import "./homepage-skeleton.scss";
import React from "react";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function HomepageSkeleton() {
  return (
    <div className="homepage-skeleton">
      <div className="announcements-skeleton">
        <LoaderCard cardSum={1} width={30} size="small" height="small" />
        <LoaderCard cardSum={2} width={100} size="large" />
      </div>
      <div className="calendar-skeleton">
        <div className="left">
          <LoaderCard cardSum={1} width={100} size="large" height="xl" />
          <LoaderCard cardSum={1} width={100} size="large" />
        </div>
        <div className="right">
          <LoaderCard cardSum={1} width={30} size="small" height="small" />
          <div className="filters">
            <div className="filters__left">
              <LoaderCard cardSum={1} width={50} size="large" />
            </div>
            <div className="filters__right">
              <LoaderCard cardSum={1} width={100} size="small" height="small" />
            </div>
          </div>
          <LoaderCard cardSum={3} width={100} size="large" />
        </div>
      </div>
    </div>
  );
}

export default HomepageSkeleton;
