import "./chapters-progress-section.scss";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../../app/socket";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ChapterSlide({ classItem, departments, chapters }) {
  const socketContext = useContext(SocketContext);

  const [chapterProgress, setChapterProgress] = useState([]);

  useEffect(() => {
    const cleanUpGetChapterProgress = getChapterProgress();
    return () => {
      cleanUpGetChapterProgress();
    };
  }, []);

  const getChapterProgress = () => {
    let args = { class_id: classItem.class_id };

    const getChapterProgressListener = (data) => {
      console.log(data);
      setChapterProgress(data);
    };

    socketContext.socket.on(
      "chapterProgress" + classItem.class_id,
      getChapterProgressListener
    );
    socketContext.socket.emit("getChapterProgress", args);

    return () => {
      socketContext.socket.off(
        "getChapterProgress",
        getChapterProgressListener
      );
      socketContext.socket.off(
        "chapterProgress" + classItem.class_id,
        getChapterProgressListener
      );
    };
  };

  const generateWeeks = () => {
    const weeks = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let augustDate = new Date(`August 1, ${currentYear}`);

    // Go to the closest previous August
    if (currentDate < augustDate) {
      augustDate.setFullYear(currentYear - 1);
    }

    while (currentDate >= augustDate) {
      weeks.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() - 7);
    }
    return weeks.reverse();
  };

  const weeks = generateWeeks();

  const normalizeDate = (date) => {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  };

  const getWeekRange = (week) => {
    const startOfWeek = normalizeDate(new Date(week));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 7);
    return { startOfWeek, endOfWeek };
  };

  const data = {
    labels: weeks.map((week) => week.toLocaleDateString()),
    datasets: departments.map((department) => {
      return {
        label: department.department_name,
        data: weeks.map((week) => {
          const { startOfWeek, endOfWeek } = getWeekRange(week);

          const weekProgress = chapterProgress.filter((progress) => {
            const progressDate = normalizeDate(new Date(progress.date));
            return (
              progressDate >= startOfWeek &&
              progressDate < endOfWeek &&
              progress.department_id === department.department_id
            );
          });

          const chapterCounts = new Array(chapters.length).fill(0);
          weekProgress.forEach((progress) => {
            const chapterIndex = chapters.findIndex(
              (chapter) => chapter.id === progress.chapter_id
            );
            if (chapterIndex !== -1) {
              chapterCounts[chapterIndex]++;
            }
          });

          return chapterCounts;
        }),
        backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 0.5)`,
      };
    }),
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Chapters",
        },
        ticks: {
          stepSize: 1,
          callback: (value, index, values) => {
            return chapters[value]?.chapter_name || "";
          },
        },
        min: 0,
        max: chapters.length - 1,
      },
    },
  };

  return (
    <div className="chapter__slider-slide">
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default ChapterSlide;
