import React from "react";
import "../skeleton.scss";
import "./exams-skeleton.scss";
import SideFiltersSkeleton from "../sideFiltersSkeleton/sideFiltersSkeleton";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function ExamsSkeleton() {
  return (
    <div className="exams-skeleton skeleton">
      <SideFiltersSkeleton />
      <div className="exams-skeleton-wrapper wrapper">
        <LoaderCard cardSum={20} width={30} size="large" mobileWidth={100} />
      </div>
    </div>
  );
}

export default ExamsSkeleton;
