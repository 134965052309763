import React, { useState, useEffect } from "react";
import "./quizAnswerStudent.scss";
import ButtonMultipleToggle from "../../components/ButtonMultipleToggle/buttonMultipleToggle";
import { BiCheck } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function QuizAnswerStudent({ answer, type, answered, hasMultipleCorrect }) {
  const toggleTypeNum = 2;
  const [toggleType, setToggleType] = useState("Λάθος");

  const [selected, setSelected] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    if (answered) {
      checkIsCorrect();
    }
  }, [answered]);

  useEffect(() => {
    if (toggleType === "Σωστό") {
      answer.selected = true;
    } else {
      answer.selected = false;
    }
  }, [toggleType]);

  const checkIsCorrect = () => {
    if (type === "correct/wrong") {
      if (
        (toggleType === "Σωστό" && answer.is_correct) ||
        (toggleType === "Λάθος" && !answer.is_correct)
      ) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
    }
    if (type === "multiple-choice") {
      if (selected) {
        if (!answer.is_correct) {
          setIsCorrect(false);
        }
        if (answer.is_correct) {
          setIsCorrect(true);
        }
      }
    }
  };

  const answerClick = () => {
    if (!answered) {
      answer.selected = !answer.selected;
      setSelected(!selected);
    }
  };

  return (
    <li
      className={
        "quiz-student__answers-item " +
        ((selected || type !== "multiple-choice") && answered
          ? isCorrect
            ? "correct"
            : "wrong"
          : "")
      }
      onClick={() => answerClick()}
    >
      <div
        className={
          "details " + (type === "correct/wrong" ? "correct-wrong" : "")
        }
      >
        {type === "multiple-choice" && (
          <div
            className={
              "quiz-student__answers-item-check " +
              (selected ? "checked" : "") +
              (hasMultipleCorrect ? " multiple" : "")
            }
          >
            {hasMultipleCorrect && selected ? (
              <BiCheck
                size={"30px"}
                color={"#6225e6"}
                style={iconStyle("transparent")}
              />
            ) : (
              ""
            )}
          </div>
        )}
        <span className="quiz-answer-student__description ms-3">
          {answer.answer}
        </span>
        {type === "correct/wrong" && (
          <ButtonMultipleToggle
            button1="Σωστό"
            button2="Λάθος"
            setSelected={setToggleType}
            selected={toggleTypeNum}
          ></ButtonMultipleToggle>
        )}
      </div>
    </li>
  );
}

export default React.memo(QuizAnswerStudent);
