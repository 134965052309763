import React, { useState, useEffect, useContext } from "react";
import { BiPlus, BiX, BiTrash, BiCheck } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../../app/socket";
import { setCurrentClassId } from "../classNamesSlice";
import { iconStyle } from "../../../utils/generalUtils";

function ClassItem({
  classItem,
  index,
  activeClassIndex,
  activeChapterIndex,
  profile,
  chapterSubmenu,
  classItemClick,
  populateChapterSubmenu,
}) {
  const socketContext = useContext(SocketContext);
  const dispatch = useDispatch();

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const [addChapterShow, setAddChapterShow] = useState(false);
  const [newChapterName, setNewChapterName] = useState("");

  useEffect(() => {
    setShowDeleteOptions(false);
  }, [activeClassIndex]);

  const addChapter = () => {
    const body = {
      chapter_name: newChapterName,
      class_id: activeClassIndex,
    };
    socketContext.socket.emit("addChapter", body);
    setAddChapterShow(false);
    setNewChapterName("");
  };

  const deleteClass = () => {
    const eventBody = {
      class_id: classItem.class_id,
    };

    socketContext.socket.emit("deleteClass", eventBody);
    dispatch(setCurrentClassId(-1));
  };

  return (
    <div key={"sideFilterClassaaa" + index} className={"filters__class-item "}>
      <div
        className={
          "filters__class-item-button " +
          (sideFiltersClassId == classItem.class_id ? " active" : "")
        }
        onClick={(e) => classItemClick(e, classItem.class_id)}
      >
        <span>{classItem.class_name}</span>
      </div>
    </div>
  );
}

export default ClassItem;
