import "./buttonMultipleToggle.scss";
import React, { useRef, useEffect } from "react";

const ButtonMultipleToggle = ({
  button1,
  button2,
  button3,
  button4,
  setSelected,
  selected,
  triple,
}) => {
  const containerRef = useRef(null);
  const button1Ref = useRef(null);
  const button2Ref = useRef(null);
  const button3Ref = useRef(null);
  const button4Ref = useRef(null);

  useEffect(() => {
    if (selected) {
      if (selected == 1) {
        activeState(button1Ref.current);
      } else if (selected == 2) {
        activeState(button2Ref.current);
      } else if (selected == 3) {
        activeState(button3Ref.current);
      } else if (selected == 4) {
        activeState(button4Ref.current);
      }
    } else {
      activeState(button1Ref.current);
    }
  }, [selected]);

  const activeState = (btnItem) => {
    if (containerRef.current && btnItem) {
      const btnActive = containerRef.current.getElementsByClassName(
        "js-btn-slide-active"
      )[0];
      let itemBounding = containerRef.current.getBoundingClientRect();
      let btnBounding = btnItem.getBoundingClientRect();

      btnActive.style.opacity = 1;
      let leftPosition =
        Math.round(btnBounding.left) - Math.round(itemBounding.left);
      if (leftPosition == 0) {
        leftPosition = 17;
      }
      btnActive.style.left = leftPosition + "px";
      let width = btnItem.offsetWidth;
      btnActive.style.width = width + "px";
      btnItem.classList.add("active");
    }
  };

  const buttonClick = (item, btnItem) => {
    let type = btnItem.current.innerText;
    setSelected(type);
    button1Ref.current.classList.remove("active");
    button2Ref.current.classList.remove("active");
    if (button3) {
      button3Ref.current.classList.remove("active");
    }
    if (button4) {
      button4Ref.current.classList.remove("active");
    }
    btnItem.current.classList.add("active");
    activeState(btnItem.current);
  };

  return (
    <div className={"button-multiple-toggle " + (triple ? " triple" : "")}>
      <div ref={containerRef} className="wrapper">
        <span className="slider js-btn-slide-active" role="presentation"></span>
        <div
          ref={button1Ref}
          className={
            "item " +
            (selected ? "" : "active") +
            (selected == 1 ? "active" : "")
          }
          onClick={(e) => buttonClick(e, button1Ref)}
        >
          <span>{button1}</span>
        </div>
        <div
          ref={button2Ref}
          className={"item " + (selected == 2 ? "active" : "")}
          onClick={(e) => buttonClick(e, button2Ref)}
        >
          <span>{button2}</span>
        </div>
        {button3 && (
          <div
            ref={button3Ref}
            className="item"
            onClick={(e) => buttonClick(e, button3Ref)}
          >
            <span>{button3}</span>
          </div>
        )}
        {button4 && (
          <div
            ref={button4Ref}
            className="item"
            onClick={(e) => buttonClick(e, button4Ref)}
          >
            <span>{button4}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonMultipleToggle;
