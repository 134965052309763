import { createSlice } from '@reduxjs/toolkit'

export const chaptersSlice = createSlice({
    name: 'chapters',
    initialState: {
        value: [{}],
        selectedChapterId: -1
    },
    reducers: {
        setChapters: (state, action) => {
            // console.log(action.payload);
            state.value = action.payload;
            // state.value.class_name = action.payload.class_name;
        },
        setCurrentChapterId: (state, action) => {
            state.selectedChapterId = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setChapters, setCurrentChapterId} = chaptersSlice.actions

export default chaptersSlice.reducer