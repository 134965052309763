import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import "./quiz-student.scss";
import "../../scss/main.scss";
import { calls } from "../../config/db_config";
import { tokenUtils } from "../../utils/token-utils";
import QuizItemStudent from "./quiztItemStudent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { IoIosClose } from "react-icons/io";
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";
import { useNavigate } from "react-router-dom";
import SideFilters from "../../components/SideFilters/side-filters";
import { logUtils } from "../../utils/logUtils";
import { SocketContext } from "../../app/socket";
import QuizScoreSlide from "./quiz-score-slide";
import { iconStyle } from "../../utils/generalUtils";
import {
  BiAnalyse,
  BiX,
  BiCollection,
  BiSearch,
  BiLibrary,
  BiAbacus,
} from "react-icons/bi";
import MobileSideFilters from "../../components/SideFilters/MobileSideFilters/mobileSideFilters";
import LoaderCard from "../../components/LoaderCard/loaderCard";
import Timer from "../../components/Timer/timer";

const QuizStudent = () => {
  const [loadingChatGpt, setLoadingChatGpt] = useState(false);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [chapters, setChapters] = useState([]);
  const [gotChapters, setGotChapters] = useState(false);
  const [chaptersWithQuiz, setChaptersWithQuiz] = useState([]);

  const mobileFiltersClick = () => {
    if (!mobileFiltersOpen) {
      setMobileFiltersOpen(true);
    }
  };

  const searchClick = () => {
    if (!searchOpen) {
      setSearchOpen(true);
    }
  };

  const socketContext = useContext(SocketContext);

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    setGotChapters(false);
    const cleanUpGetChapters = getChapters();
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetChapters();
      cleanUpGetClasses();
    };
  }, [sideFiltersClassId]);

  useEffect(() => {
    const cleanUpGetChapters = getChapters();
    return () => {
      cleanUpGetChapters();
    };
  }, [searchText]);

  useEffect(() => {
    getChaptersWithQuiz();
  }, []);

  const navigate = useNavigate();

  const profile = useSelector((state) => state.profile.value);

  const timer = new Date();
  const [enableTimer, setEnableTimer] = useState(false);
  const [selectedChapters, setSelectedChapters] = useState([]);

  const [questions, setQuestions] = useState([]);

  const [finalScore, setFinalScore] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const [dateStarted, setDateStarted] = useState(0);

  const getChaptersWithQuiz = () => {
    let args = {};

    const getChaptersWithQuizListener = (data) => {
      console.log(data);
      setChaptersWithQuiz(data);
    };

    socketContext.socket.on("chaptersWithQuiz", getChaptersWithQuizListener);
    socketContext.socket.emit("getChaptersWithQuiz", args);
    socketContext.socket.on("refreshChaptersWithQuiz", () => {
      socketContext.socket.emit("getChaptersWithQuiz", args);
    });
  };

  const getClasses = () => {
    let args = {};

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const getChapters = () => {
    let args = {
      class_id: sideFiltersClassId,
      search: searchText,
    };
    const getChaptersListener = (data) => {
      setGotChapters(true);
      setChapters(data);
    };

    const refreshChaptersListener = () => {
      socketContext.socket.emit("getChapters", args);
    };

    socketContext.socket.on("chapters", getChaptersListener);
    socketContext.socket.emit("getChapters", args);
    socketContext.socket.on("refreshChapters", refreshChaptersListener);

    return () => {
      socketContext.socket.off("getChapters", getChaptersListener);
      socketContext.socket.off("chapters", getChaptersListener);
      socketContext.socket.off("refreshChapters", refreshChaptersListener);
    };
  };

  const populateChapters = () => {
    if (chapters.length > 0) {
      return chapters.map((chapter, index) => {
        if (chaptersWithQuiz.some((chp) => chp.chapter_id == chapter.id)) {
          return (
            <div
              key={`chapter-${index}`}
              className="chapters__item"
              onClick={() => {
                selectChapter(chapter.id);
              }}
            >
              <div className="chapters__item-title">
                <span>{chapter.chapter_name}</span>
              </div>
              <div
                className={
                  "chapters__item-select " +
                  (selectedChapters.includes(chapter.id) ? "active" : "")
                }
              ></div>
            </div>
          );
        }
      });
    } else {
      return (
        <div className="quiz-student__info">
          <div className="quiz-student__info-wrapper">
            <BiLibrary
              size={"300px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
            />
            <span>Δε βρέθηκαν κεφάλαια</span>
          </div>
        </div>
      );
    }
  };

  const selectChapter = (chapterId) => {
    const tempSelected = selectedChapters;
    if (!tempSelected.includes(chapterId)) {
      tempSelected.push(chapterId);
    } else {
      let start = tempSelected.indexOf(chapterId);
      tempSelected.splice(start, 1);
    }
    setSelectedChapters([...tempSelected]);
  };

  const carouselRef = useRef(null);
  const [interactionAllowed, setInteractionAllowed] = useState(true);

  const nextSlide = () => {
    setInteractionAllowed(false);
    setTimeout(() => {
      carouselRef.current.slickNext();
      let currentSlide = carouselRef.current.innerSlider.state.currentSlide;
      if (currentSlide === questions.length - 1) {
        quizSubmit();
      }
      setInteractionAllowed(true);
    }, 1800);
  };

  const timerFinish = () => {
    quizSubmit();
  };

  const getTime = (difficulty) => {
    switch (difficulty) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 5;
      default:
        break;
    }
  };

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const getQuestionsFromChatgpt = () => {
    setLoadingChatGpt(true);
    const temp = chapters.filter((chapter) =>
      selectedChapters.includes(chapter.id)
    );

    const selectedClasses = classes.filter((c) =>
      temp.some((tempItem) => tempItem.class_id === c.class_id)
    );

    let promptClasses = "";
    selectedClasses.map((classItem) => {
      promptClasses += classItem.class_name + " " + classItem.grade_name + " ";
    });

    let promptChapters = "";
    temp.map((chapterItem) => {
      promptChapters += chapterItem.chapter_name + " ";
    });

    let prompt =
      "Generate 5 multiple-choice questions for the courses " +
      promptClasses +
      " and chapters " +
      promptChapters +
      ". Each question should be structured as follows: chapter_id: 4, description: question text, difficulty: 1, exercises_id: auto-increment starting from 1, hint: '', type: 'multiple-choice', and an answers array. In the answers array, each item should have an answer value containing the text of the specific answer, ex_id_fk: question's exercises_id, id: auto-increment integer, is_correct: boolean. Return a stringified array with exactly 5 questions. Ensure that the question descriptions do not contain the answers, which should only appear in the answers array." +
      " Also very important use greek for both question and answers ONLY if the string " +
      promptClasses +
      " , doesnt include any foreign language. If it includes a foreign language then use it as you would teaching that language in combination with greek if needed. Very important to return only the stringified array in the format i asked.";
    let args = "?prompt=" + prompt;
    fetch(calls.getChagptResponse + args, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenUtils.getBearerToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          if (data.data) {
            setLoadingChatGpt(false);
            console.log(" got questions from chatgpt ");
            console.log(data);
            try {
              const updatedQuestions = JSON.parse(data.data.content);
              console.log(updatedQuestions);
              let time = 0;

              setQuestions(updatedQuestions);

              updatedQuestions.forEach((dataQuestion) => {
                time += getTime(2);
              });
              timer.setSeconds(new Date().getSeconds() + time * 60);
              setEnableTimer(true);
              getPercentage();
              setQuizConfigured(true);

              logUtils.openQuiz();
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          console.log("not able to retrieve");
        }
      });
  };

  const getQuestionsByChapterId = () => {
    let args =
      "?chapters_selected=" +
      JSON.stringify(selectedChapters) +
      "&limit=" +
      (generalSettings.find((set) => set.name == "questions-number")
        ? generalSettings.find((set) => set.name == "questions-number").value
        : 10);

    fetch(calls.getQuestionsByChapterId + args, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenUtils.getBearerToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          if (data.data) {
            const updatedQuestions = data.data.map((question) => ({
              ...question,
              answered: false,
              correct: true,
              not_answered: false,
              answers: shuffleArray(
                question.answers.map((answer) => ({
                  ...answer,
                  selected: false,
                }))
              ),
              pairs: question.answers.map((answer) => ({
                answerA: answer.id,
                answerB: answer.id,
              })),
            }));

            let time = 0;

            setQuestions(updatedQuestions);

            data.data.forEach((dataQuestion) => {
              time += getTime(dataQuestion.difficulty);
            });
            timer.setSeconds(new Date().getSeconds() + time * 60);
            setEnableTimer(true);
            getPercentage();
            setQuizConfigured(true);

            logUtils.openQuiz();
          }
        } else {
          console.log("not able to retrieve");
        }
      });
  };

  const quizSubmit = async () => {
    setSubmitted(true);
    let correctPres = 0;
    setEnableTimer(false);

    let correctCount = 0;
    questions.map((question, i) => {
      if (question.correct && !question.not_answered) {
        console.log(" question is correct ");
        console.log(question);
        correctCount++;
      }
    });
    correctPres = Math.round((correctCount / questions.length) * 100);
    setFinalScore(correctPres);
    setSubmitted(true);

    // submit quiz score
    console.log("questions finished");
    console.log(questions);
    const body = {
      chapters: JSON.stringify(selectedChapters),
      date_started: dateStarted,
      score: correctPres,
    };

    socketContext.socket.emit("submitQuiz", body);

    if (profile.user_type === "student") {
      questions.map((question) => {
        const selectedAnswers = [];
        if (question.type !== "matching") {
          question.answers.map((answer) => {
            if (answer.selected) {
              selectedAnswers.push(answer);
            }
          });
        } else {
          question.pairs.map((pair) => {
            selectedAnswers.push(pair.answerA.id);
            selectedAnswers.push(pair.answerB.id);
          });
        }
        const statBody = {
          user_id: profile.user_id,
          exercise_id: question.exercises_id,
          answers: selectedAnswers,
          is_correct: question.correct,
        };
        socketContext.socket.emit("updateQuizSubmissions", statBody);
      });
    }
  };

  const populateQuizQuestions = () => {
    return questions.map((question, index) => {
      return (
        <div key={"quizQuestion" + index}>
          <QuizItemStudent
            key={"question-item" + index}
            question={question}
            nextSlide={nextSlide}
          />
          <span>
            {carouselRef.current.innerSlider.state.currentSlide + 1}/
            {questions.length}
          </span>
        </div>
      );
    });
  };

  const carouselSettings = {
    centerMode: true,
    infinite: false,
    dots: false,
    draggable: false,
    swipe: false,
    arrows: false,
    slidesToShow: 1,
    centerPadding: "0",
    swipeToSlide: false,
    focusOnSelect: true,
  };

  const [quizConfigured, setQuizConfigured] = useState(false);

  const quizStartClick = () => {
    setDateStarted(new Date());
    getQuestionsByChapterId();
  };

  const quizStartAutomatedClick = () => {
    setDateStarted(new Date());
    getQuestionsFromChatgpt();
  };

  const [barWidth, setBarWidth] = useState(100);

  const getPercentage = async () => {
    const dateNow = new Date();
    const fullTime = Math.abs(timer - dateNow) / 1000;

    let interval = setInterval(() => {
      let temp = Math.abs(timer - new Date()) / 1000;
      let tempWidth = (temp / fullTime) * 100;

      setBarWidth(tempWidth);

      if (tempWidth <= 1) clearInterval(interval);
    }, 1000);
  };

  const [activeLabel, setActiveLabel] = useState(0);

  useEffect(() => {
    if (loadingChatGpt) {
      const interval = setInterval(() => {
        setActiveLabel((prevActiveLabel) => (prevActiveLabel + 1) % 4);
      }, 7100);

      return () => clearInterval(interval);
    }
  }, [loadingChatGpt]);

  const labels = [
    "Δημιουργούμε την εκφώνηση της κάθε ερώτησης...",
    "Επεξεργαζόμαστε τις πιθανές απαντήσεις...",
    "Σσσσς! τώρα εφαρμόζουμε τις σωστές επιλογές για κάθε ερώτηση...",
    "Έιμαστε χεδόν έτοιμοι...",
  ];

  const goQuizHome = () => {
    setQuestions([]);
    setFinalScore(false);
    setSubmitted(false);
    setQuizConfigured(false);
  };

  const tempSubmit = () => {
    console.log("submit");
    let currentSlide = carouselRef.current.innerSlider.state.currentSlide;
    questions.map((question, index) => {
      if (currentSlide < index) {
        question.answered = true;
        question.correct = false;
        question.not_answered = true;
      }
      if (currentSlide == index && !question.answered) {
        console.log(question);
        let correct = true;
        if (question.type === "correct/wrong" && !question.is_multiple) {
          if (
            (question.is_correct && question.selected == "Σωστό") ||
            (!question.is_correct && question.selected == "Λάθος")
          ) {
            correct = true;
          } else {
            correct = false;
          }
        } else {
          question.answers.map((answer) => {
            if (question.type === "multiple-choice") {
              if (
                (!answer.is_correct && answer.selected) ||
                (answer.is_correct && !answer.selected)
              ) {
                correct = false;
              }
            }
            if (question.type === "correct/wrong" && question.is_multiple) {
              if (
                (answer.is_correct && !answer.selected) ||
                (!answer.is_correct && answer.selected)
              ) {
                correct = false;
              }
            }
            if (question.type === "matching") {
              try {
                if (question.pairs.length === question.answers.length) {
                  const updatedAnswers = question.pairs.map((match) => ({
                    ...match,
                    correct: match.answerA.id === match.answerB.id,
                  }));
                  correct = updatedAnswers.every((match) => match.correct);
                } else {
                  correct = false;
                }
              } catch (e) {
                console.log(e);
              }
            }
          });
        }
        question.correct = correct;
      }
    });

    quizSubmit();
    const totalSlides = carouselRef.current.innerSlider.state.slideCount;
    carouselRef.current.slickGoTo(totalSlides - 1);
  };

  const onTimeExpired = () => {
    console.log(" time is up");
    // quizSubmit();
  };

  return (
    <div
      className={"quiz-student " + (submitted ? "submitted" : "")}
      style={{ pointerEvents: interactionAllowed ? "auto" : "none" }}
    >
      {loadingChatGpt ? (
        <div className="modal-background show ">
          <div className="loader-content">
            {labels.map((labelContent, index) => (
              <span
                key={index}
                className={`label ${
                  activeLabel === index ? "show" : "dont-show"
                }`}
              >
                {labelContent}
              </span>
            ))}
          </div>
          <div id="loader">
            <div className="one"></div>
            <div className="two"></div>
            <div className="three"></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
        className={
          "quiz-student__quiz " +
          (quizConfigured ? "" : "hide") +
          (submitted ? " final" : "")
        }
      >
        {/* {enableTimer && profile.user_type === "student" && (
          <div className="quiz-student__timer-wrapper">
            <div className="bar" style={{ width: barWidth + "%" }}></div>
            <BsFillStopwatchFill size={30} color={"var(--on)"} />
            <CountdownTimer
              expiryTimestamp={timer}
              onTimeExpired={() => {
                timerFinish();
              }}
            />
          </div>
        )} */}

        <div className="row">
          <div className="col-12">
            <div className="col-12">
              <div className="quiz-student__quizes-container">
                <Slider
                  className="disabled"
                  ref={carouselRef}
                  {...carouselSettings}
                >
                  {populateQuizQuestions()}
                  <QuizScoreSlide
                    finalScore={finalScore}
                    submitted={submitted}
                    chapters={selectedChapters}
                    questions={questions}
                  />
                </Slider>
                {!submitted ? (
                  <div
                    className="quiz-student__chip"
                    onClick={() => tempSubmit()}
                  >
                    <div className="quiz-student__chip-timer">
                      {questions && questions.length ? (
                        <Timer
                          quizTime={
                            (generalSettings.find(
                              (set) => set.name == "question-time"
                            )
                              ? generalSettings.find(
                                  (set) => set.name == "question-time"
                                ).value
                              : 3) * questions.length
                          }
                          onTimerExpired={onTimeExpired}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="quiz-student__chip-close">
                      <BiX
                        onClick={() => goQuizHome()}
                        size={"30px"}
                        color={"#ccc"}
                        style={iconStyle("#f8f8f8")}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {submitted ? (
                  <div className="quiz-student__return">
                    <button
                      onClick={() => goQuizHome()}
                      className="cta cta-shadow"
                    >
                      Επιστροφή στην αρχική
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"quiz-student__conf " + (quizConfigured ? "hide" : "")}>
        <SideFilters onlyWithQuiz={true} />
        <div className="quiz-student__conf-main">
          {profile.user_type !== "student" ? (
            <div className="quiz-student__conf-main-header">
              <div
                className={
                  "navigate " +
                  (searchOpen || mobileFiltersOpen ? "closed" : "")
                }
                onClick={() => navigate("/quiz-admin")}
              >
                <BiCollection
                  size={"30px"}
                  color={"#ccc"}
                  style={iconStyle("transparent")}
                />
                <span>Διαχείριση ερωτήσεων</span>
              </div>
              <div
                className={"search " + (searchOpen ? "open" : "closed")}
                onClick={() => searchClick()}
              >
                <input
                  className="input"
                  placeholder="Αναζητήστε Κεφάλαιο"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchOpen ? (
                  <BiX
                    className="search-close"
                    onClick={() => setSearchOpen(false)}
                    size={"40px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  <BiSearch
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}
              </div>
              <div
                className={
                  "quiz-mobile-filters " +
                  (mobileFiltersOpen && !searchOpen ? "open" : "closed")
                }
                onClick={() => mobileFiltersClick()}
              >
                <div className="quiz-mobile-filters__preview ">
                  {mobileFiltersOpen ? (
                    <span onClick={() => setMobileFiltersOpen(false)}>
                      Φίλτρα
                      <BiX
                        size={"25px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                      />
                    </span>
                  ) : (
                    <BiAbacus
                      size={"25px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                  )}
                </div>
                <MobileSideFilters />
              </div>
            </div>
          ) : (
            <div className="student-header">
              <div
                className={
                  "student-header__search " +
                  (mobileFiltersOpen ? " closed" : "")
                }
                onClick={() => setMobileFiltersOpen(false)}
              >
                <input
                  placeholder="Αναζήτηση"
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <BiSearch
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </div>
              <div
                className={
                  "student-header__filters " +
                  (mobileFiltersOpen ? "open" : "closed")
                }
                onClick={() => mobileFiltersClick()}
              >
                <div className="student-header__filters-preview">
                  {mobileFiltersOpen ? (
                    <span onClick={() => setMobileFiltersOpen(false)}>
                      Φίλτρα
                      <BiX
                        size={"25px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                      />
                    </span>
                  ) : (
                    <BiAbacus
                      size={"25px"}
                      color={"#cccccc"}
                      style={iconStyle("transparent")}
                    />
                  )}
                </div>
                <MobileSideFilters bigWidth={true} />
              </div>
            </div>
          )}
          <div className="chapters">
            {gotChapters ? (
              populateChapters()
            ) : (
              <LoaderCard cardSum={5} width={100} />
            )}
          </div>
        </div>
        {selectedChapters.length ? (
          <div
            className={
              "quiz-student__conf-main-start d-flex " +
              (selectedChapters.length > 0 ? "active" : "")
            }
          >
            {/* {window.location.hostname.includes("localhost") ||
            window.location.hostname.includes("gnosi") ||
            window.location.hostname.includes("md") ||
            window.location.hostname.includes("demo") ||
            window.location.hostname.includes("oramapaideias") ? (
              <button
                className="cta cta-fill chatgpt-start"
                onClick={quizStartAutomatedClick}
              >
                Εκκίνηση AI quiz
                <div className="special-icon ms-3">
                  <BiAnalyse
                    size={"30px"}
                    color={"#fff"}
                    style={iconStyle("transparent")}
                  />
                </div>
              </button>
            ) : (
              ""
            )} */}
            <button className="cta cta-fill" onClick={quizStartClick}>
              Εκκίνηση
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default QuizStudent;
