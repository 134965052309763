import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { useSelector } from "react-redux";
import { BiDotsHorizontalRounded, BiCheck, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function ProfessorComment({ comment, curriculumAnswer }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const actionsButtonRef = useRef(null);

  const deleteComment = () => {
    const eventBody = {
      comment_id: comment.id,
      curriculumAnswerId: curriculumAnswer.curriculum_answers_id,
    };
    socketContext.socket.emit("deleteCurriculumProfessorComment", eventBody);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        actionsButtonRef.current &&
        !actionsButtonRef.current.contains(event.target)
      ) {
        setShowDeleteOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={
        "professor-comments__content-comment " +
        (comment.id == -1 ? " loading-item" : "")
      }
    >
      <div className="header">
        {profile.user_type == "admin" ||
        profile.user_id == comment.created_by ? (
          <div className="header__actions">
            <div
              ref={actionsButtonRef}
              className="header__actions-buttons open-options"
            >
              <BiDotsHorizontalRounded
                className="open-options"
                size={"25px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
                onClick={() => setShowDeleteOptions(true)}
              />
            </div>
            {showDeleteOptions && (
              <div className="header__actions-options">
                <span onClick={() => deleteComment()}>Διαγραφή</span>
              </div>
            )}
          </div>
        ) : null}
        <div className="header__image">
          {comment.id != -1 ? (
            <img
              alt="profile student professor"
              src={
                comment.profile_picture
                  ? comment.profile_picture
                  : "resources/student.png"
              }
            />
          ) : (
            <img
              alt="profile student professor"
              src={
                profile.profile_picture
                  ? profile.profile_picture
                  : "resources/student.png"
              }
            />
          )}
        </div>
        <div className="header__info">
          {comment.id != -1 ? (
            <span className="header__info-name">
              {comment.first_name} {comment.last_name}
            </span>
          ) : (
            <span className="header__info-name">
              {profile.first_name} {profile.last_name}
            </span>
          )}
          {comment.id != -1 ? (
            <span className="header__info-date">
              {formatDate(comment.created_at)}
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="comment-text">{comment.comment_text}</div>
    </div>
  );
}

export default ProfessorComment;
