import { useContext, useEffect, useState } from "react";
import "./edit-dep-users.scss";
import { motion, AnimatePresence } from "framer-motion";
import { SocketContext } from "../../app/socket";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import "react-tabs/style/react-tabs.css";
import { BiPencil, BiX } from "react-icons/bi";
import EditDepUserItem from "./editDepUserItem";
import { useSelector } from "react-redux";

function EditDepUsers({
  currentUsers,
  userType,
  selectedDepartment,
  classTuition,
}) {
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(currentUsers);

  const [name, setName] = useState("");

  useEffect(() => {
    // if (!isExpanded) {
    //   socketContext.socket.emit("refreshAllUsersWithParams");
    // }
    if (window.innerWidth < 700) {
      document.getElementById("main-app").style.overflowY = isExpanded
        ? "hidden"
        : "auto";
    } else {
      document.body.style.overflowY = isExpanded ? "hidden" : "auto";
    }
  }, [isExpanded]);

  useEffect(() => {
    setName("");
  }, []);

  useEffect(() => {
    const cleanUpGetUsers = getUsers();
    return () => {
      cleanUpGetUsers();
    };
  }, [name]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const getUsers = () => {
    const uniqueId =
      userType === "professor"
        ? "edit-professors"
        : userType === "student"
        ? "edit-students"
        : "edit-parents";
    const args = {
      uniqe_id: uniqueId,
      type: userType,
      also_admins: userType === "professor",
      name: name,
    };

    const getUsersListener = (data) => {
      setUsers(data);
    };

    const refreshUsersListener = () => {
      // socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on("allUsersWithParams" + uniqueId, getUsersListener);
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on("refreshAllUsersWithParams", refreshUsersListener);

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getUsersListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshUsersListener
      );
    };
  };

  const addUserToSelectedUsers = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newUsers = [...prevSelectedUsers, user];
      newUsers.sort((a, b) => a.user_id - b.user_id);
      return newUsers;
    });

    const body = {
      user_id: user.user_id,
      department_id: selectedDepartment,
      class_id: sideFiltersClassId,
      type: "add",
      class_tuition: classTuition,
      add_tuition: getGeneralSettingValue(generalSettings, "tuition_for_class"),
      add_steroid_tuition: getGeneralSettingValue(
        generalSettings,
        "tuition_on_steroids"
      ),
    };

    socketContext.socket.emit("updateClassHasUsers", body);
  };

  const removeFromSelectedUsers = (user) => {
    setSelectedUsers((prevSelectedUsers) => {
      const filteredUsers = prevSelectedUsers.filter(
        (currentUser) => currentUser.user_id !== user.user_id
      );
      filteredUsers.sort((a, b) => a.user_id - b.user_id);
      return filteredUsers;
    });

    const body = {
      user_id: user.user_id,
      department_id: selectedDepartment,
      class_id: sideFiltersClassId,
      type: "remove",
      class_tuition: classTuition,
      add_tuition: getGeneralSettingValue(generalSettings, "tuition_for_class"),
      add_steroid_tuition: getGeneralSettingValue(
        generalSettings,
        "tuition_on_steroids"
      ),
    };

    socketContext.socket.emit("updateClassHasUsers", body);
  };

  const populateCurrentUsers = () => {
    return selectedUsers.map((user, index) => {
      return (
        <EditDepUserItem
          key={"selectedDepUsers" + user.user_id}
          user={user}
          type="remove"
          handleClick={() => removeFromSelectedUsers(user)}
        />
      );
    });
  };

  const populateAllUsers = () => {
    const filteredUsers = users.filter(
      (user) =>
        !selectedUsers.some(
          (currentUser) => currentUser.user_id === user.user_id
        )
    );
    return filteredUsers.map((user, index) => {
      return (
        <EditDepUserItem
          key={"allDepUsers" + user.user_id}
          user={user}
          type="add"
          handleClick={() => addUserToSelectedUsers(user)}
        />
      );
    });
  };

  return (
    <div
      className={`edit-dep-users users__list-wrapper-item ${
        isExpanded ? "is-expanded" : "not-expanded"
      }`}
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className="modal-background show"
        ></div>
      )}
      <motion.div
        onClick={toggleExpand}
        className="preview"
        whileHover={!isExpanded ? { scale: 1.03 } : {}}
      >
        <BiPencil
          size={"30px"}
          color={"#cccccc"}
          style={iconStyle("transparent")}
        />
      </motion.div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="content"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: 1,
              scale: 1,
              width: "90vw",
              height: "80vh",
              top: "50%",
              left: "50%",
              translateX: "-50%",
              translateY: "-50%",
            }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5 }}
            style={{ position: "fixed" }}
          >
            <div onClick={toggleExpand} className="close-btn">
              <BiX size={"35px"} color={"#ccc"} style={iconStyle("#f8f8f8")} />
            </div>
            <span className="content__label">Επιλεγμένοι</span>
            <div className="content__list">
              <AnimatePresence> {populateCurrentUsers()}</AnimatePresence>
            </div>
            <span className="content__label">
              Όλοι οι {userType == "professor" ? "Καθηγητές" : "Μαθητές"}
            </span>
            <div className="content__search">
              <input
                className="input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={
                  "Αναζητήστε " +
                  (userType == "student" ? "μαθητή" : "καθηγητή")
                }
              />
            </div>
            <div className="content__list">
              {" "}
              <AnimatePresence> {populateAllUsers()}</AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default EditDepUsers;
