import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../app/socket";
import "./exam-records.scss";
import RecordStudent from "./recordStudent";
import MarkedUser from "./markedUser";
import { AnimatePresence } from "framer-motion";
import LoaderCard from "../LoaderCard/loaderCard";

function ExamRecords({ exam, setIsOpen }) {
  const socketContext = useContext(SocketContext);

  useEffect(() => {
    getUsersInExams();
    getMarked();

    if (!exam.id || exam.id == undefined) {
      exam.id = exam.exam_id;
    }
  }, []);

  const [marked, setMarked] = useState([]);
  const [students, setStudents] = useState([]);

  const getUsersInExams = () => {
    let args = { class_id: exam.class_id, department_id: "" };

    try {
      let departmentId = JSON.parse(exam.department_id);
      if (departmentId && departmentId.length == 1 && departmentId[0] == -1) {
        // do nothing
      } else {
        args.department_id = departmentId;
      }
    } catch (e) {
      console.log(e);
    }

    const getStudentsInExamListener = (data) => {
      setStudents(data);
    };

    socketContext.socket.on("studentsInExam", getStudentsInExamListener);
    socketContext.socket.emit("getStudentsInExam", args);
  };

  const getMarked = () => {
    let args = { exam_id: exam.id ? exam.id : exam.exam_id };

    const getMarkedListener = (data) => {
      setMarked(data);
    };

    socketContext.socket.on("markedExams", getMarkedListener);
    socketContext.socket.emit("getMarkedExams", args);
    socketContext.socket.on("refreshMarkedExams", () => {
      socketContext.socket.emit("getMarkedExams", args);
    });
  };

  const populateStudents = () => {
    return students.map((student) => {
      if (!marked.some((item) => item.user_id == student.user_id)) {
        return (
          <RecordStudent
            key={"recordStudentasdasd" + student.user_id}
            student={student}
            examId={exam.id ? exam.id : exam.exam_id}
            questionsNum={exam.questions_count}
            maxRank={exam.max_rank}
            detailedRank={exam.detailed_rank ? exam.detailed_rank : ""}
          />
        );
      }
    });
  };

  const populateMarkedStudents = () => {
    return students.map((student, index) => {
      if (marked.some((item) => item.user_id == student.user_id)) {
        const item = marked.find((item) => item.user_id == student.user_id);
        return (
          <MarkedUser
            key={"markedUser" + student.user_id + " index " + index}
            student={student}
            score={item.score}
            examId={exam.id ? exam.id : exam.exam_id}
            comment={item.result_text}
            maxRank={exam.max_rank}
            studentDetailedScore={item.detailed_score}
            examDetailedRank={exam.detailed_rank}
          />
        );
      }
    });
  };

  return (
    <div className={"exam-records"}>
      {marked.length > 0 && <div className="title">Βαθμολογημένοι</div>}
      {marked && students ? (
        <div className="marked-users">
          <AnimatePresence>{populateMarkedStudents()}</AnimatePresence>
        </div>
      ) : (
        <LoaderCard cardSum={6} width={25} size="large" />
      )}
      <div className="title">Καταγραφή βαθμών στους μαθητές</div>
      {marked && students ? (
        <div className="users">
          <AnimatePresence>{populateStudents()}</AnimatePresence>
        </div>
      ) : (
        <LoaderCard cardSum={6} width={100} height="xl" />
      )}
    </div>
  );
}

export default ExamRecords;
