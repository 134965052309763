import React, { useEffect, useState, useRef } from "react";
import "./gif-box.scss";

function GifBox({ sendMessage }) {
  const [gifs, setGifs] = useState([]);
  const apiKey = "4rYPalSykXin9rMqEfzHuoeOP42JceF6";

  const boxRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [searchGif, setSearchGif] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSearchGif("");
  }, []);

  useEffect(() => {
    fetchTrendingGifs();
  }, [searchGif]);

  const fetchTrendingGifs = async () => {
    try {
      let response;
      if (searchGif) {
        response = await fetch(
          `https://api.giphy.com/v1/gifs/search?api_key=${apiKey}&limit=25&q=${searchGif}`
        );
      } else {
        response = await fetch(
          `https://api.giphy.com/v1/gifs/trending?api_key=${apiKey}&limit=25&`
        );
      }
      const data = await response.json();
      setGifs(data.data);
    } catch (error) {
      console.error("Error fetching trending GIFs:", error);
    }
  };

  const toggleGifContent = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const clickGif = (gifSrc) => {
    sendMessage(true, gifSrc);
    setIsOpen(false);
    setSearchGif("");
  };

  return (
    <div className="gif-box">
      <div className="gif-box__icon" onClick={() => toggleGifContent()}>
        GIF
      </div>
      <div
        className={"gif-box__content " + (isOpen ? "open" : "")}
        ref={boxRef}
      >
        <div className="gif-footer">
          <div className="search">
            <input
              className="input"
              value={searchGif}
              placeholder="Αναζήτηση gif"
              onChange={(e) => setSearchGif(e.target.value)}
            />
          </div>
          <span>Powered By Giphy</span>
        </div>
        <div className="gif-grid">
          {gifs.map((gif) => (
            <div
              key={"gif" + gif.id}
              className="item"
              onClick={() => clickGif(gif.images.fixed_height.url)}
            >
              <img src={gif.images.fixed_height.url} alt={gif.title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GifBox;
