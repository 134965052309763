import React, { useState, useEffect } from "react";
import "./quizMatchingAnswer.scss";
import { BiArrowBack } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function QuizMatchingAnswer({
  answers,
  answered,
  matchedAnswers,
  setMatchedAnswers,
}) {
  const [answersA, setAnswersA] = useState([]);
  const [answersB, setAnswersB] = useState([]);

  const [selectedA, setSelectedA] = useState(-1);
  const [selectedB, setSelectedB] = useState(-1);

  useEffect(() => {
    splitAnswers();
  }, []);

  useEffect(() => {
    if (selectedA !== -1 && selectedB !== -1) {
      addMatch();
    }
  }, [selectedA, selectedB]);

  useEffect(() => {
    if (answered) {
      checkIsCorrect();
    }
  }, [answered]);

  const checkIsCorrect = () => {
    const updatedAnswers = matchedAnswers.map((match) => ({
      ...match,
      correct: match.answerA.id === match.answerB.id,
    }));
    setMatchedAnswers(updatedAnswers);
  };

  const splitAnswers = () => {
    const tempA = [];
    const tempB = [];
    answers.map((answer) => {
      const objA = {
        id: answer.id,
        answer: JSON.parse(answer.answer)[0],
      };
      const objB = {
        id: answer.id,
        answer: JSON.parse(answer.answer)[1],
      };
      tempA.push(objA);
      tempB.push(objB);
    });
    shuffleArray(tempA);
    shuffleArray(tempB);

    setAnswersA(tempA);
    setAnswersB(tempB);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const populateAnswersA = () => {
    const matchedAnswerAIds = matchedAnswers.map((match) => match.answerA);
    const remainingA = [];
    answersA.map((answer) => {
      if (!matchedAnswerAIds.some((match) => match.id === answer.id)) {
        remainingA.push(answer);
      }
    });
    return remainingA
      .filter((answer) => {
        return !matchedAnswers.some((match) => match.answerA === answer.id);
      })
      .map((answer, index) => {
        return (
          <span
            key={"answerA" + index}
            className={answer.id === selectedA ? "selected" : ""}
            onClick={() => {
              if (selectedA === answer.id) {
                setSelectedA(-1);
              } else {
                setSelectedA(answer.id);
              }
            }}
          >
            {answer.answer}
          </span>
        );
      });
  };

  const populateAnswersB = () => {
    const matchedAnswerBIds = matchedAnswers.map((match) => match.answerB);
    const remainingB = [];
    answersB.map((answer) => {
      if (!matchedAnswerBIds.some((match) => match.id === answer.id)) {
        remainingB.push(answer);
      }
    });
    return remainingB.map((answer, index) => {
      return (
        <span
          key={"answerB" + index}
          className={answer.id === selectedB ? "selected" : ""}
          onClick={() => {
            if (selectedB === answer.id) {
              setSelectedB(-1);
            } else {
              setSelectedB(answer.id);
            }
          }}
        >
          {answer.answer}
        </span>
      );
    });
  };

  const checkRemaining = () => {
    const matchedAnswerBIds = matchedAnswers.map((match) => match.answerB);
    const remainingB = [];
    answersB.map((answer) => {
      if (!matchedAnswerBIds.some((match) => match.id === answer.id)) {
        remainingB.push(answer);
      }
    });
    return remainingB;
  };

  const addMatch = () => {
    if (selectedA !== -1 && selectedB !== -1) {
      const answerA = answers.find((answer) => answer.id === selectedA);
      const answerB = answers.find((answer) => answer.id === selectedB);
      setMatchedAnswers((prevMatchedAnswers) => [
        ...prevMatchedAnswers,
        {
          answerA: { id: answerA.id, answer: JSON.parse(answerA.answer)[0] },
          answerB: { id: answerB.id, answer: JSON.parse(answerB.answer)[1] },
          correct: null,
        },
      ]);
    }
    setSelectedA(-1);
    setSelectedB(-1);
  };

  const removeFromMatched = (indexToRemove) => {
    const updatedItems = matchedAnswers.filter(
      (_, index) => index !== indexToRemove
    );
    setMatchedAnswers(updatedItems);
  };

  const populateMatched = () => {
    return matchedAnswers.map((answer, index) => {
      return (
        <div
          key={"matched" + index}
          className={
            "item " +
            (answered ? (answer.correct === true ? "correct" : "wrong") : "")
          }
          onClick={() => removeFromMatched(index)}
        >
          <span>{answer.answerA.answer}</span>
          <BiArrowBack
            size={"25px"}
            color={"#6225E6"}
            style={iconStyle("transparent")}
          />
          <span>{answer.answerB.answer}</span>
        </div>
      );
    });
  };

  return (
    <div className="quiz-student__matching">
      {matchedAnswers.length > 0 && (
        <div className="matched">
          <span className="title">Επιλεγμένα</span>
          <div className="list">{populateMatched()}</div>
        </div>
      )}
      {checkRemaining().length ? (
        <span className="title">Επιλέξτε ζευγάρια</span>
      ) : (
        ""
      )}
      <div className="list">
        <div className="list-items a">{populateAnswersA()}</div>
        <div className="list-items b">{populateAnswersB()}</div>
      </div>
    </div>
  );
}

export default React.memo(QuizMatchingAnswer);
