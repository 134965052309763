import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const GoogleAuth = ({ googleLoginFunction }) => {
  const clientId =
    "1079920356418-krc8kovu986sj7kholnaau1kkvk6lk8u.apps.googleusercontent.com";

  const onSuccess = (credentialResponse) => {
    //   credentialResponse = `{
    //     "idToken":"eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjZTExYWVjZjllYjE0MDI0YTQ0YmJmZDFiY2Y4YjMyYTEyMjg3ZmEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiI4MzIzMzYyMjQyMTktZGljNGk3dG8xMTlzZzFlb3ByNnA2N2NidGZxcnUzZmIuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI4MzIzMzYyMjQyMTktcWxibDN1a20zaXBtbG8wMTFqcGcyNTFtbTFyc29nMTcuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDk2OTg4MTUyNDA4NzE4MjEyMDYiLCJlbWFpbCI6Imtvc3RhczExMDYyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiMzB1TzBLcUxlT2ZqdUZ5RTU5TFBEUSIsIm5vbmNlIjoiNlhBS0pWakFRNFdWS0REN0pJMEJSOEludWp3QWRoQTVlejl2RklUcXlQOCIsIm5hbWUiOiJLb25zdGFudGlub3MgRWZrYXJwaWRpcyIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NJMU5HXzFnQS1SYVl3eFJDOV9ZeUxCTC1JNDYtcmVBSGZ5Qjhyb2NSUmNRODdONnhERUl3PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IktvbnN0YW50aW5vcyIsImZhbWlseV9uYW1lIjoiRWZrYXJwaWRpcyIsImlhdCI6MTcxMzg5MTI5NiwiZXhwIjoxNzEzODk0ODk2fQ.Xu2YARr8ojn6WOrJ3AonAC0i8oUJt9nktYjHLYmLjRCz1s5sBLfVl_ZVkzi7lUi3pIcOzxCJje2uKTZZfP1k3nTUUWSHU5EyovevP3hMhJNbXiuO-G9lOCAjd5wlPrC0c8u3FaECs1PsZAptTQHVgvpiLml8QJdG2vP_dfqcXvKpd1B1dbVxUvTaJQDN_K86pF_9MJaQBQeoxahKLg2RBccDSoqB-L-u1Ikd5BsGvhv0p-oPAlHnKTxuDpAZd5gXgtsnFBsO9cap-b2tH2l-AyQB5vB9iB6AVsvhzNBFbg1ATASUGO9s6UXJLKD5GHN4ospHmNCFk7b8V5aUsqI2_A",
    //     "scopes"🙁
    //        "https://www.googleapis.com/auth/userinfo.email",
    //        "https://www.googleapis.com/auth/userinfo.profile",
    //        "openid"
    //     ],
    //     "serverAuthCode":"4/0AeaYSHCo89GwGPsjMvckHNWHIHTgiRnf02ihhdbvQtcrKis-w8Oczh90vUxMlXBjqFCSsA",
    //     "user":{
    //        "email":"kostas11062@gmail.com",
    //        "familyName":"Efkarpidis",
    //        "givenName":"Konstantinos",
    //        "id":"109698815240871821206",
    //        "name":"Konstantinos Efkarpidis",
    //        "photo":"https://lh3.googleusercontent.com/a/ACg8ocI1NG_1gA-RaYwxRC9_YyLBL-I46-reAHfyB8rocRRcQ87N6xDEIw=s120"
    //     }
    //  }`;
    console.log(" unformated ");
    console.log(credentialResponse);
    const base64Url = credentialResponse.credential.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    googleLoginFunction(jsonPayload);
    const { given_name, family_name, email } = JSON.parse(jsonPayload);
    // console.log(`Name: ${given_name} ${family_name}, Email: ${email}`);
  };

  const onError = () => {
    console.log("Login Failed");
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse);
        }}
        onError={() => {
          onError();
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleAuth;
