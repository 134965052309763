import React from "react";
import "../skeleton.scss";
import "./curriculum-skeleton.scss";
import SideFiltersSkeleton from "../sideFiltersSkeleton/sideFiltersSkeleton";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function CurriculumSkeleton() {
  return (
    <div className="users-skeleton skeleton">
      <SideFiltersSkeleton />
      <div className="curriculum-skeleton-wrapper wrapper">
        <LoaderCard cardSum={1} width={100} size="small" height="small" />
        <div className="text-end">
          <LoaderCard
            cardSum={1}
            width={30}
            size="small"
            height="small"
            align="right"
          />
        </div>
        <LoaderCard cardSum={4} width={100} size="small" height="small" />
      </div>
    </div>
  );
}

export default CurriculumSkeleton;
