import { calls } from "../config/db_config";
import { tokenUtils } from "./token-utils";

function postLog(location, logName, logDescription) {
  const body = {
    location: location,
    name: logName,
    description: logDescription,
  };

  return fetch(calls.insertLog, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: tokenUtils.getBearerToken(),
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
}

// GENERIC
function loggedIn() {
  return postLog("login", "logged-in", `the user logged in`);
}

function loggedOut() {
  return postLog("app", "logged-out", `the user logged out`);
}

// QUIZ
function logQuizStudent(score) {
  return postLog(
    "quiz-student",
    "quiz-finished",
    `quiz taken with [score=${score}]`
  );
}

function openQuiz() {
  return postLog("quiz-student", "quiz-opened", `quiz opened by a student`);
}

function closeQuiz() {
  return postLog("quiz-student", "quiz-closed", `quiz closed by a student`);
}

function closeQuizUnexpectedly() {
  return postLog(
    "quiz-student",
    "quiz-closed-unexpected",
    `quiz closed by a student unexpectedly`
  );
}

function quizAnswerTimes(quizQuestionsArray, quizTimesArray) {
  if (quizQuestionsArray.length === quizTimesArray.length) {
    quizQuestionsArray = JSON.stringify(quizQuestionsArray);
    quizTimesArray = JSON.stringify(quizTimesArray);
    const logDescription = `quiz answers [quizQuestionsArray=${quizQuestionsArray}][quizTimesArray=${quizTimesArray}]`;
    return postLog("quiz-student", "quiz-questions-time", logDescription);
  } else {
    return postLog(
      "quiz-student",
      "quiz-questions-time-error",
      "incorrect arrays were given"
    );
  }
}

export const logUtils = {
  postLog,
  logQuizStudent,
  loggedIn,
  loggedOut,
  openQuiz,
  closeQuiz,
  closeQuizUnexpectedly,
  quizAnswerTimes,
};
