import "./progressBar.scss";
import React from "react";
import "react-calendar-datetime-picker/dist/index.css";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

function ProgressBar({ force = false }) {
  const progressBar = useSelector((state) => state.progressBar.value);

  return (
    (progressBar || force) && (
      <div className="progress-bar">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  );
}

export default ProgressBar;
