import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Compress from "compress.js";
import { nanoid } from "nanoid";
import { tokenUtils } from "../../utils/token-utils";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          resolve({
            default: URL.createObjectURL(file), // Create a temporary URL for the local file
          });
        })
    );
  }

  abort() {
    console.log("Upload aborted");
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

const RichTextEditorComponent = ({
  imagesEndpoint,
  postImagesEndPoint,
  setText,
  saveFiles,
  fileNameStart,
  text,
}) => {
  useEffect(() => {
    if (saveFiles) {
      handleSave();
    }
  }, [saveFiles]);
  const [editorData, setEditorData] = useState(text ? text : "");

  useEffect(() => {
    if (text) {
      setEditorData(text);
    }
  }, [text]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    // setText(data);
  };

  async function compressAndUploadImages(images) {
    const compress = new Compress();
    const files = [];

    for (const image of images) {
      const response = await fetch(image.src);
      const blob = await response.blob();

      const compressedImages = await compress.compress([blob], {
        size: 2,
        quality: 0.75,
        maxWidth: 1920,
        maxHeight: 1920,
        resize: true,
      });

      const { data, ext } = compressedImages[0];
      const compressedBlob = Compress.convertBase64ToFile(data, ext);

      let fileExtension;

      if (blob.type === "image/jpeg") fileExtension = ".jpg";
      if (blob.type === "image/png") fileExtension = ".png";

      let fileName = fileNameStart + "-" + nanoid() + fileExtension;

      const newImageSrc = `resources/${imagesEndpoint}/${fileName}`;

      const newFile = new File([compressedBlob], fileName, {
        type: compressedBlob.type,
        lastModified: new Date().getTime(),
      });

      image.src = newImageSrc;
      files.push(newFile);
    }

    const config = {
      headers: { Authorization: tokenUtils.getBearerToken() },
    };
    const uploadedImages = [];

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("arrayOfFilesName", file);
      });

      try {
        await axios
          .post(postImagesEndPoint, formData, config)
          .then((response) => {
            if (response.data && response.data.path) {
              images.forEach((image, index) => {
                const baseUrl = "http://localhost:3001/";
                const newImageSrc = `${baseUrl}resources/${imagesEndpoint}/${response.data.path[index]}`;
                uploadedImages.push({
                  originalSrc: image.src,
                  newSrc: newImageSrc,
                });
              });
            }
          });
      } catch (ex) {
        console.log(ex);
      }
    }

    setEditorData("");
    return uploadedImages;
  }

  const onSave = async (htmlData) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, "text/html");
    const images = Array.from(doc.querySelectorAll("img"));

    const uploadedImages = await compressAndUploadImages(images);

    uploadedImages.forEach(({ originalSrc, newSrc }) => {
      const imageElements = doc.querySelectorAll(`img[src="${originalSrc}"]`);
      imageElements.forEach((img) => {
        img.src = newSrc;
      });
    });

    const serializer = new XMLSerializer();
    const updatedHtml = serializer.serializeToString(doc);
    let baseUrl;
    const currentUrl = window.location.href;
    if (currentUrl.includes("localhost:3000")) {
      baseUrl = "http://localhost:3001/";
    } else {
      const url = new URL(currentUrl);
      baseUrl = `${url.protocol}//${url.hostname}/`;
      if (url.port) {
        baseUrl = `${baseUrl}:${url.port}/`;
      }
    }
    const regex = new RegExp(`src="resources/${fileNameStart}/(.*?)"`, "g");
    const updatedHtmlWithBase = updatedHtml.replace(
      regex,
      `src="${baseUrl}resources/${fileNameStart}/$1"`
    );

    const bodyContent = updatedHtmlWithBase.match(/<body[^>]*>(.*?)<\/body>/s);
    const result = bodyContent ? bodyContent[1] : null;
    setText(result);
  };

  const handleSave = async () => {
    await onSave(editorData);
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleEditorChange}
        className="test"
        config={{
          extraPlugins: [MyCustomUploadAdapterPlugin],
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "insertTable",
              "|",
              "uploadImage",
              "blockQuote",
              "|",
              "undo",
              "redo",
            ],
            shouldNotGroupWhenFull: true,
          },
        }}
      />
    </div>
  );
};

export default RichTextEditorComponent;
