import React from "react";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function SideFiltersSkeleton() {
  return (
    <div className="sideFiltersSkeleton">
      {/* <LoaderCard cardSum={1} width={30} size="small" height="small" /> */}
      <LoaderCard cardSum={10} width={100} size="large" />
    </div>
  );
}

export default SideFiltersSkeleton;
