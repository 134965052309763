import React, { useState, useEffect, useContext, useRef } from "react";
import "./date-section.scss";
import { SocketContext } from "../../../../app/socket";
import Draggable from "react-draggable";
import { useEventContext } from "../../eventContext";

const defaultStartTime = new Date(); // or '2024-05-27T08:00:00'
const defaultEndTime = new Date();

function RoundHalfDown(num) {
  return Math.round(num / 15) * 15;
}
const TimeSlot = ({ hour }) => (
  <div className="time-slot">
    <span className="time-slot__hour">{hour}:00</span>
  </div>
);

function getMinutes(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  return hours * 60 + minutes;
}

function TimeLine({ eventDepartment, selectedClassrooms }) {
  const socketContext = useContext(SocketContext);

  const { eventStartDate, setEventStartDate } = useEventContext();
  const { eventEndDate, setEventEndDate } = useEventContext();
  const { eventId } = useEventContext();

  const { finalTestForErrors, setFinalTestForErrors } = useEventContext();
  const { setNoFinalErrors } = useEventContext();
  const { setErrorMsg } = useEventContext();

  useEffect(() => {
    getCurrentEvents();
  }, [eventStartDate]);

  useEffect(() => {
    if (finalTestForErrors) {
      setFinalTestForErrors(false);
      if (checkForErrors() && checkForErrors().length) {
        setNoFinalErrors("has-error");
        setErrorMsg(checkForErrors());
      } else {
        setNoFinalErrors("no-errors");
        setErrorMsg("no-error");
      }
    }
  }, [finalTestForErrors]);

  const checkForErrors = () => {
    function doEventsOverlap(event1, event2) {
      // Extract hours and minutes from the dates
      const start1 =
        new Date(event1.start).getHours() * 60 +
        new Date(event1.start).getMinutes();
      const end1 =
        new Date(event1.end).getHours() * 60 +
        new Date(event1.end).getMinutes();
      const start2 =
        new Date(event2.start).getHours() * 60 +
        new Date(event2.start).getMinutes();
      const end2 =
        new Date(event2.end).getHours() * 60 +
        new Date(event2.end).getMinutes();

      // Compare only hours and minutes (converted to minutes)
      return !(end1 <= start2 || start1 >= end2);
    }
    let msg = "";
    const overlapFound = currentEvents.some((event) => {
      let event1 = {
        start: new Date(event.start_at),
        end: new Date(event.finish_at),
      };

      let event2 = {
        start: new Date(eventStartDate),
        end: new Date(eventEndDate),
      };

      if (doEventsOverlap(event1, event2)) {
        let classroomsOverlap = false;
        let professorOverlap = false;
        let departmentOverlap = false;

        let type = "μάθημα";
        if (eventType == "exam") {
          type = "διαγώνισμα";
        } else if (eventType == "test") {
          type = "τεστ";
        }

        let overlapType = "μάθημα";
        if (event.type == "exam") {
          overlapType = "διαγώνισμα";
        } else if (event.type == "test") {
          overlapType = "τεστ";
        }

        try {
          let eventClassrooms = JSON.parse(event.classroom_id);
          eventClassrooms.map((classroom) => {
            if (eventClassrooms.includes.classroom) {
              classroomsOverlap = true;
            }
          });
        } catch (e) {
          console.log(e);
        }

        if (classroomsOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με την ίδια αίθουσα.";
        } else if (departmentOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με το ίδιο τμήμα.";
        } else if (professorOverlap) {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType +
            " με τον ίδιο καθηγητή.";
        } else {
          msg =
            "Το επιλεγμένο χρονοδιάγραμμα για το " +
            type +
            " σας επικαλύπτει ένα άλλο " +
            overlapType;
        }

        return true;
      }

      return false; // Continues to the next item
    });

    return msg;
  };

  const getCurrentTime = () => {
    const now = new Date();
    return formatTime(now);
  };

  const getCurrentTimePlusTwoHours = () => {
    const now = new Date();
    now.setHours(now.getHours() + 2);
    return formatTime(now);
  };

  const formatTime = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return NaN;
    }
    let hours = date.getHours();
    let minutes = date.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}`;
  };

  const { eventRepeatType, setEventRepeatType, eventType } = useEventContext();

  const handleStartTimeChange = (e) => {
    let startTime = e.target.value;
    let startTimeMinutes = getMinutes(startTime) - 1440;
    setEventStartMinutes(startTimeMinutes);
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    let endTime = e.target.value;
    let endTimeMinutes = getMinutes(endTime) - 1440;
    setEventEndMinutes(endTimeMinutes);
    setEndTime(e.target.value);
  };

  const defaultStartTime = eventStartDate
    ? new Date(eventStartDate)
    : new Date();
  let defaultEndTime;

  if (eventEndDate) {
    defaultEndTime = new Date(eventEndDate);
    // Check if dates are the same and ignore time part
    if (defaultEndTime.toDateString() === defaultStartTime.toDateString()) {
      // Adjust end time to be 2 hours ahead
      defaultEndTime = new Date(
        defaultStartTime.getTime() + 2 * 60 * 60 * 1000
      );
    }
  } else {
    // No end date provided, set to 2 hours ahead
    defaultEndTime = new Date(defaultStartTime.getTime() + 2 * 60 * 60 * 1000);
  }

  const [startTime, setStartTime] = useState(
    isNaN(formatTime(defaultStartTime))
      ? getCurrentTime()
      : formatTime(defaultStartTime)
  );
  const [endTime, setEndTime] = useState(
    isNaN(formatTime(defaultEndTime))
      ? getCurrentTimePlusTwoHours()
      : formatTime(defaultEndTime)
  );

  let currentTime = defaultStartTime.getHours();
  let currentEndTime = defaultEndTime.getHours();
  let currentEventTime = currentTime;
  if (currentTime > 22) {
    currentEventTime = 22;
  }

  currentEventTime =
    currentTime < 10
      ? "0"
      : "" + currentTime + ":" + defaultStartTime.getMinutes();
  let currentEventEndTime =
    currentTime + 2 < 10
      ? "0"
      : "" + currentEndTime + ":" + defaultEndTime.getMinutes();

  const [selectedDate, setSelectedDate] = useState(
    eventStartDate ? new Date(eventStartDate) : new Date()
  );

  const [eventStartMinutes, setEventStartMinutes] = useState(
    getMinutes(currentEventTime) - 1440
  );
  const [eventEndMinutes, setEventEndMinutes] = useState(
    getMinutes(currentEventEndTime) - 1440
  );

  useEffect(() => {
    setStartTime(getEventStartTime());
    if (eventStartDate.length < 24) {
      setEventStartDate((currentDateTime) => {
        const datePart = currentDateTime.split(" ")[0];
        const newDateTime = `${datePart} ${getEventStartTime()}:00`; // Assuming seconds are always 00
        return newDateTime;
      });
    }
  }, [eventStartMinutes]);

  useEffect(() => {
    setEndTime(getEventEndTime());
    if (eventEndDate.length < 24) {
      setEventEndDate((currentDateTime) => {
        const datePart = currentDateTime.split(" ")[0];
        const newDateTime = `${datePart} ${getEventEndTime()}:00`; // Assuming seconds are always 00
        return newDateTime;
      });
    }
  }, [eventEndMinutes]);

  const [currentEvents, setCurrentEvents] = useState([]);
  const scrollableRef = useRef(null);

  useEffect(() => {
    getCurrentEvents();
    getEventStartTime();
  }, [selectedClassrooms]);

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 500;
    }
  }, [scrollableRef]);

  const getEventStartTime = () => {
    let startHour = Math.floor((eventStartMinutes + 1440) / 60);
    let startMinutes = (eventStartMinutes + 1440) % 60;
    if (startHour < 10) {
      startHour = "0" + startHour;
    }
    if (startMinutes < 10) {
      startMinutes = "0" + Math.abs(startMinutes);
    }
    let completeTime = startHour + ":" + startMinutes;
    return completeTime;
  };

  const getEventEndTime = () => {
    let startHour = Math.floor((eventEndMinutes + 1440) / 60);
    let startMinutes = (eventEndMinutes + 1440) % 60;
    if (startHour < 10) {
      startHour = "0" + startHour;
    }
    if (startMinutes < 10) {
      startMinutes = "0" + Math.abs(startMinutes);
    }
    let completeTime = startHour + ":" + startMinutes;
    return completeTime;
  };

  const getCurrentEvents = () => {
    let actualDate = eventStartDate ? eventStartDate : selectedDate;
    actualDate = new Date(actualDate);
    if (actualDate) {
      const args = {
        day: actualDate.getDate(),
        month: actualDate.getMonth(),
        year: actualDate.getFullYear(),
        classroom_id: selectedClassrooms,
        department_id: eventDepartment,
        event_id: eventId ? eventId : "",
      };

      const getEventsListener = (data) => {
        setCurrentEvents(data);
      };

      socketContext.socket.on("eventsAvailability", getEventsListener);
      socketContext.socket.emit("getEventsAvailability", args);
      socketContext.socket.on("refreshEventsAvailability", () => {
        socketContext.socket.emit("getEventsAvailability", args);
      });
    }
  };

  const handleDragStop = (newStartPixel) => {
    const duration = eventEndMinutes - eventStartMinutes;
    let newStartMinutes = Math.floor(RoundHalfDown(newStartPixel));

    setEventStartMinutes(newStartMinutes);
    setEventEndMinutes(newStartMinutes + duration);
  };

  const handleDrag = (e, data) => {
    const { y } = data;
    const buffer = 30; // This is the distance from the bottom edge at which scrolling should start.
    const { scrollTop, offsetHeight } = scrollableRef.current;
    if (y + 1440 - scrollTop > offsetHeight - buffer) {
      scrollableRef.current.scrollTop += buffer;
    }
  };

  const populateCurrentEvents = () => {
    return currentEvents.map((event, key) => {
      let topPosition =
        new Date(event.start_at).getHours() * 60 +
        new Date(event.start_at).getMinutes();
      let bottomPosition =
        new Date(event.finish_at).getHours() * 60 +
        new Date(event.finish_at).getMinutes();
      let height = bottomPosition - topPosition;
      return (
        <div
          key={`event-${key}`}
          className="time-slot__taken"
          style={{
            position: "absolute",
            top: `${topPosition}px`,
            height: `${height}px`,
            width: "100%",
          }}
        >
          <span className="time-slot__taken-title">
            {event.title} -{" "}
            {event.type == "lecture"
              ? "Μάθημα"
              : event.type == "exam"
              ? "Διαγώνισμα"
              : "Τεστ"}
          </span>
        </div>
      );
    });
  };

  return (
    <div className="timeline-wrapper">
      <div className="time">
        <div className="item">
          <label htmlFor="startTime">Ώρα έναρξης:</label>
          <input
            type="time"
            className="input"
            id="startTime"
            value={startTime}
            onChange={handleStartTimeChange}
          />
        </div>
        <div className="item">
          <label htmlFor="endTime">Ώρα λήξης:</label>
          <input
            type="time"
            className="input"
            id="endTime"
            value={endTime}
            onChange={handleEndTimeChange}
          />
        </div>
      </div>
      <div ref={scrollableRef} className="timeline no-scollbar">
        <div style={{ position: "relative", height: "1440px" }}>
          {" "}
          {[...Array(24).keys()].map((hour, key) => (
            <TimeSlot key={"day-hour" + key} hour={hour} />
          ))}
          <DraggableEvent
            getEventStartTime={getEventStartTime}
            eventStartMinutes={eventStartMinutes}
            eventEndMinutes={eventEndMinutes}
            onDragStop={handleDragStop}
            getEventEndTime={getEventEndTime}
            handleDrag={handleDrag}
          />
          <div className="time-slot__taken-container">
            {populateCurrentEvents()}
          </div>
          <div
            className="time-slot__current"
            style={{
              top: `${new Date().getHours() * 60 + new Date().getMinutes()}px`,
            }}
          ></div>
        </div>
      </div>
      {eventType == "lecture" ? (
        <div className="repeat-type">
          <span className="label">Επανάληψη μαθήματος</span>
          <select
            value={eventRepeatType}
            onChange={(e) => setEventRepeatType(e.target.value)}
            className="input"
          >
            <option value="">Καμία επανάληψη</option>
            <option value="weekly">Εβδομαδιαία επανάληψη</option>
          </select>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const DraggableEvent = ({
  getEventStartTime,
  eventStartMinutes,
  eventEndMinutes,
  onDragStop,
  getEventEndTime,
  handleDrag,
}) => {
  return (
    <Draggable
      axis="y"
      bounds="parent"
      position={{ x: 0, y: RoundHalfDown(eventStartMinutes) }}
      onStop={(e, data) => onDragStop(data.y)}
      onDrag={handleDrag}
    >
      <div
        className="time-slot__event"
        style={{ height: eventEndMinutes - eventStartMinutes + "px" }}
      >
        <span className="time-slot__event-title">
          {getEventStartTime()} - {getEventEndTime()}
        </span>
      </div>
    </Draggable>
  );
};

export default TimeLine;
