import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../app/socket";
import { useSelector } from "react-redux";
import "./activate-profile.scss";
import { BiCheck, BiX, BiTrash, BiMailSend, BiPhone } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import { useNavigate } from "react-router-dom";

function ActivateProfile({ announcement }) {
  const socketContext = useContext(SocketContext);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const profile = useSelector((state) => state.profile.value);
  const navigate = useNavigate();

  const [parentEmail, setParentEmail] = useState("");
  const [parentPhone, setParentPhone] = useState("");
  const [personalPhone, setPersonalPhone] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  const activateUser = () => {
    if (!parentEmail) {
      setErrorMsg("Παρακαλώ εισάγεται email γονέα");
    } else if (!parentPhone) {
      setErrorMsg("Παρακαλώ εισάγεται κινητό γονέα");
    } else if (!personalPhone) {
      setErrorMsg("Παρακαλώ εισάγεται προσωπικό κινητό");
    } else {
      let body = {
        for_activate: true,
        user_id: profile.user_id,
        parent_email: parentEmail,
        personal_phone: personalPhone,
        parent_phone: parentPhone,
      };

      socketContext.socket.emit("updateUser", body);
      navigate("/");
    }
  };

  return (
    <div className="activate-profile">
      <div className="modal-background show"></div>
      <div className="activate-profile__modal">
        <span className="modal-header">
          Παρακαλώ συμπληρώστε τα παρακάτω για να συνεχίσετε
        </span>
        <div className="form">
          <div className="logo-input">
            <BiMailSend
              size={"30px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
            <input
              type="text"
              placeholder="Email γονέα"
              value={parentEmail}
              onChange={(e) => setParentEmail(e.target.value)}
            />
          </div>
          <div className="logo-input">
            <BiPhone
              size={"30px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
            <input
              type="number"
              placeholder="Κινητό γονέα"
              value={parentPhone}
              onChange={(e) => setParentPhone(e.target.value)}
            />
          </div>
          <div className="logo-input">
            <BiPhone
              size={"30px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
            <input
              type="number"
              placeholder="Προσωπικό κινητό"
              value={personalPhone}
              onChange={(e) => setPersonalPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="error">
          <span>{errorMsg}</span>
        </div>
        <div className="actions">
          <button onClick={() => activateUser()} className="cta cta-fill">
            Αποθήκευση
          </button>
        </div>
      </div>
    </div>
  );
}

export default ActivateProfile;
