import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiX,
  BiArrowFromRight,
  BiArrowFromLeft,
  BiCarousel,
  BiAddToQueue,
  BiSearch,
  BiAbacus,
} from "react-icons/bi";
import { motion } from "framer-motion";
import "./professorExams.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import { iconStyle } from "../../../../utils/generalUtils";
import { EventProvider } from "../../../../components/ConfigureEvent/eventContext";
import ConfigureEvent from "../../../../components/ConfigureEvent/configureEvent";
import ProfessorExamsCard from "./professorExamsCard";
import MobileSideFilters from "../../../../components/SideFilters/MobileSideFilters/mobileSideFilters";
import LoaderCard from "../../../../components/LoaderCard/loaderCard";

function ProfessorExams({
  allExams,
  setAllExams,
  searchText,
  setSearchText,
  gotExams,
}) {
  const socketContext = useContext(SocketContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [gotType, setGotType] = useState(false);
  const [configureSlide, setConfigureSlide] = useState("type");
  const [overflow, setOverflow] = useState("hidden");
  const showCreateRender = useRef(0);

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [eventToEdit, setEventToEdit] = useState();
  const [configureSlideEdit, setConfigureSlideEdit] = useState("general");
  const [editIsOpen, setEditIsOpen] = useState(false);

  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    setModalOpen(showCreateEvent);
    if (showCreateEvent) {
      setOverflow("hidden");
      document.body.style.overflowY = "hidden";
    } else {
      setOverflow("auto");
      document.body.style.overflowY = "auto";
      setGotType(false);
    }
  }, [showCreateEvent]);

  const openCreateEventModal = () => {
    if (!showCreateEvent) {
      setShowCreateEvent(true);
      setOverflow("hidden");
      document.body.style.overflowY = "hidden";
    }
  };

  useEffect(() => {
    if (!showCreateEvent && showCreateRender.current !== 0) {
      // return getEvents();
    }
    showCreateRender.current += 1;
  }, [showCreateEvent]);

  const populateExams = () => {
    return allExams.map((exam, index) => {
      return (
        <ProfessorExamsCard
          key={"professorExamsCard" + index}
          event={exam}
          setEditIsOpen={setEditIsOpen}
          setEventToEdit={setEventToEdit}
        />
      );
    });
  };

  const searchClick = () => {
    if (!searchOpen) {
      setSearchOpen(true);
    }
  };

  const mobileFiltersClick = () => {
    if (!mobileFiltersOpen) {
      setMobileFiltersOpen(true);
    }
  };

  return (
    <div className="professor-exams">
      {editIsOpen ? (
        <div
          className={
            "events__item-create got-type " +
            (editIsOpen ? " is-open" : "") +
            " " +
            configureSlideEdit
          }
        >
          <EventProvider
            title={eventToEdit.title}
            type={eventToEdit.type}
            classId={eventToEdit.class_id}
            departments={eventToEdit.department_id}
            professors={eventToEdit.professors}
            startDate={eventToEdit.start_at}
            endDate={eventToEdit.finish_at}
            id={eventToEdit.event_id}
            classroom={eventToEdit.classroom_id}
            repeatType={eventToEdit.repeat_type}
            detailedRank={eventToEdit.detailed_rank}
            maxRank={eventToEdit.max_rank}
            eventOnlineGrades={eventToEdit.exam_details}
          >
            <ConfigureEvent
              isEdit={true}
              setConfigureSlide={setConfigureSlideEdit}
              configureSlide={configureSlideEdit}
              setShowCreateEvent={setEditIsOpen}
            />
          </EventProvider>
        </div>
      ) : (
        ""
      )}
      {showCreateEvent ? (
        <div
          onClick={() => {
            setShowCreateEvent(false);
            setConfigureSlide("type");
          }}
          className={"modal-background show " + (showCreateEvent ? "show" : "")}
        ></div>
      ) : (
        ""
      )}
      {editIsOpen ? (
        <div
          onClick={() => {
            setEditIsOpen(false);
            setConfigureSlideEdit("general");
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <div className="professor-exams__list">
        <div
          className={
            "professor-exams__header " + (showCreateEvent ? "open" : "")
          }
        >
          <motion.div
            layout
            className={
              "events__item-create " +
              (searchOpen || mobileFiltersOpen ? " closed " : " open ") +
              (showCreateEvent ? " is-open" : "") +
              (gotType ? " got-type " : "") +
              " " +
              configureSlide
            }
            onClick={() => {
              openCreateEventModal();
            }}
            initial={"closed"}
            animate={showCreateEvent ? "open" : "closed"}
            exit={"closed"}
            whileHover={
              !showCreateEvent
                ? { scale: 1.03, transition: { duration: 0.25 } }
                : {}
            }
          >
            {!showCreateEvent && (
              <motion.div
                className="events__item-create-info"
                layout="position"
              >
                <BiCarousel
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <span className="label-create">Προγραμματισμός εξέτασης</span>
              </motion.div>
            )}
            {showCreateEvent && (
              <motion.div
                key={"createContentModal"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="events__item-create-motion"
                style={{ overflowY: overflow }}
              >
                <div
                  className="events__item-create-close"
                  onClick={() => {
                    setShowCreateEvent(false);
                    setConfigureSlide("type");
                  }}
                >
                  <BiX
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                </div>
                <EventProvider>
                  <ConfigureEvent
                    setConfigureSlide={setConfigureSlide}
                    setShowCreateEvent={setShowCreateEvent}
                    setGotType={setGotType}
                    configureSlide={configureSlide}
                    events={allExams}
                    setEvents={setAllExams}
                    showLecture={false}
                  />
                </EventProvider>
              </motion.div>
            )}
          </motion.div>
          <div
            className={
              "professor-exams__search " + (searchOpen ? "open" : "closed")
            }
            onClick={() => searchClick()}
          >
            <input
              className="input"
              placeholder="Αναζητήστε Εξέταση"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {searchOpen ? (
              <BiX
                className="search-close"
                onClick={() => setSearchOpen(false)}
                size={"40px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            ) : (
              <BiSearch
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
          </div>
          <div
            className={
              "professor-exams__mobile-filters " +
              (mobileFiltersOpen && !searchOpen ? "open" : "closed")
            }
            onClick={() => mobileFiltersClick()}
          >
            <div className="professor-exams__mobile-filters-preview">
              {mobileFiltersOpen ? (
                <span onClick={() => setMobileFiltersOpen(false)}>
                  Φίλτρα
                  <BiX
                    size={"25px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                </span>
              ) : (
                <BiAbacus
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              )}
            </div>
            <MobileSideFilters />
          </div>
        </div>
        {gotExams ? (
          populateExams()
        ) : window.innerWidth > 800 ? (
          <LoaderCard cardSum={16} width={25} />
        ) : (
          <LoaderCard cardSum={10} width={100} />
        )}
      </div>
    </div>
  );
}

export default ProfessorExams;
