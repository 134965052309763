import React, { useState, useEffect } from "react";
import "./timer.scss";

const Timer = ({ quizTime, onTimerExpired }) => {
  const [time, setTime] = useState(quizTime * 60); // Convert minutes to seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          onTimerExpired();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="timer">
      <span className="timer__time">{formatTime(time)}</span>
    </div>
  );
};

export default Timer;
