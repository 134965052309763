import "./school-section.scss";
import { BiUserPin, BiUser, BiUserVoice, BiCategoryAlt } from "react-icons/bi";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../../../app/socket";
import { useSelector } from "react-redux";
import { iconStyle } from "../../../../utils/generalUtils";

function SchoolSection() {
  const socketContext = useContext(SocketContext);

  const [studentCount, setStudentCount] = useState(0);
  const [professorCount, setProfessorCount] = useState(0);
  const [classesCount, setClassesCount] = useState(0);
  const [departmentsCount, setDepartmentsCount] = useState(0);
  const [chaptersCount, setChaptersCount] = useState(0);
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  useEffect(() => {
    const cleanUpGetTotalStudents = getTotalStudents();
    const cleanUpGetTotalProfessors = getTotalProfessors();
    const cleanUpGetTotalClasses = getTotalClasses();
    const cleanUpGetTotalChapters = getTotalChapters();
    const cleanUpGetTotalDepartments = getTotalDepartments();
    return () => {
      cleanUpGetTotalStudents();
      cleanUpGetTotalProfessors();
      cleanUpGetTotalDepartments();
      cleanUpGetTotalClasses();
      cleanUpGetTotalChapters();
    };
  }, [sideFiltersClassId]);

  const getTotalClasses = () => {
    let args = {};
    const getTotalClassesListener = (data) => {
      if (!sideFiltersClassId || sideFiltersClassId === -1) {
        if (data && data[0] && data[0].total_count) {
          setClassesCount(data[0].total_count);
        }
      } else {
        setClassesCount(1);
      }
    };

    socketContext.socket.on("totalClasses", getTotalClassesListener);
    socketContext.socket.emit("getTotalClasses", args);

    return () => {
      socketContext.socket.off("getTotalClasses", getTotalClassesListener);
      socketContext.socket.off("totalClasses", getTotalDepartments);
    };
  };

  const getTotalChapters = () => {
    let args = { class_id: sideFiltersClassId };
    const getTotalChaptersListener = (data) => {
      if (data && data[0] && data[0].total_count) {
        setChaptersCount(data[0].total_count);
      } else {
        setChaptersCount(0);
      }
    };

    socketContext.socket.on("totalChapters", getTotalChaptersListener);
    socketContext.socket.emit("getTotalChapters", args);

    return () => {
      socketContext.socket.off("getTotalChapters", getTotalChaptersListener);
      socketContext.socket.off("totalChapters", getTotalChapters);
    };
  };

  const getTotalDepartments = () => {
    let args = { class_id: sideFiltersClassId };
    const getTotalDepartmentsListener = (data) => {
      if (data && data[0] && data[0].total_count) {
        setDepartmentsCount(data[0].total_count);
      } else {
        setDepartmentsCount(0);
      }
    };

    socketContext.socket.on("totalDepartments", getTotalDepartmentsListener);
    socketContext.socket.emit("getTotalDepartments", args);

    return () => {
      socketContext.socket.off(
        "getTotalDepartments",
        getTotalDepartmentsListener
      );
      socketContext.socket.off("totalDepartments", getTotalDepartments);
    };
  };

  const getTotalStudents = () => {
    const uniqueKey = "statStudent";
    let args = {
      type: "student",
      uniqueKey: uniqueKey,
      class_id: sideFiltersClassId,
    };

    const getTotalStudentsListener = (data) => {
      if (data && data[0] && data[0].total_count) {
        setStudentCount(data[0].total_count);
      } else {
        setStudentCount(0);
      }
    };

    const refreshTotalStudentListener = () => {
      socketContext.socket.emit("getTotalUsers", args);
    };

    socketContext.socket.on("totalUsers" + uniqueKey, getTotalStudentsListener);
    socketContext.socket.emit("getTotalUsers", args);
    socketContext.socket.on(
      "refreshTotalUsers",
      () => refreshTotalStudentListener
    );

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off(
        "totalUsers" + uniqueKey,
        getTotalStudentsListener
      );
      socketContext.socket.off("getTotalUsers", getTotalStudentsListener);
      socketContext.socket.off(
        "refreshTotalUsers",
        refreshTotalStudentListener
      );
    };
  };

  const getTotalProfessors = () => {
    const uniqueKey = "statProf";
    let args = {
      profAndAdmins: true,
      uniqueKey: uniqueKey,
      class_id: sideFiltersClassId,
    };

    const getTotalStudentsListener = (data) => {
      if (data && data[0] && data[0].total_count) {
        setProfessorCount(data[0].total_count);
      } else {
        setProfessorCount(0);
      }
    };

    const refreshTotalStudentListener = () => {
      socketContext.socket.emit("getTotalUsers", args);
    };

    socketContext.socket.on("totalUsers" + uniqueKey, getTotalStudentsListener);
    socketContext.socket.emit("getTotalUsers", args);
    socketContext.socket.on(
      "refreshTotalUsers",
      () => refreshTotalStudentListener
    );

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off(
        "totalUsers" + uniqueKey,
        getTotalStudentsListener
      );
      socketContext.socket.off("getTotalUsers", getTotalStudentsListener);
      socketContext.socket.off(
        "refreshTotalUsers",
        refreshTotalStudentListener
      );
    };
  };

  return (
    <div className={"section school-section"}>
      <span className="section__title">Γενικά</span>
      <div className="school-section__general">
        <div className="item">
          <div className="item__icon">
            <BiUser style={iconStyle("#6225E6")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Αρ. Μαθητών</span>
            <span className="label">{studentCount}</span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiUserVoice
              style={iconStyle("#FFD43D")}
              color={"white"}
              size={50}
            />
          </div>
          <div className="item__details">
            <span className="title">Αρ. Καθηγητών</span>
            <span className="label yellow">{professorCount}</span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiUserPin style={iconStyle("#ff9797")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Αρ. Τμημάτων</span>
            <span className="label red">{departmentsCount}</span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiCategoryAlt
              style={iconStyle("#25e662")}
              color={"white"}
              size={50}
            />
          </div>
          <div className="item__details">
            <span className="title">Αρ. Κεφαλαίων</span>
            <span className="label green">{chaptersCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SchoolSection;
