import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { BiCalendar, BiHome, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import "./online-exam-preview.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function OnlineExamPreview({ event }) {
  const socketContext = useContext(SocketContext);

  const [examDetails, setExamDetails] = useState([]);

  useEffect(() => {
    if (event.exam_details) {
      try {
        const details = JSON.parse(decodeURIComponent(event.exam_details));
        console.log(details);
        setExamDetails(details);
      } catch (e) {
        console.log(e);
      }
    }
  }, [event]);

  const descriptionImageClick = () => {};

  const populateThemata = () => {
    return examDetails.map((detail, index) => {
      return (
        <div className="themata__item" key={"eventThema" + index}>
          <span className="subtitle">Θέμα {index + 1}ο</span>
          {/* <span className="title">{detail.title}</span> */}
          <CKEditor
            editor={ClassicEditor}
            data={detail}
            config={{
              toolbar: [], // Configures an empty toolbar
            }}
            disabled={true} // Disable editing
          />
        </div>
      );
    });
  };

  return (
    <div className="online-exam-preview">
      {examDetails ? <div className="themata">{populateThemata()}</div> : ""}
    </div>
  );
}

export default OnlineExamPreview;
