import React from "react";
import "../skeleton.scss";
import SideFiltersSkeleton from "../sideFiltersSkeleton/sideFiltersSkeleton";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function StatisticsSkeleton() {
  return (
    <div className="users-skeleton skeleton">
      <SideFiltersSkeleton />
      <div className="users-wrapper wrapper">
        <LoaderCard cardSum={1} width={30} size="small" height="small" />
        <LoaderCard
          cardSum={4}
          width={20}
          size="small"
          height="small"
          mobileWidth={50}
        />
        <LoaderCard cardSum={1} width={30} size="small" height="small" />
        <LoaderCard
          cardSum={4}
          width={20}
          size="small"
          height="small"
          mobileWidth={50}
        />
        <LoaderCard cardSum={1} width={30} size="small" height="small" />
        <LoaderCard cardSum={3} width={30} size="small" />
        <LoaderCard cardSum={1} width={100} size="large" height="xl" />
      </div>
    </div>
  );
}

export default StatisticsSkeleton;
