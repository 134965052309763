import { cookieUtils } from "./cookie-parser";

export const tokenToCookie = function (userObject, reload) {
  console.log(" user obkect ");
  console.log(userObject);
  if (userObject.birthday) {
    delete userObject.birthday;
  }
  if (userObject.address) {
    delete userObject.address;
  }
  if (userObject.house_phone) {
    delete userObject.house_phone;
  }
  if (userObject.joined_at) {
    delete userObject.joined_at;
  }
  if (userObject.school) {
    delete userObject.school;
  }
  if (userObject.address_number) {
    delete userObject.address_number;
  }
  if (userObject.postal_code) {
    delete userObject.postal_code;
  }
  if (userObject.address_area) {
    delete userObject.address_area;
  }
  if (userObject.parent_first_name) {
    delete userObject.parent_first_name;
  }
  if (userObject.parent_first_name2) {
    delete userObject.parent_first_name2;
  }
  if (userObject.parent_last_name) {
    delete userObject.parent_last_name;
  }
  if (userObject.parent_last_name2) {
    delete userObject.parent_last_name2;
  }
  if (userObject.work_phone) {
    delete userObject.work_phone;
  }
  if (userObject.is_archived) {
    delete userObject.is_archived;
  }
  if (userObject.deleted_date) {
    delete userObject.deleted_date;
  }
  if (userObject.school_grade) {
    delete userObject.school_grade;
  }

  delete userObject.grade;
  delete userObject.last_quiz;
  delete userObject.first_name;
  delete userObject.last_name;
  delete userObject.profile_picture;
  delete userObject.email;
  delete userObject.password;
  delete userObject.last_page;
  delete userObject.paid_lectures;
  delete userObject.address;
  delete userObject.joined_lectures;

  const cookie = JSON.stringify(userObject);
  console.log(" final cookie ");
  console.log(cookie);

  cookieUtils.setCookie("auth", cookie, 200);
  if (reload) {
    window.location.reload();
  }
};

export const removeToken = function () {
  cookieUtils.eraseCookie("auth");
};
