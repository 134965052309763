import React, { useRef, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { motion, usePresence } from "framer-motion";
import { SocketContext } from "../../app/socket";

function InactiveUserItem({ user, inactiveUserActivate }) {
  const socketContext = useContext(SocketContext);

  const containerRef = useRef(null);
  const nonActiveRef = useRef(null);
  const studentRef = useRef(null);
  const professorRef = useRef(null);
  const parentRef = useRef(null);
  // activeState(nonActiveRef);

  useEffect(() => {
    activeState(nonActiveRef.current);
  }, []);

  const currentUser = useSelector((state) => state.profile.value);

  const selected = "";

  const [isPresent, safeToRemove] = usePresence();
  const transition = {
    type: "spring",
    stiffness: 500,
    damping: 70,
    mass: 5,
    duration: 2,
  };
  const animations = {
    layout: true,
    initial: "in",
    style: {
      position: isPresent ? "relative" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scale: 1, opacity: 1 },
      out: { scale: 0, opacity: 0, zIndex: -1 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  const activeState = (btnItem) => {
    const btnActive = containerRef.current.getElementsByClassName(
      "js-btn-slide-active"
    )[0];
    let itemBounding = containerRef.current.getBoundingClientRect();
    let btnBounding = btnItem.getBoundingClientRect();

    btnActive.style.opacity = 1;
    btnActive.style.left =
      Math.round(btnBounding.left) - Math.round(itemBounding.left) + "px";
    let width = btnItem.offsetWidth;
    btnActive.style.width = width + "px";
  };

  const buttonClick = (item, btnItem) => {
    nonActiveRef.current.classList.remove("active");
    studentRef.current.classList.remove("active");

    if (currentUser.user_type === "admin") {
      professorRef.current.classList.remove("active");
    }

    btnItem.current.classList.add("active");
    activeState(btnItem.current);
    setTimeout(() => {
      if (btnItem === studentRef) {
        activateUser("student");
      } else if (btnItem === professorRef) {
        console.log("is professor");
        activateUser("professor");
      } else if (btnItem === parentRef) {
        activateUser("parent");
      }
    }, "500");
  };

  const activateUser = (type) => {
    const body = {
      user_id: user.user_id,
      type: type,
      activate: 1,
    };
    user.user_type = type;
    socketContext.socket.emit("updateNotActivatedUsers", body);
    inactiveUserActivate(user);
  };

  return (
    <motion.div {...animations} className="list__item">
      <span className="list__item-name">
        {user.first_name} {user.last_name}
      </span>
      <div
        className={"list__item-controls " + (selected === "" ? "" : "active")}
      >
        <div className="list__item-controls-options">
          <div ref={containerRef} className="container">
            <span
              className="list__item-controls-options__active-state js-btn-slide-active"
              role="presentation"
            ></span>
            <div
              ref={nonActiveRef}
              className="list__item-controls-options-option active"
              onClick={(e) => buttonClick(e, nonActiveRef)}
            >
              <span>Μη Ενεργός</span>
            </div>
            <div
              ref={studentRef}
              className="list__item-controls-options-option"
              onClick={(e) => buttonClick(e, studentRef)}
            >
              <span>Μαθητής</span>
            </div>
            {currentUser.user_type === "admin" && (
              <div
                ref={professorRef}
                className="list__item-controls-options-option"
                onClick={(e) => buttonClick(e, professorRef)}
              >
                <span>Καθηγητής</span>
              </div>
            )}
            {currentUser.user_type === "admin" && (
              <div
                ref={parentRef}
                className="list__item-controls-options-option"
                onClick={(e) => buttonClick(e, parentRef)}
              >
                <span>Γονέας</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default InactiveUserItem;
