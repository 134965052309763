import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import "./chapters-timeline.scss";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";

function ChaptersTimeline({ selectedDate, event }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [activeChapter, setActiveChapter] = useState();

  const [eventChapter, setEventChapter] = useState([]);

  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    const cleanUpGetChapters = getChapters();
    const cleanUpGetEventChapter = getEventChapter();
    return () => {
      cleanUpGetEventChapter();
      cleanUpGetChapters();
    };
  }, []);

  const getChapters = () => {
    let args = {
      class_id: event.class_id,
    };
    const getChaptersListener = (data) => {
      setChapters(data);
    };

    const refreshChaptersListener = () => {
      socketContext.socket.emit("getChapters", args);
    };

    socketContext.socket.on("chapters", getChaptersListener);
    socketContext.socket.emit("getChapters", args);
    socketContext.socket.on("refreshChapters", refreshChaptersListener);

    return () => {
      socketContext.socket.off("getChapters", getChaptersListener);
      socketContext.socket.off("chapters", getChaptersListener);
      socketContext.socket.off("refreshChapters", refreshChaptersListener);
    };
  };

  const getEventChapter = () => {
    let args = {
      event_id: event.event_id,
      date: selectedDate,
    };
    const getEventChapterListener = (data) => {
      console.log(data);
      if (data && data[0]) {
        setActiveChapter(data[0].chapter_id);
      }
    };
    const refreshEventChapterListener = () => {
      socketContext.socket.emit("getEventChapter", args);
    };
    socketContext.socket.on("eventChapter", getEventChapterListener);
    socketContext.socket.emit("getEventChapter", args);
    socketContext.socket.on(
      "refreshEventChapter" + event.event_id,
      refreshEventChapterListener
    );
    return () => {
      socketContext.socket.off("getEventChapter", getEventChapterListener);
      socketContext.socket.off("eventChapter", getEventChapterListener);
      socketContext.socket.off(
        "refreshEventChapter" + event.event_id,
        refreshEventChapterListener
      );
    };
  };

  const clickChapter = (chapterId) => {
    setActiveChapter(chapterId);
    const eventBody = {
      event_id: event.event_id,
      date: selectedDate,
      chapter_id: chapterId,
    };
    socketContext.socket.emit("updateEventChapter", eventBody);
  };

  const populateChapters = () => {
    return chapters.map((chapter, index) => {
      return (
        <div
          key={"chapterItem " + index}
          className={
            "timeline-item " +
            (activeChapter >= chapter.id ? " active" : "") +
            (activeChapter == chapter.id ? " last " : "")
          }
        >
          <div className="timeline-item__content">
            <div
              className="timeline-item__content-dot "
              onClick={() => clickChapter(chapter.id)}
            ></div>
            <span className="timeline-item__content-label">
              {chapter.chapter_name}
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="chapters-timeline">
      <span className="chapters-timeline__label">Κεφαλαίο Διδασκαλίας:</span>
      <div className="chapters-timeline__list no-scrollbar">
        {populateChapters()}
      </div>
    </div>
  );
}

export default ChaptersTimeline;
