import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useEventContext } from "../../eventContext";
import "./online-grades-section.scss";
import OnlineGradeItem from "./onlineGradeItem";
import { BiChevronDown, BiChevronUp, BiPlus, BiTrash } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

function OnlineGradesSection() {
  const { onlineGrades, setOnlineGrades } = useEventContext();
  const { eventMaxRank, setEventMaxRank } = useEventContext();

  const [totalCurrentRank, setTotalCurrentRank] = useState(0);

  const addItem = () => {
    const newItem = {
      id: Date.now(),
      saved: false,
      description: "",
      rank: 0,
    };
    setOnlineGrades([...onlineGrades, newItem]);
  };

  const removeItem = (id) => {
    const indexToRemove = onlineGrades.findIndex((item) => item.id === id);
    if (indexToRemove > -1) {
      const newOnlineGrades = [
        ...onlineGrades.slice(0, indexToRemove),
        ...onlineGrades.slice(indexToRemove + 1),
      ];
      setOnlineGrades(newOnlineGrades);
    }
  };

  const updateDescription = (id, newDescription) => {
    setOnlineGrades((currentGrades) => {
      return currentGrades.map((grade) => {
        if (grade.id === id) {
          return { ...grade, description: newDescription, saved: true };
        }
        return grade;
      });
    });
  };

  const updateRank = (id, newRank) => {
    const updatedGrades = onlineGrades.map((grade) => {
      if (grade.id === id) {
        return { ...grade, rank: newRank };
      }
      return grade;
    });
    setOnlineGrades(updatedGrades);
  };

  useEffect(() => {
    let temp = 0;
    onlineGrades.map((grade) => {
      temp += parseInt(grade.rank);
    });
    setTotalCurrentRank(temp);
  }, [onlineGrades]);

  // Change here: Use the length of onlineGrades to dynamically set the expanded item
  const lastItemKey = onlineGrades.length > 0 ? onlineGrades.length - 1 : "0";

  return (
    <div className={"online-grades-section no-scrollbar"}>
      <div className="max-rank">
        <span className="max-rank__label">Μέγιστη βαθμολογία</span>
        <div className="max-rank__wrapper">
          <div className="rank-item__input">
            <BiChevronDown
              size={"30px"}
              color={"#fff"}
              style={iconStyle("#d6d6d6")}
              onClick={() => setEventMaxRank(eventMaxRank - 1)}
            />
            <input
              type="number"
              value={eventMaxRank}
              onChange={(e) => setEventMaxRank(parseInt(e.target.value))}
            />
            <BiChevronUp
              size={"30px"}
              color={"#fff"}
              style={iconStyle("#d6d6d6")}
              onClick={() => setEventMaxRank(eventMaxRank + 1)}
            />
          </div>
          <div className="grades-section__progress">
            <div
              className="total"
              style={{
                width: `${
                  (onlineGrades.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseInt(currentValue.rank, 10),
                    0
                  ) *
                    100) /
                  eventMaxRank
                }%`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <Accordion defaultActiveKey={lastItemKey.toString()}>
        {onlineGrades.map((item, index) => (
          <Accordion.Item eventKey={`${index}`} key={item.id}>
            <Accordion.Header>
              Θέμα {index + 1}
              <div className="accordion-button__actions">
                <BiTrash
                  onClick={(e) => {
                    e.stopPropagation();
                    removeItem(item.id);
                  }}
                  size={"35px"}
                  color={"#fff"}
                  style={iconStyle("transparent")}
                />
                <BiChevronDown
                  className="chevron"
                  size={"35px"}
                  color={"white"}
                  style={iconStyle("transparent")}
                />
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <OnlineGradeItem
                key={"online grade item " + item.id}
                item={item}
                updateDescription={updateDescription}
                updateRank={updateRank}
              />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      {totalCurrentRank < eventMaxRank ? (
        <button
          className="cta cta-shadow add-thema"
          onClick={addItem}
          style={{ marginTop: "20px" }}
        >
          Προσθέστε Θέμα
          <BiPlus
            size={"35px"}
            color={"#ccc"}
            style={iconStyle("transparent")}
          />
        </button>
      ) : (
        <span className="thema-max">
          Έχετε συμπληρώσει τον μέγιστο βαθμό. Για να προσθέσετε καινούριο θέμα,
          μειώστε βαθμολογία σε κάποιο παραπάνω θέμα...
        </span>
      )}
    </div>
  );
}

export default OnlineGradesSection;
