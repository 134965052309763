import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiCalendar,
  BiHome,
  BiX,
  BiBookOpen,
  BiTime,
  BiDesktop,
  BiCalendarCheck,
  BiCalendarX,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import "./announcement-card.scss";
import StudentExamScore from "../../../StudentExamScore/studentExamScore";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AnnouncementCard({ event, closeCard, createdBy }) {
  const socketContext = useContext(SocketContext);
  const profile = useSelector((state) => state.profile.value);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(date);
  };

  const populateClassrooms = () => {
    return event.classroom_names.map((classroom, index) => {
      return <span key={"classroomName" + index}>{classroom}</span>;
    });
  };

  const startCall = () => {
    if (event.call_link) {
      if (profile.paid_lectures) {
        let joinedLectures = profile.joined_lectures;
        if (!joinedLectures) {
          joinedLectures = 1;
        } else {
          joinedLectures++;
        }
        let body = {
          user_id: profile.user_id,
          event_id: event.event_id,
          date: formatDate(new Date()),
        };
        socketContext.socket.emit("updateJoinedEvents", body);
      }

      const isRunningInWebView = () => {
        return window.ReactNativeWebView !== undefined;
      };
      let callLink = event.call_link;
      if (isRunningInWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "OPEN_LINK",
            data: callLink,
          })
        );
      } else {
        window.open(callLink, "_blank");
      }
    }
  };

  return (
    <div className="announcement-card">
      <div className="announcement-card__close" onClick={() => closeCard()}>
        <BiX
          size={"30px"}
          color={"#cccccc"}
          // color={"#fff"}
          style={iconStyle("transparent")}
        />
      </div>
      <div
        className={
          "info " +
          (event.type == "lecture" && !event.call_link ? " no-shadow" : "")
        }
      >
        <span className="info__created-by">
          {createdBy && (
            <img
              alt="created_by student professor"
              className="creator-img"
              src={
                createdBy.profile_picture
                  ? createdBy.profile_picture
                  : "resources/student.png"
              }
            />
          )}
          {createdBy ? (
            <span className="creator-name">
              <span>{createdBy.first_name}</span>
              <span>{createdBy.last_name}</span>
            </span>
          ) : (
            ""
          )}
        </span>
        <div className="info__wrapper">
          <div className="info__wrapper-details">
            <div className="item">
              <BiCalendarCheck
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {formatDate(event.start_at)} στις {formatTime(event.start_at)}
                </span>
              </div>
            </div>
            <div className="item">
              <BiCalendarX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {formatDate(event.finish_at)} στις{" "}
                  {formatTime(event.finish_at)}
                </span>
              </div>
            </div>
            {event.class_name ? (
              <div className="item">
                <BiBookOpen
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">
                  <span className="label">{event.class_name}</span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="details">
        <CKEditor
          editor={ClassicEditor}
          data={event.description}
          config={{
            toolbar: [],
          }}
          disabled={true}
        />
        {event.type == "lecture" && event.call_link ? (
          <button
            className="cta start-online mobile"
            onClick={() => startCall()}
          >
            <BiDesktop
              size={"25px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
            <span>Εκκίνηση ηλεκτρονικού μαθήματος</span>
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AnnouncementCard;
