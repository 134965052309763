import React from "react";
import { motion, usePresence } from "framer-motion";
import { BiPlus, BiMinus } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function DismissalUserItem({ type, user, updateDismissals }) {
  const [isPresent, safeToRemove] = usePresence();
  const transition = {
    type: "spring",
    stiffness: 500,
    damping: 70,
    mass: 5,
    duration: 2,
  };

  const animations = {
    layout: true,
    initial: "in",
    style: {
      position: isPresent ? "relative" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0, zIndex: -1 },
      tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      {...animations}
      className="user"
      onClick={
        type === "add"
          ? () => updateDismissals(user, 1)
          : () => updateDismissals(user, 0)
      }
    >
      <div className="user__info">
        <div className="user__info-img">
          <img
            alt="profile edupal"
            src={
              user.profile_picture
                ? user.profile_picture
                : "resources/student.png"
            }
          />
        </div>
        <div className="user__info-title">
          <span>{user.first_name}</span>
          <span>{user.last_name}</span>
        </div>
      </div>
      <div className="user__icon">
        {type === "add" && (
          <BiPlus size={"30px"} color={"#fff"} style={iconStyle("#91FE8F")} />
        )}
        {type !== "add" && (
          <BiMinus size={"30px"} color={"#fff"} style={iconStyle("#FF6D6D")} />
        )}
      </div>
    </motion.div>
  );
}

export default DismissalUserItem;
