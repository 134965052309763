import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import { motion } from "framer-motion";
import "./events-item.scss";
import ProfessorEventCard from "../../EventCard/exam/professor/professorEventCard";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { useHomepageContext } from "../../../pages/Homepage/homepageContext";
import StudentEventCard from "../../EventCard/exam/student/studentEventCard";
import ParentEventCard from "../../EventCard/exam/parent/parentEventCard";
import DismissalUserItem from "../dismissalUserItem";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AnnouncementCard from "../../EventCard/exam/announcement/announcementCard";

const getDuration = (date1, date2) => {
  const diff = Math.abs(date2 - date1);

  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 60) {
    return `${minutes} λεπ`;
  } else if (hours < 24) {
    const remainingMinutes = minutes % 60;
    return `${hours} ${hours > 1 ? "ώρες" : "ώρα"} ${
      remainingMinutes > 0 ? remainingMinutes : ""
    } ${
      remainingMinutes > 0 ? (remainingMinutes == 1 ? "λεπτό" : "λεπτά") : ""
    } `;
  } else {
    return `${days} ${days > 1 ? "μέρες" : "μέρα"}`;
  }
};

const formatDate = (dateString, includeYear) => {
  const date = new Date(dateString);
  let formatDate = "";
  if (includeYear) {
    formatDate = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
      month: "short",
    });
  } else {
    formatDate = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "short",
      // year: "numeric",
    });
  }
  return formatDate.format(date);
};

const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat("el-GR", options);

  return formatter.format(date);
};

function EventsItem({
  event,
  setEditIsOpen,
  setEventToEdit,
  selectedDate,
  dismissalInitActive,
  isForProfile,
}) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const { setModalOpen } = useHomepageContext();

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(80);
  const [modalLeft, setModalLeft] = useState(0.1);
  const [modalTop, setModalTop] = useState(0.075);

  const [createdBy, setCreatedBy] = useState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "hidden";
      }
    } else {
      if (window.innerWidth > 700) {
        document.body.style.overflowY = "auto";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const openEdit = () => {
    setEventToEdit(event);
    setIsExpanded(false);
    setTimeout(() => {
      setEditIsOpen(true);
    }, [400]);
  };

  useEffect(() => {
    if (event.type == "announcement") {
      return getCreatedBy();
    }
  }, [event]);

  useEffect(() => {
    if (window.innerWidth < 800) {
      if (profile.user_type == "student" && event.type == "lecture") {
        setModalHeight(60);
        setModalTop(0.2);
        setModalWidth(90);
        setModalLeft(0.03);
      } else {
        setModalHeight(93);
        setModalWidth(90);
        setModalTop(0.05);
        setModalLeft(0.03);
      }
    } else {
      if (event.type == "announcement") {
        setModalHeight(60);
        setModalWidth(70);
        setModalTop(0.2);
        setModalLeft(0.14);
      } else if (
        profile.user_type == "admin" ||
        profile.user_type == "professor"
      ) {
        if (event.type == "lecture") {
          setModalHeight(85);
          setModalWidth(70);
          setModalTop(0.075);
          setModalLeft(0.14);
        } else if (event.type == "online-exam") {
        } else {
          setModalHeight(85);
          setModalWidth(80);
          setModalTop(0.075);
          setModalLeft(0.1);
        }
      } else if (
        profile.user_type == "student" ||
        profile.user_type == "parent"
      ) {
        if (event.type == "lecture") {
          setModalHeight();
          setModalWidth(60);
          setModalTop(0.3);
          setModalLeft(0.2);
        } else if (!event.score) {
          setModalHeight(40);
          setModalWidth(80);
          setModalTop(0.2);
          setModalLeft(0.1);
        } else if (event.type == "online-exam") {
        } else {
          setModalHeight(85);
          setModalWidth(80);
          setModalTop(0.075);
          setModalLeft(0.1);
        }
      }
    }
  }, [event]);

  const openCard = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;
      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
      };
      if (modalHeight) {
        newPositionStyle.height = modalHeight + "vh";
      } else {
        newPositionStyle.height = "auto";
      }
      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  const closeCard = () => {
    setIsExpanded(false);
  };

  const deleteExam = () => {
    if (event.type == "lecture") {
      let body = { event_id: event.event_id };
      socketContext.socket.emit("deleteEvent", body);
    } else if (event.type == "announcement") {
      let body = { announcement_id: event.announcement_id };
      socketContext.socket.emit("deleteAnnouncement", body);
    } else {
      socketContext.socket.emit("deleteExam", event.exam_id);
    }
    setShowDeleteModal(false);
    setIsExpanded(false);
  };

  const getCreatedBy = () => {
    const userId = event.created_by;

    const getUsersListener = (data) => {
      if (data.length > 0) {
        setCreatedBy(data[0]);
      } else {
        setCreatedBy([]);
      }
    };

    socketContext.socket.on("allUsersWithParams" + userId, getUsersListener);

    const args = { user_id: userId };
    socketContext.socket.emit("getAllUsersWithParams", args);

    const refreshAllUsersWithParamsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshAllUsersWithParamsListener
    );

    return () => {
      socketContext.socket.off("allUsersWithParams" + userId, getUsersListener);
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshAllUsersWithParamsListener
      );
    };
  };

  const getIsExpired = () => {
    if (event.type != "announcement" && event.start_at && event.finish_at) {
      if (event.repeat_type == "weekly") {
        const eventFinishDate = new Date(event.finish_at);
        const updatedDate = selectedDate;
        updatedDate.setHours(
          eventFinishDate.getHours(),
          eventFinishDate.getMinutes(),
          eventFinishDate.getSeconds()
        );
        if (new Date() > updatedDate) {
          return " expired";
        } else {
          return "";
        }
      } else {
        if (new Date() > new Date(event.finish_at)) {
          return " expired";
        } else {
          return "";
        }
      }
    }
  };

  const needProgress = () => {
    if (event.type != "announcement") {
      let start = new Date(event.start_at);
      let end = new Date(event.finish_at);

      if (
        event.repeat_type == "weekly" &&
        new Date().getDate() == new Date(selectedDate).getDate() &&
        new Date().getMonth() == new Date(selectedDate).getMonth() &&
        new Date().getFullYear() == new Date(selectedDate).getFullYear()
      ) {
        let updatedDate = new Date();
        updatedDate.setHours(
          end.getHours(),
          end.getMinutes(),
          end.getSeconds()
        );
        end = updatedDate;

        updatedDate = new Date();
        updatedDate.setHours(
          start.getHours(),
          start.getMinutes(),
          start.getSeconds()
        );
        start = updatedDate;
        if (start <= new Date() && end >= new Date()) {
          return true;
        } else {
          return false;
        }
      } else {
        if (start <= new Date() && end >= new Date()) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const [eventProgress, setEventProgress] = useState(0);

  useEffect(() => {
    getEventProgress();
    setTimeout(() => {
      getEventProgress();
    }, 5000);
  }, []);

  const getEventProgress = () => {
    let start = new Date(event.start_at);
    let end = new Date(event.finish_at);
    if (event.repeat_type == "weekly") {
      let updatedDate = new Date();
      updatedDate.setHours(end.getHours(), end.getMinutes(), end.getSeconds());
      end = updatedDate;

      updatedDate = new Date();
      updatedDate.setHours(
        start.getHours(),
        start.getMinutes(),
        start.getSeconds()
      );
      start = updatedDate;
    }
    start = start.getTime();
    end = end.getTime();
    const now = new Date().getTime();

    if (now < start) {
      setEventProgress(0);
      return 0;
    }
    if (now > end) {
      setEventProgress(100);
      return 100;
    }

    const totalDuration = end - start;
    const elapsed = now - start;
    const progress = (elapsed / totalDuration) * 100;

    const totalProg = Math.round(progress);
    if (totalProg < 1) {
      setEventProgress(100);
      return 100;
    } else {
      if (100 - totalProg <= 2) {
        setEventProgress(3);
      } else {
        setEventProgress(100 - totalProg);
      }
      return 100 - totalProg;
    }
  };

  const populateClassroomNames = () => {
    return event.classroom_names.map((classroom, index) => {
      const classroomFull =
        classroom + (index < event.classroom_names.length - 1 ? ", " : " ");
      return classroomFull;
    });
  };

  const populateDepartmentNames = () => {
    if (event.department_names) {
      return event.department_names.map((depName, index) => {
        const depNameFull =
          depName + (index < event.department_names.length - 1 ? ", " : " ");
        return depNameFull;
      });
    }
  };

  const populateProfessorNames = () => {
    if (event.professor_names) {
      return event.professor_names.map((profName, index) => {
        let nameSplit = "";
        let parts = profName.split(" ");
        if (parts.length === 2) {
          // Making sure there are exactly two parts
          nameSplit = `${parts[0]} ${parts[1].charAt(0)}.`;
        }
        const profNameFull =
          nameSplit + (index < event.professor_names.length - 1 ? ", " : " ");
        return profNameFull;
      });
    }
  };

  const startCall = () => {
    if (!event.call_link) {
      const hash = window.crypto.randomUUID();
      const meeting_url = "https://meet.jit.si/" + hash;
      let eventBody = {
        meeting_url: meeting_url,
        event_id: event.event_id,
      };

      socketContext.socket.emit("addEventMeeting", eventBody);
    } else {
      const isRunningInWebView = () => {
        return window.ReactNativeWebView !== undefined;
      };
      let callLink = event.call_link;
      if (isRunningInWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "OPEN_LINK",
            data: callLink,
          })
        );
      } else {
        window.open(callLink, "_blank");
      }
    }
  };

  const populateAnDepartmentNames = () => {
    try {
      return JSON.parse(event.department_names).map((depName, index) => {
        const depNameFull =
          depName +
          " " +
          (index != JSON.parse(event.department_names).length - 1 ? "," : "");
        return depNameFull;

        // {depName}
        // {index != JSON.parse(event.department_names).length - 1
        //   ? ","
        //   : " - "}
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      ref={containerRef}
      className={
        "event-item " +
        (isExpanded ? " is-expanded " : " is-collapsed  ") +
        event.type +
        " " +
        getIsExpired()
        // (new Date() > new Date(event.start_at) &&
        // new Date() <= new Date(event.finish_at)
        //   ? " current "
        //   : "")
      }
    >
      <span>{event.type}</span>
      {!isExpanded && needProgress() ? (
        <div
          className="event-item__progress"
          style={{ width: eventProgress + "%" }}
        >
          {/* {getEventProgress()} */}
        </div>
      ) : (
        " "
      )}
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        // layout
        initial={false} // You can adjust this as needed
        className={
          "event-item-content " + (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style} // Apply dynamic style here
      >
        <div
          className={"event-item__delete " + (showDeleteModal ? "show" : "")}
        >
          <div className="wrapper">
            <span className="wrapper__label ">
              Είστε σίγουροι ότι θέλετε να διαγράψατε{" "}
              {event.type == "lecture" ? "το μάθημα" : ""}{" "}
              {event.type == "announcement" ? "την ανακοίνωση" : ""};
            </span>
            <div className="wrapper__actions">
              <button onClick={() => setShowDeleteModal(false)} className="cta">
                Ακύρωση
              </button>
              <button onClick={() => deleteExam()} className="cta cta-red">
                Διαγραφή
              </button>
            </div>
          </div>
        </div>
        {isExpanded && event.type == "announcement" ? (
          <AnnouncementCard
            event={event}
            closeCard={closeCard}
            createdBy={createdBy}
          />
        ) : isExpanded &&
          (profile.user_type == "admin" || profile.user_type == "professor") ? (
          <ProfessorEventCard
            event={event}
            closeCard={closeCard}
            selectedDate={selectedDate}
            dismissalInitActive={dismissalInitActive}
          />
        ) : isExpanded && profile.user_type == "student" ? (
          <StudentEventCard event={event} closeCard={closeCard} />
        ) : isExpanded && profile.user_type == "parent" ? (
          <ParentEventCard event={event} closeCard={closeCard} />
        ) : (
          <div
            className={
              "event-item__preview " +
              (event.type == "announcement" ? "announcement" : "")
            }
          >
            <div className="time-preview">
              {createdBy && (
                <img
                  alt="created_by student professor"
                  className="time-preview__creator"
                  src={
                    createdBy.profile_picture
                      ? createdBy.profile_picture
                      : "resources/student.png"
                  }
                />
              )}
              <div className="time-preview__container">
                {event.type != "online-exam" && event.type != "announcement" ? (
                  <div className="time">
                    <span>{formatDateTime(event.start_at)}</span>
                    <span className="separator">-</span>
                    <span>{formatDateTime(event.finish_at)}</span>
                  </div>
                ) : (
                  <div className="time">
                    <span>{formatDate(event.start_at)}</span>
                    <span className="separator">-</span>
                    <span>{formatDate(event.finish_at)}</span>
                  </div>
                )}
                {event.type != "announcement" ? (
                  <div className="duration">
                    <span>
                      {event.type != "online-exam"
                        ? getDuration(
                            new Date(event.start_at),
                            new Date(event.finish_at)
                          )
                        : event.duration + " λεπτά"}
                    </span>
                  </div>
                ) : (
                  <div className="event-creator">
                    {createdBy ? (
                      <div className="created_by__info">
                        <div className="name">
                          <span>
                            {createdBy.first_name} {createdBy.last_name}
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="info">
              {event.type != "announcement" ? (
                <div className="info__title">
                  <span>{event.title}</span>
                  <span>{event.grade_name}</span>
                </div>
              ) : (
                <div className="info__title">
                  <CKEditor
                    editor={ClassicEditor}
                    data={event.description}
                    config={{
                      toolbar: [],
                    }}
                    disabled={true}
                  />
                </div>
              )}
              <div className="info__details">
                {event.type == "online-exam" ? (
                  <div className="info__details-item">
                    <span className="item-title">
                      <span className="label">Έυρος: </span>
                      {getDuration(
                        new Date(event.start_at),
                        new Date(event.finish_at)
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {event.type != "online-exam" &&
                event.type != "parent-event" &&
                event.classroom_names ? (
                  <div className="info__details-item">
                    <span className="item-title">
                      <span className="label">
                        {event.classroom_names.length > 1
                          ? "Αίθουσες: "
                          : "Αίθουσα: "}{" "}
                      </span>
                      {populateClassroomNames()}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {event.type == "announcement" ? (
                  <div className="info__details-item">
                    <span className="item-title">
                      <span className="label">
                        {event.class_name ? "Μάθημα: " : "Μαθήματα: "}{" "}
                      </span>
                      {event.class_name ? event.class_name : "Όλα"}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {event.type != "announcement" ? (
                  <div className="info__details-item">
                    <span className="item-title">
                      <span className="label">
                        {event.department_names &&
                        (event.department_names.length > 1 ||
                          event.department_id == "[-1]")
                          ? "Τμήματα: "
                          : "Τμήμα: "}{" "}
                      </span>
                      {populateDepartmentNames()}
                    </span>
                  </div>
                ) : (
                  <div className="info__details-item">
                    <span className="item-title">
                      <span className="label">
                        {!event.department_names ||
                        event.department_names.length > 1 ||
                        !event.department_names ||
                        event.department_id == "[-1]"
                          ? "Τμήματα: "
                          : "Τμήμα: "}{" "}
                      </span>
                      {event.department_names
                        ? populateAnDepartmentNames()
                        : "Όλα"}
                    </span>
                  </div>
                )}
                {event.type == "announcement" ? (
                  <div className="info__details-item">
                    <span className="item-title">
                      Προς:
                      {event.for_professors ? " Καθηγητές, " : ""}
                      {event.for_students ? " Μαθητές, " : ""}
                      {event.for_parents ? " Γονείς, " : ""}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {event.type != "announcement" ? (
                  <div className="info__details-item">
                    <span className="item-title">
                      <span className="label">
                        {event.professor_names &&
                        event.professor_names.length > 1
                          ? "Καθηγητές: "
                          : "Καθηγητής: "}
                      </span>
                      {populateProfessorNames()}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
        {isExpanded &&
        (profile.user_type == "admin" || profile.user_type == "professor") ? (
          <div className="event-item__actions">
            <button
              onClick={() => setShowDeleteModal(true)}
              className="cta cta-red cta-no-border"
            >
              Διαγραφή
            </button>
            <div className="button-container">
              {!isForProfile ? (
                <button className="cta" onClick={() => openEdit()}>
                  Επεξεργασία
                </button>
              ) : (
                ""
              )}
              {event.type == "lecture" ? (
                <button
                  className="cta start-online desk"
                  onClick={() => startCall()}
                >
                  <BiDesktop
                    size={"25px"}
                    color={"#6225e6"}
                    style={iconStyle("transparent")}
                  />
                  <span>
                    {!event.call_link || event.call_link == null
                      ? "Μετατροπή σε ηλεκτρονικό μάθημα"
                      : "Εκκίνηση ηλεκτρονικού μαθήματος"}
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </motion.div>
    </div>
  );
}

export default EventsItem;
