import React from "react";
import { IoIosClose } from "react-icons/io";

function ExamThumbnail({ index, image, removeThumbnail, zoomImage }) {
  return (
    <div className={"position-relative exam-thumbnail-item"}>
      <IoIosClose
        className="exam__close-image-icon"
        color={"black"}
        size={32}
        onClick={() => removeThumbnail(index)}
      />
      <img src={image} alt="thumbnail edupal" onClick={zoomImage} />
    </div>
  );
}

export default ExamThumbnail;
