import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const LiveWorksheetEmbed = ({ embedHtml }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Create a container div for embedding
    const embedContainer = document.getElementById("embed-container");

    // Inject the embed HTML into the div
    embedContainer.innerHTML = embedHtml;

    // Function to handle the load event of scripts and iframes
    const handleLoad = () => {
      // Check if all iframes inside the embed container have loaded
      const iframes = embedContainer.getElementsByTagName("iframe");
      let iframesLoaded = true;
      for (let i = 0; i < iframes.length; i++) {
        if (!iframes[i].contentWindow) {
          iframesLoaded = false;
          break;
        }
      }

      // If all iframes are loaded, hide the loader
      if (iframesLoaded) {
        setLoading(false);
      }
    };

    // Attach load event listeners to iframes and scripts within the embedHtml
    const scripts = embedContainer.getElementsByTagName("script");
    const iframes = embedContainer.getElementsByTagName("iframe");

    for (let script of scripts) {
      script.onload = handleLoad;
    }

    for (let iframe of iframes) {
      iframe.onload = handleLoad;
    }

    // Extract the inline script from embedHtml
    const inlineScriptMatch = embedHtml.match(/<script>([\s\S]*?)<\/script>/);
    const inlineScriptContent = inlineScriptMatch ? inlineScriptMatch[1] : null;

    // Create and append the external script tag
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://www.liveworksheets.com/embed/embed.js?v=1";
    scriptTag.async = true;
    scriptTag.onload = () => {
      // Execute the inline script after the external script is loaded
      if (inlineScriptContent) {
        const inlineScript = document.createElement("script");
        inlineScript.textContent = inlineScriptContent;
        document.body.appendChild(inlineScript);
      }
      // Trigger the load handler once the external script is loaded
      handleLoad();
    };
    document.body.appendChild(scriptTag);

    // Cleanup script tags on component unmount
    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [embedHtml]);

  return (
    <div>
      <Helmet>
        <script
          src="https://www.liveworksheets.com/embed/embed.js?v=1"
          async
        ></script>
      </Helmet>
      {loading && <div className="loader">Loading...</div>}
      <div id="embed-container" style={{ width: "100%" }}></div>
    </div>
  );
};

export default LiveWorksheetEmbed;
