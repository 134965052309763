import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiPlus, BiUpload, BiX } from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import "./create-user.scss";
import { useSelector } from "react-redux";
import Compress from "compress.js";

function CreateUser({
  professors,
  students,
  parents,
  isExpanded,
  setIsExpanded,
  isOnlyParent,
  studentId,
}) {
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);
  const [photoFile, setPhotoFile] = useState();
  const [photoName, setPhotoName] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const hasCameraAccess = useSelector((state) => state.profile.hasCameraAccess);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const cleanUpGetAllUsers = getAllUsers();
    return () => {
      cleanUpGetAllUsers();
    };
  }, []);

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.1 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "80vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
      setErrorMsg("");
      setFirstName("");
      setLastName("");
      setPwd("");
      setRepeatPwd("");
      setEmail("");
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const [allUsers, setAllUsers] = useState([]);

  const getAllUsers = () => {
    const uniqueId = "get-all-users";
    let args = {};

    const getProfessorsListener = (data) => {
      setAllUsers(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshProfessorsListener
      );
    };
  };

  const [userType, setUserType] = useState("student");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [repeatPwd, setRepeatPwd] = useState("");
  const [personalPhone, setPersonalPhone] = useState("");
  const [parentPhone, setParentPhone] = useState("");

  const saveUser = () => {
    if (!lastName) {
      setErrorMsg("last-name");
    } else if (!firstName) {
      setErrorMsg("first-name");
    } else if (
      allUsers.find((usr) => usr.email == email)
      // professors.find((usr) => usr.email == email) ||
      // students.find((usr) => usr.email == email) ||
      // parents.find((usr) => usr.email == email)
    ) {
      setErrorMsg("email");
    } else if (pwd != repeatPwd) {
      setErrorMsg("repeat-pwd");
    } else {
      let body = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        user_type: isOnlyParent ? "parent" : userType,
        pwd: pwd,
        student_id: studentId,
      };
      socketContext.socket.emit("createUser", body);
      setIsExpanded(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className={"create-user " + (isExpanded ? "is-expanded" : "is-collapsed")}
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "create-user__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="create-user__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            {!isOnlyParent ? (
              <div className="user-item">
                <div className="user-item__type">
                  <div
                    className={
                      "type-item " + (userType == "admin" ? " active" : "")
                    }
                    onClick={() => setUserType("admin")}
                  >
                    Ιδιοκτήτης
                  </div>
                  <div
                    className={
                      "type-item " + (userType == "professor" ? " active" : "")
                    }
                    onClick={() => setUserType("professor")}
                  >
                    Καθηγητής
                  </div>
                  <div
                    className={
                      "type-item " + (userType == "student" ? " active" : "")
                    }
                    onClick={() => setUserType("student")}
                  >
                    Μαθητής
                  </div>
                  <div
                    className={
                      "type-item " + (userType == "parent" ? " active" : "")
                    }
                    onClick={() => setUserType("parent")}
                  >
                    Γονέας
                  </div>
                </div>
              </div>
            ) : (
              <span className="user-item__title">Προσθήκη Γονέα</span>
            )}
            <div className="user-item">
              <span className="user-item__label">Επώνυμο</span>
              {errorMsg == "last-name" ? (
                <span className="user-item__error">
                  Παρακαλώ εισάγετε επώνυμο...
                </span>
              ) : (
                ""
              )}
              <input
                placeholder="Εισάγεται το επώνυμο σας"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="input"
              />
            </div>
            <div className="user-item">
              <span className="user-item__label">Όνομα</span>
              {errorMsg == "first-name" ? (
                <span className="user-item__error">
                  Παρακαλώ εισάγετε όνομα...
                </span>
              ) : (
                ""
              )}
              <input
                placeholder="Εισάγεται το όνομα σας"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="input"
              />
            </div>
            <div className="user-item">
              <span className="user-item__label">Email</span>
              {errorMsg == "mail" ? (
                <span className="user-item__error">
                  Το email υπάρχει ήδη σε κάποιον άλλον χρήστη...
                </span>
              ) : (
                ""
              )}
              <input
                placeholder="Εισάγεται το email σας"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input"
              />
            </div>
            <div className="user-item">
              <span className="user-item__label">Κωδικός</span>
              <input
                type="password"
                placeholder="Εισάγεται το email σας"
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                className="input"
              />
            </div>
            <div className="user-item">
              <span className="user-item__label">Επαναλάβετε τον κωδικό</span>
              {errorMsg == "repeat-pwd" ? (
                <span className="user-item__error">
                  Οι 2 κωδικοί δεν είναι ίδιοι...
                </span>
              ) : (
                ""
              )}
              <input
                type="password"
                placeholder="Εισάγεται το email σας"
                value={repeatPwd}
                onChange={(e) => setRepeatPwd(e.target.value)}
                className="input"
              />
            </div>
            <div className="create-user__config-actions">
              <button className="cta" onClick={() => saveUser()}>
                Δημιουργία
              </button>
            </div>
            <div
              className="create-user__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="create-user__preview">
            <BiPlus
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default CreateUser;
