import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/navbar";
import { Outlet } from "react-router";
import { SocketProvider } from "../../app/socket";
import { calls } from "../../config/db_config";
import { tokenUtils } from "../../utils/token-utils";
import { setProfileData } from "../../components/Navbar/navbarSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ChatProvider } from "./chatContext";

const WithNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const studentExcludePaths = ["/exams-admin", "/exams-submitted", "/users"];
  const professorExcludePaths = [""];

  useEffect(() => {
    try {
      fetch(calls.getUserData, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: tokenUtils.getBearerToken(),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            let profileData = data.data;
            dispatch(setProfileData(profileData));

            const pathname = window.location.pathname;

            if (profileData.user_type === "student") {
              if (studentExcludePaths.includes(pathname)) {
                navigate("/");
              }
            } else if (
              profileData.user_type === "professor" ||
              profileData.user_type === "admin"
            ) {
              if (professorExcludePaths.includes(pathname)) {
                navigate("/");
              }
            }

            if (profileData.is_archived == 1 || profileData.is_archived == 2) {
              navigate("/login");
            }
          } else {
            navigate("/login");
          }
        });
    } catch (e) {
      navigate("/login");
    }
  });

  return (
    <>
      <SocketProvider>
        <ChatProvider>
          <Navbar />
        </ChatProvider>
        <Outlet />
      </SocketProvider>
    </>
  );
};

export default WithNav;
