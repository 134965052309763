import React, { useEffect, useState, useContext } from "react";
import "./chat-edit.scss";
import { BiX } from "react-icons/bi";
import { SocketContext } from "../../app/socket";
// import ChatItem from "./chatItem";
import { tokenUtils } from "../../utils/token-utils";
import axios from "axios";
import Compress from "compress.js";
import { calls } from "../../config/db_config";
import { iconStyle } from "../../utils/generalUtils";
import { useSelector } from "react-redux";

function ChatEdit({ chat, chatImage, setIsOpen }) {
  const socketContext = useContext(SocketContext);
  const envVars = useSelector((state) => state.profile.environmentVars);

  const [participantsData, setParticipantsData] = useState([]);

  useEffect(() => {
    if (chat && chat.participants) {
      const userIds = JSON.parse(chat.participants);
      const userPromises = userIds.map((userId) => getUserDataPromise(userId));

      Promise.all(userPromises)
        .then((users) => {
          // Once all promises resolve, update the state with all user data
          setParticipantsData(users.filter((user) => user !== null)); // Filter out nulls if any promise did not resolve to valid data
        })
        .catch((error) => console.error("Failed to fetch user data:", error));
    }
  }, [chat]);

  const getUserDataPromise = (userId) => {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        // If no response within a timeout, resolve with null or reject
        resolve(null);
        // Or reject(new Error('Timeout'));
      }, 5000); // Set timeout as appropriate

      socketContext.socket.once("allUsersWithParams" + userId, (data) => {
        clearTimeout(timeout);
        if (data && data[0]) {
          resolve(data[0]);
        } else {
          resolve(null); // Resolve with null to avoid blocking the Promise.all due to one failure
        }
      });

      const args = { user_id: userId };
      socketContext.socket.emit("getAllUsersWithParams", args);
    });
  };

  const [imageSrc, setImageSrc] = useState(null); // For displaying the image
  const [file, setFile] = useState(null); // To save the file for later use

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Use FileReader to read the file
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result); // Update the image's src for display
        setFile(selectedFile); // Save the file for sending to the backend later
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleClick = () => {
    // Programmatically click the hidden file input
    fileInputRef.current.click();
  };

  // Create a ref for the file input
  const fileInputRef = React.useRef();

  const [chatName, setChatName] = useState(
    chat.chat_name ? chat.chat_name : "Ομαδική συνομιλία"
  );

  const cancelChanges = () => {
    setChatName(chat.chat_name ? chat.chat_name : "Ομαδική συνομιλία");
    setFile(null);
    setImageSrc(null);
    setIsOpen(false);
  };

  const saveChanges = async () => {
    console.log("saving changes");
    let fileName = "";
    if (file) {
      const timestamp = new Date().getTime();
      const extension = file.name.split(".").pop();
      fileName = `chat-image-${timestamp}.${extension}`;
    }
    if (fileName) {
      fileName = `/resources/${envVars.DB_SCHOOL_NAME}/chat-images/` + fileName;
    }
    const body = {
      chat_id: chat.chat_id,
      chat_picture: fileName ? fileName : "",
      chat_name: chatName ? chatName : "",
      participants: chat.participants,
    };
    socketContext.socket.emit("updateChatInfo", body);
    setIsOpen(false);
    if (file) {
      // Generate a unique fileName using the current timestamp
      const config = {
        headers: { Authorization: tokenUtils.getBearerToken() },
      };

      const compress = new Compress();

      const options = {
        size: 4, // the max size in MB, for no limit, put false
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 200, // the max width of the output image, defaults to 1920px
        maxHeight: 200, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      };

      try {
        console.log(" trying  to  compress image ");
        // Compress and resize the image
        const compressedFiles = await compress.compress([file], options);

        // compressedFiles is an array of compressed images. Most of the time you'll only need the first one
        const imgData = compressedFiles[0];
        // Create a blob from the compressed image data
        const imgBlob = Compress.convertBase64ToFile(imgData.data, imgData.ext);

        const formData = new FormData();
        formData.append("file", imgBlob, fileName); // Use the generated fileName
        formData.append("chatId", chat.chat_id);

        const res = await axios.post(calls.saveChatPicture, formData, config);
        if (res.data && res.data.path) {
          console.log("Success");
          // setShowModal(false);
        } else {
          console.log("failed to save image");
        }
      } catch (ex) {
        console.log(ex);
      }
    }
    setIsOpen(false);
  };

  const populateParticipants = () => {
    return participantsData.map((usr, index) => {
      return (
        <div key={"participantItem " + index} className="item">
          <div className="info"></div>
        </div>
      );
    });
  };

  return (
    <div className="chat-edit">
      <div className="chat-edit__close" onClick={() => setIsOpen(false)}>
        <BiX size={"32px"} color={"#ccc"} style={iconStyle("transparent")} />
      </div>
      <div className="chat-edit__actions">
        <button className="cta cta-red" onClick={() => cancelChanges()}>
          Ακύρωση
        </button>
        <button className="cta" onClick={saveChanges}>
          Αποθήκευση
        </button>
      </div>
      <div className="chat-edit__header">
        <span className="title">Επεξεργασία συνομιλίας</span>
      </div>
      <div className="chat-edit__general">
        <div className="info">
          <span className="sub-title">Γενικά</span>
          <div className="pic">
            <img
              src={imageSrc ? imageSrc : chatImage}
              alt="profile"
              onClick={handleClick}
            />
            <input
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }} // Hide the file input
              ref={fileInputRef}
            />
          </div>
          <div className="name">
            <input
              className="input"
              value={chatName}
              onChange={(e) => setChatName(e.target.value)}
            />
          </div>
        </div>
        <div className="participants">
          <span className="sub-title">Συμμετέχοντες</span>
        </div>
      </div>
    </div>
  );
}

export default ChatEdit;
