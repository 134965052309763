import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { BiCalendar, BiHome, BiX, BiBookOpen, BiTime } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import "./parent-event-card.scss";
import StudentExamScore from "../../../StudentExamScore/studentExamScore";
import { useSelector } from "react-redux";
import ParentEvent from "../../ParentEvent/parentEvent";

function ParentEventCard({ event, closeCard }) {
  const socketContext = useContext(SocketContext);

  const children = useSelector((state) => state.profile.children);

  console.log(event);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formatter.format(date);
  };

  const populateClassrooms = () => {
    return event.classroom_names.map((classroom, index) => {
      return <span key={"classroomName" + index}>{classroom}</span>;
    });
  };

  return (
    <div className="parent-event-card">
      <div className="parent-event-card__close" onClick={() => closeCard()}>
        <BiX
          size={"30px"}
          color={"#cccccc"}
          // color={"#fff"}
          style={iconStyle("transparent")}
        />
      </div>
      <div className="info">
        <span className="info__title">
          <span className="label">{event.title}</span>
        </span>
        <div className="info__wrapper">
          <div className="info__wrapper-details">
            <div className="item">
              <BiCalendar
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="date">
                  {formatDate(event.start_at)}
                  {event.type == "online-exam"
                    ? " - " + formatDate(event.finish_at)
                    : ""}
                </span>
              </div>
            </div>
            <div className="item">
              <BiTime
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                <span className="time">
                  {formatTime(event.start_at)} - {formatTime(event.finish_at)}
                </span>
              </div>
            </div>
            <div className="item">
              <BiBookOpen
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
              <div className="wrapper">
                {/* <span className="sublabel">Τύπος εξέτασης: </span> */}
                <span className="label">
                  {event.type == "exam" ? "Διαγώνισμα" : ""}
                  {event.type == "online-exam" ? "Ηλεκτρονικό Διαγώνισμα" : ""}
                  {event.type == "test" ? "Τεστ" : ""}
                </span>
              </div>
            </div>
            {event.type == "exam" ||
            (event.type == "test" && event.classroom_names) ? (
              <div className="item">
                <BiHome
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <div className="wrapper">{populateClassrooms()}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="details">
        {event.type == "exam" ||
        event.type == "test" ||
        event.type == "online-exam" ? (
          <StudentExamScore event={event} />
        ) : (
          ""
        )}
        {event.type == "parent-event" ? <ParentEvent event={event} /> : ""}
      </div>
    </div>
  );
}

export default ParentEventCard;
