import "./tuition-section.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { Bar, Line, Pie } from "react-chartjs-2";
import "chart.js/auto";
import {
  BiCategoryAlt,
  BiMoney,
  BiUser,
  BiUserPin,
  BiUserVoice,
  BiWallet,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const getStartOfWeek = (week) => {
  const current = new Date();
  const firstDayOfYear = new Date(current.getFullYear(), 0, 1);
  const days = (week - 1) * 7;

  const startOfWeek = new Date(
    firstDayOfYear.setDate(firstDayOfYear.getDate() + days)
  );

  const dayOfWeek = startOfWeek.getDay();
  const startOffset = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  startOfWeek.setDate(startOfWeek.getDate() - startOffset);

  return startOfWeek;
};

const getEndOfWeek = (week) => {
  const startOfWeek = getStartOfWeek(week);
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  return endOfWeek;
};

const getMonthName = (monthIndex) => {
  const months = [
    "Ιανουαρίου",
    "Φεβρουαρίου",
    "Μαρτίου",
    "Απριλίου",
    "Μαΐου",
    "Ιουνίου",
    "Ιουλίου",
    "Αυγούστου",
    "Σεπτεμβρίου",
    "Οκτωβρίου",
    "Νοεμβρίου",
    "Δεκεμβρίου",
  ];
  return months[monthIndex];
};

function TuitionSection() {
  const socketContext = useContext(SocketContext);
  const sliderRef = useRef(null);

  const [tuitionIsMonthly, setTuitionIsMonthly] = useState(false);
  const [monthlyTuition, setMonthlyTuition] = useState([]);
  const [totalTuition, setTotalTuition] = useState(0);
  const [totalTuitionEarned, setTotalTuitionEarned] = useState(0);
  const [detailedTuition, setDetailedTuition] = useState([]);
  const [totalTuitionForGrades, setTotalTuitionForGrades] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(
    getWeekNumber(new Date()) - 1
  );

  const donutData = {
    labels: [
      "Προβλεπόμενα Δίδακτρα " + totalTuition + "€",
      "Πληρωμένα Δίδακτρα " + totalTuitionEarned + "€",
    ],
    datasets: [
      {
        data: [totalTuition, totalTuitionEarned],
        backgroundColor: ["#6124e681", "#ffd53d92"],
        borderColor: ["#6225e6", "#ffd43d"],
        borderWidth: 1,
      },
    ],
  };

  const donutOptions = {
    cutoutPercentage: 50,
    responsive: true,
    maintainAspectRatio: false,
  };

  const tuitionForGradesData = {
    labels: totalTuitionForGrades.map((item) => item.grade_name),
    datasets: [
      {
        label: "Συνολικά Δίδακτρα",
        data: totalTuitionForGrades.map((item) => item.total_amount),
        backgroundColor: "#6124e681",
        borderColor: "#6225e6",
        borderWidth: 1,
        borderRadius: 10,
      },
    ],
  };

  const tuitionForGradesOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Amount",
        data: [],
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  });

  const [weeklyData, setWeeklyData] = useState([]);

  useEffect(() => {
    return getGeneralSettings();
  }, []);

  useEffect(() => {
    if (tuitionIsMonthly) {
      return getMonthlyTuition();
    } else {
      const cleanUpGetTotalTuition = getTotalTuition();
      const cleanUpGetTotalTuitionEarned = getTotalTuitionEarned();
      const cleanUpGetDetailedTuition = getDetailedTuition();
      const cleanUpGetTuitionForGrades = getTotalTuitionForGrades();
      getUsers();
      return () => {
        cleanUpGetDetailedTuition();
        cleanUpGetTotalTuition();
        cleanUpGetTuitionForGrades();
        cleanUpGetTotalTuitionEarned();
      };
    }
  }, [tuitionIsMonthly]);

  const getGeneralSettings = () => {
    let args = {};

    const getGeneralSettingsListener = (data) => {
      const tuitionIsMonthlySetting = () => {
        return data.find((obj) => obj.name === "tuition_is_monthly");
      };
      if (tuitionIsMonthlySetting()) {
        setTuitionIsMonthly(tuitionIsMonthlySetting().value);
      } else {
        setTuitionIsMonthly(false);
      }
    };

    const refreshGeneralSettingsListener = () => {
      socketContext.socket.emit("getGeneralSettings", args);
    };

    socketContext.socket.on("generalSettings", getGeneralSettingsListener);
    socketContext.socket.emit("getGeneralSettings", args);
    socketContext.socket.on(
      "refreshGeneralSettings",
      refreshGeneralSettingsListener
    );

    return () => {
      socketContext.socket.off(
        "getGeneralSettings",
        getGeneralSettingsListener
      );
      socketContext.socket.off("generalSettings", getGeneralSettingsListener);
      socketContext.socket.off(
        "refreshGeneralSettings",
        refreshGeneralSettingsListener
      );
    };
  };

  const getMonthlyTuition = () => {
    let args = {};
    const getMonthlyTuitionListener = (data) => {
      setMonthlyTuition(data);
    };

    socketContext.socket.on("monthlyTuition", getMonthlyTuitionListener);
    socketContext.socket.emit("getMonthlyTuition", args);

    return () => {
      socketContext.socket.off("getMonthlyTuition", getMonthlyTuitionListener);
      socketContext.socket.off("monthlyTuition", getMonthlyTuitionListener);
    };
  };

  const getDetailedTuition = () => {
    let args = { selectOnlyPayments: true };
    const getDetailedTuitionListener = (data) => {
      console.log(data);
      setDetailedTuition(data);
      setChartData(prepareChartData(data));
      setWeeklyData(prepareWeeklyData(data));
    };

    socketContext.socket.on("detailedTuition", getDetailedTuitionListener);
    socketContext.socket.emit("getDetailedTuition", args);

    return () => {
      socketContext.socket.off(
        "getDetailedTuition",
        getDetailedTuitionListener
      );
      socketContext.socket.off("detailedTuition", getDetailedTuitionListener);
    };
  };

  const getTotalTuitionEarned = () => {
    let args = {};
    const getTotalTuitionEarnedListener = (data) => {
      if (data) {
        setTotalTuitionEarned(data);
      }
    };

    socketContext.socket.on(
      "totalTuitionEarned",
      getTotalTuitionEarnedListener
    );
    socketContext.socket.emit("getTotalTuitionEarned", args);

    return () => {
      socketContext.socket.off(
        "getTotalTuitionEarned",
        getTotalTuitionEarnedListener
      );
      socketContext.socket.off(
        "totalTuitionEarned",
        getTotalTuitionEarnedListener
      );
    };
  };

  const getTotalTuition = () => {
    let args = {};
    const getTotalTuitionListener = (data) => {
      if (data) {
        setTotalTuition(data);
      }
    };

    socketContext.socket.on("totalTuition", getTotalTuitionListener);
    socketContext.socket.emit("getTotalTuition", args);

    return () => {
      socketContext.socket.off("getTotalTuition", getTotalTuitionListener);
      socketContext.socket.off("totalTuition", getTotalTuitionListener);
    };
  };

  const getTotalTuitionForGrades = () => {
    let args = {};
    const getTotalTuitionForGradesListener = (data) => {
      data.map((item) => {
        if (item.total_amount < 0) {
          item.total_amount = 0;
        }
      });
      setTotalTuitionForGrades(data);
    };

    socketContext.socket.on(
      "totalTuitionForGrades",
      getTotalTuitionForGradesListener
    );
    socketContext.socket.emit("getTotalTuitionForGrades", args);

    return () => {
      socketContext.socket.off(
        "getTotalTuitionForGrades",
        getTotalTuitionForGradesListener
      );
      socketContext.socket.off(
        "totalTuitionForGrades",
        getTotalTuitionForGradesListener
      );
    };
  };

  const prepareChartData = (data) => {
    const months = [
      "Ιαν",
      "Φεβ",
      "Μάρ",
      "Απρ",
      "Μάι",
      "Ιούν",
      "Ιούλ",
      "Αύγ",
      "Σεπ",
      "Οκτ",
      "Νοέ",
      "Δεκ",
    ];
    let sumsByMonth = Array(12).fill(0);

    data.forEach((item) => {
      const date = new Date(item.time);
      const monthIndex = date.getMonth();
      sumsByMonth[monthIndex] += item.amount;
    });

    sumsByMonth = [...sumsByMonth.slice(8), ...sumsByMonth.slice(0, 8)];
    const monthLabels = [...months.slice(8), ...months.slice(0, 8)];

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
        },
      },
    };

    return {
      labels: monthLabels,
      datasets: [
        {
          label: "Σύνολο πληρωμών",
          data: sumsByMonth,
          fill: false,
          backgroundColor: "#6225e6",
          borderColor: "#6224e645",
        },
      ],
      options: options,
    };
  };

  const prepareWeeklyData = (data) => {
    const daysOfWeek = [
      "Κυριακή",
      "Δευτέρα",
      "Τρίτη",
      "Τετάρτη",
      "Πέμπτη",
      "Παρασκευή",
      "Σάββατο",
    ];
    let weeks = [];

    for (let i = 0; i < 52; i++) {
      let weekData = Array(7).fill(0);
      weeks.push({
        week: i + 1,
        days: daysOfWeek.map((day, index) => ({
          day,
          amount: 0,
        })),
      });
    }

    data.forEach((item) => {
      const date = new Date(item.time);
      const weekIndex = getWeekNumber(date) - 1;
      const dayIndex = date.getDay();

      weeks[weekIndex].days[dayIndex].amount += item.amount;
    });

    return weeks;
  };

  const formatWeekTitle = (week) => {
    const startOfWeek = getStartOfWeek(week);
    const endOfWeek = getEndOfWeek(week);
    return `${startOfWeek.getDate()} ${getMonthName(
      startOfWeek.getMonth()
    )} ${startOfWeek.getFullYear()} - ${endOfWeek.getDate()} ${getMonthName(
      endOfWeek.getMonth()
    )} ${endOfWeek.getFullYear()}`;
  };

  const currentWeekIndex = getWeekNumber(new Date()) - 1;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentWeekIndex,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const [users, setUsers] = useState([]);

  const getUsers = () => {
    let args = {};

    const getUserDataListener = (data) => {
      setUsers(data);
    };

    socketContext.socket.on("userDataForAutoProgram", getUserDataListener);
    socketContext.socket.emit("getUserDataForAutoProgram", args);
  };

  const getTuitionForCurrentWeek = () => {
    const currentWeekStart = getStartOfWeek(currentSlide + 1);
    const currentWeekEnd = getEndOfWeek(currentSlide + 1);
    return detailedTuition.filter((tuition) => {
      const tuitionDate = new Date(tuition.time);
      return tuitionDate >= currentWeekStart && tuitionDate <= currentWeekEnd;
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div
      className={
        "section tuition-section " + (tuitionIsMonthly ? "d-none" : "")
      }
    >
      <span className="section__title">Δίδακτρα</span>
      <div className="tuition-section__general">
        <div className="item">
          <div className="item__icon">
            <BiUser style={iconStyle("#6225E6")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Αναμ. Ετήσιος Τζίρος</span>
            <span className="label">
              {new Intl.NumberFormat("de-DE").format(totalTuition)}€
            </span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiWallet style={iconStyle("#FFD43D")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Συν. Εισπράξεις</span>
            <span className="label">
              {new Intl.NumberFormat("de-DE").format(totalTuitionEarned)}€
            </span>
          </div>
        </div>
        <div className="item">
          <div className="item__icon">
            <BiWallet style={iconStyle("#ff9797")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">Αναμ. Εισπράξεις</span>
            <span className="label">
              {new Intl.NumberFormat("de-DE").format(
                totalTuition - totalTuitionEarned
              )}
              €
            </span>
          </div>
        </div>
        {/* <div className="item">
          <div className="item__icon">
            <BiMoney style={iconStyle("#25e662")} color={"white"} size={50} />
          </div>
          <div className="item__details">
            <span className="title">M.O. Μηνιαίων Εισ.</span>
            <span className="label">100€</span>
          </div>
        </div> */}
      </div>
      <div className="tuition-stats">
        <div className="tuition-stats__payments">
          <div className="graph graph-container">
            <span className="label">Σύνολο διδάκτρων ανά μήνα</span>
            <Line data={chartData} />
          </div>
          <div className="general">
            <div className="grades graph-container">
              <span className="label">Σύνολο διδάκτρων ανά τάξη</span>
              <Bar
                data={tuitionForGradesData}
                options={tuitionForGradesOptions}
              />
            </div>
            <div className="total  graph-container">
              {donutData && donutOptions ? (
                <Pie data={donutData} options={donutOptions} />
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
        </div>
        <div className="tuition-stats__users"></div>
      </div>
      <div className="weekly-slider">
        <div className="slider-controls">
          <button className="cta cta-shadow" onClick={handlePrevious}>
            Προηγούμενη
          </button>
          <h3>
            {formatWeekTitle(
              weeklyData[currentSlide]?.week || currentWeekIndex + 1
            )}
          </h3>
          <button className="cta cta-shadow" onClick={handleNext}>
            Επόμενη
          </button>
        </div>
        <div className="slider-wrapper">
          <Slider {...settings} ref={sliderRef}>
            {weeklyData.map((week, index) => (
              <div key={index} className="week-slide">
                <Bar
                  data={{
                    labels: week.days.map((day) => day.day),
                    datasets: [
                      {
                        label: "Πληρωμές",
                        data: week.days.map((day) => day.amount),
                        backgroundColor: "#6124e681",
                        borderColor: "#6225e6",
                        borderWidth: 1,
                        borderRadius: 20,
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            ))}
          </Slider>
          <div className="weekly-details">
            {getTuitionForCurrentWeek().map((tuition, index) => {
              const user = users.find(
                (user) => user.user_id === tuition.student_id
              );
              return (
                <div key={index} className="tuition-item">
                  <div className="tuition-item__profile">
                    <img
                      className="profile-img__img"
                      src={
                        user.profile_picture
                          ? user.profile_picture
                          : "resources/student.png"
                      }
                      alt={"student"}
                    />
                    <span className="tuition-name">
                      {user
                        ? `${user.first_name} ${user.last_name}`
                        : "Unknown User"}
                    </span>
                  </div>
                  <div className="tuition-item__details">
                    <span className="tuition-date">
                      {formatDate(tuition.time)}
                    </span>
                    <span className="tuition-amount">{tuition.amount}€</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TuitionSection;
