import React, { useState, useEffect, useContext } from "react";
import "./quiz-score-slide.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SocketContext } from "../../app/socket";
import { BiCheck, BiArrowBack, BiX } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function QuizScoreSlide({ finalScore, submitted, chapters, questions }) {
  const socketContext = useContext(SocketContext);
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.value);
  const [quizPositions, setQuizPositions] = useState([]);

  useEffect(() => {
    if (submitted) {
      console.log("questions");
      console.log(questions);
      getRankingPositions();
    }
  }, [submitted]);

  const getRankingPositions = () => {
    console.log(" getting ranked position ");
    let args = {
      chapters: JSON.stringify(chapters),
    };
    const getQuizPositionsListener = (data) => {
      console.log("quiz positions");
      console.log(data);
      setQuizPositions(data);
    };

    socketContext.socket.emit("getQuizPositions", args);

    const refreshQuizPositionsListener = () => {
      socketContext.socket.emit("getQuizPositions", args);
    };

    const onQuizSubmitted = () => {
      socketContext.socket.emit("getQuizPositions", args);
    };

    socketContext.socket.on(
      "submittedQuizScore" + profile.user_id,
      onQuizSubmitted
    );

    socketContext.socket.on("quizPositions", getQuizPositionsListener);
    socketContext.socket.on(
      "refreshQuizPositions",
      refreshQuizPositionsListener
    );

    return () => {
      socketContext.socket.off("getQuizPositions", getQuizPositionsListener);
      socketContext.socket.off("quizPositions", getQuizPositionsListener);
      socketContext.socket.off(
        "refreshQuizPositions",
        refreshQuizPositionsListener
      );
    };
  };

  const getIncorrectMatching = (quest, index) => {
    let temp = "";
    try {
      temp = JSON.parse(quest.answers[index].answer)[1];
    } catch (e) {
      console.log(e);
    }
    return temp;
  };

  const populateQuizAnswers = () => {
    return questions.map((question, index) => {
      if (!question.not_answered)
        return (
          <div
            key={"result__question" + index}
            className="quiz-score-slide__answer"
          >
            <div
              className={
                "question " +
                (question.type == "correct/wrong"
                  ? "correct-wrong"
                  : question.type)
              }
            >
              <span className="question__title editor-read-ony ">
                {question.correct ? (
                  <div className="question__title-is-correct ">
                    <span>Απάντησες Σωστά!</span>
                    <BiCheck
                      className="correct-icon"
                      size={"30px"}
                      color={"#fff"}
                      style={iconStyle("transparent")}
                    />
                  </div>
                ) : (
                  <div className="question__title-is-correct not-correct">
                    <span>Απάντησες Λάθος!</span>
                    <BiX
                      className="correct-icon"
                      size={"30px"}
                      color={"#fff"}
                      style={iconStyle("transparent")}
                    />
                  </div>
                )}
                <div
                  className={
                    "title-wrapper " +
                    (question.type == "correct/wrong" && !question.is_multiple
                      ? " just-title"
                      : "")
                  }
                >
                  <CKEditor
                    editor={ClassicEditor}
                    data={question.description}
                    config={{
                      toolbar: [], // Configures an empty toolbar
                    }}
                    disabled={true} // Disable editing
                  />
                  {question.type == "correct/wrong" && !question.is_multiple ? (
                    <div className="correct-wrong">
                      <span
                        className={
                          "" +
                          ((question.correct && question.is_correct) ||
                          (!question.correct && !question.is_correct)
                            ? "selected"
                            : "") +
                          (question.is_correct ? " correct" : "")
                        }
                      >
                        Σωστό
                      </span>
                      <span
                        className={
                          "" +
                          ((!question.correct && question.is_correct) ||
                          (question.correct && !question.is_correct)
                            ? "selected"
                            : "") +
                          (!question.is_correct ? " correct" : "")
                        }
                      >
                        Λάθος
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </span>
              {question.type != "correct/wrong" ||
              (question.type == "correct/wrong" && question.is_multiple) ? (
                <div className="question__answers">
                  {question.type === "multiple-choice" &&
                    question.answers.map((answer, answerIndex) => {
                      return (
                        <div
                          key={"restul__answer" + answerIndex}
                          className={
                            "question__answers-answer " +
                            (answer.selected ? "selected " : " ") +
                            (answer.is_correct ? " correct " : " ")
                          }
                        >
                          {answer.answer}
                        </div>
                      );
                    })}
                  {question.type === "correct/wrong" &&
                    question.answers.map((answer, answerIndex) => {
                      return (
                        <div
                          key={"restul__answer" + answerIndex}
                          className={"question__answers-answer "}
                        >
                          {answer.answer}
                          <div className="correct-wrong">
                            <span
                              className={
                                "" +
                                (answer.selected ? "selected" : "") +
                                (answer.is_correct ? " correct" : "")
                              }
                            >
                              Σωστό
                            </span>
                            <span
                              className={
                                "" +
                                (!answer.selected ? "selected" : "") +
                                (!answer.is_correct ? " correct" : "")
                              }
                            >
                              Λάθος
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  {question.type === "matching" &&
                    question.pairs.map((pair, answerIndex) => {
                      return (
                        <div
                          key={"restul__answer" + answerIndex}
                          className={
                            "question__answers-answer matching " +
                            (pair.answerA.id !== pair.answerB.id
                              ? " wrong"
                              : "")
                          }
                        >
                          <div
                            className={
                              "column a " +
                              (pair.answerA.id == pair.answerB.id
                                ? "correct"
                                : "")
                            }
                          >
                            <span className="label">{pair.answerA.answer}</span>
                          </div>
                          <BiArrowBack
                            className="arrow"
                            size={"25px"}
                            color={"#6225E6"}
                            style={iconStyle("transparent")}
                          />
                          <div
                            className={
                              "column b " +
                              (pair.answerA.id == pair.answerB.id
                                ? "correct"
                                : "")
                            }
                          >
                            <span className={"label "}>
                              {pair.answerB.answer}
                            </span>
                            {pair.answerA.id !== pair.answerB.id ? (
                              <span className="label">
                                {getIncorrectMatching(question, answerIndex)}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                ""
              )}
              {question.explanation ? (
                <div className="quiz-score-slide__explanation">
                  <span className="label">Επεξήγηση</span>
                  <span className="sub-title">{question.explanation}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        );
    });
  };

  return (
    <div className="quiz-score-slide">
      <div className="wrapper">
        <div className="details">
          <span className="title">Τέλος Quiz!</span>
          <span className="subtitle">Το σκορ σου είναι:</span>
          <div className="score">
            <CircularProgressbar
              pathColor="{red}"
              strokeWidth={6}
              className={"score-circle"}
              value={finalScore}
              duration={2}
              text=""
            />
            <span className="score-text">{finalScore} %</span>
          </div>
          <div className="positions">
            <div className="positions__top">
              <div className="positions__top-position second">
                <div className="user">
                  <img
                    className="user__img"
                    src={
                      quizPositions &&
                      quizPositions[1] &&
                      quizPositions[1].profile_picture
                        ? quizPositions[1].profile_picture
                        : "resources/student.png"
                    }
                    alt={"student information system"}
                  />
                  <span className="user__name">
                    {quizPositions && quizPositions[1]
                      ? quizPositions[1].first_name +
                        " " +
                        quizPositions[1].last_name
                      : "---- ----"}
                  </span>
                  <span className="user__score">
                    {quizPositions && quizPositions[1]
                      ? quizPositions[1].average_score + "%"
                      : "-%"}
                  </span>
                </div>
                <div className="rank">
                  <span>2</span>
                </div>
              </div>
              <div className="positions__top-position first">
                <div className="user">
                  <img
                    className="user__img"
                    src={
                      quizPositions &&
                      quizPositions[0] &&
                      quizPositions[0].profile_picture
                        ? quizPositions[0].profile_picture
                        : "resources/student.png"
                    }
                    alt={"student information system"}
                  />
                  <span className="user__name">
                    {quizPositions &&
                    quizPositions[0] &&
                    quizPositions[0].first_name
                      ? quizPositions[0].first_name +
                        " " +
                        quizPositions[0].last_name
                      : "---- ----"}
                  </span>
                  <span className="user__score">
                    {quizPositions && quizPositions[0]
                      ? quizPositions[0].average_score + "%"
                      : "-%"}
                  </span>
                </div>
                <div className="rank">
                  <span>1</span>
                </div>
              </div>
              <div className="positions__top-position third">
                <div className="user">
                  <img
                    className="user__img"
                    src={
                      quizPositions &&
                      quizPositions[2] &&
                      quizPositions[2].profile_picture
                        ? quizPositions[1].profile_picture
                        : "resources/student.png"
                    }
                    alt={"student information system"}
                  />
                  <span className="user__name">
                    {quizPositions && quizPositions[2]
                      ? quizPositions[1].first_name +
                        " " +
                        quizPositions[2].last_name
                      : "---- ----"}
                  </span>
                  <span className="user__score">
                    {quizPositions && quizPositions[2]
                      ? quizPositions[1].average_score + "%"
                      : "-%"}
                  </span>
                </div>
                <div className="rank">
                  <span>3</span>
                </div>
              </div>
            </div>
            <div className="positions__secondary"></div>
          </div>
          <div className="separator">
            <div className="line"></div>
          </div>
          <div className="result__answers">
            <div className="result__answers-explain">
              <div className="line"></div>
              <span className="result__answers-explain-answer">
                Απάντηση Μαθητή
              </span>
              <div className="result__answers-explain-dot"></div>
              <span className="result__answers-explain-answer">
                Σωστή Απάντηση
              </span>
            </div>
            <span className="title">Αποτελέσματα απαντήσεων</span>
            {populateQuizAnswers()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(QuizScoreSlide);
