import React, { useState, useEffect, useRef } from "react";
import { BiDotsHorizontal } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";
import "./drop-down-menu.scss";

function DropDownMenu({ onAction }) {
  const [showContent, setShowContent] = useState(false);
  const menuRef = useRef(null);

  const handleToggleMenu = () => {
    setShowContent((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowContent(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = () => {
    setShowContent(false);
    onAction();
  };

  return (
    <div className="drop-down-menu" ref={menuRef}>
      <div className="drop-down-menu__preview" onClick={handleToggleMenu}>
        <BiDotsHorizontal
          size={"20px"}
          color={"#ccc"}
          style={iconStyle("transparent")}
        />
      </div>
      <div
        className={"drop-down-menu__content " + (showContent ? "active" : "")}
      >
        <span onClick={() => handleOptionClick()}>Διαγραφή</span>
      </div>
    </div>
  );
}

export default DropDownMenu;
