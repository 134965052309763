import React, { useEffect, useState } from "react";
import { calls } from "../../../../config/db_config";
import RichTextEditor from "../../../RichTextEditor/richeTextEditor";
import { useEventContext } from "../../eventContext";
import "./online-grades-section.scss";
import { useSelector } from "react-redux";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

function OnlineGradeItem({ item, updateDescription, updateRank }) {
  const [description, setDescription] = useState("");
  const { saveRichText } = useEventContext();
  const { onlineGrades } = useEventContext();
  const { eventMaxRank } = useEventContext();
  const [rank, setRank] = useState(item.rank ? item.rank : 0);
  const envVars = useSelector((state) => state.profile.environmentVars);

  useEffect(() => {
    if (saveRichText) {
      updateDescription(item.id, description);
    }
  }, [description]);

  useEffect(() => {
    updateRank(item.id, rank);
  }, [rank]);

  return (
    <div className={"online-grade-item"}>
      <div className="content">
        <span className="content__title">Εκφώνηση</span>
        <RichTextEditor
          key={"rich text editor " + item.id}
          imagesEndpoint={`${envVars.DB_SCHOOL_NAME}/exams`}
          postImagesEndPoint={calls.adminSubmitExamImage}
          text={item.description ? item.description : ""}
          setText={setDescription}
          saveFiles={saveRichText}
          fileNameStart="exams"
        />
        <div className="content__rank">
          <span className="content__rank-label">
            Μέγιστη Βαθμολογία Θέματος
          </span>
          <div className="thema-input">
            <span>
              Απομένουν{" "}
              {eventMaxRank -
                onlineGrades.reduce(
                  (accumulator, currentValue) =>
                    accumulator + parseInt(currentValue.rank),
                  0
                )}
            </span>
            <div className="rank-item__input">
              <BiChevronDown
                size={"30px"}
                color={"#fff"}
                style={iconStyle("#d6d6d6")}
                onClick={() => setRank(rank - 1)}
              />
              <input
                type="number"
                value={rank}
                onChange={(e) => setRank(e.target.value)}
              />
              <BiChevronUp
                size={"30px"}
                color={"#fff"}
                style={iconStyle("#d6d6d6")}
                onClick={() => setRank(rank + 1)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnlineGradeItem;
