import React, { useContext, useState } from "react";
import { BiCheck, BiPencil, BiTrash, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";

function ChapterItem({ chapter }) {
  const socketContext = useContext(SocketContext);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [newName, setNewName] = useState(chapter.chapter_name);

  const deleteChapter = () => {
    setDeleteOpen(false);
    let body = { chapter_id: chapter.id };
    socketContext.socket.emit("deleteChapter", body);
  };

  const editChapter = () => {
    setIsEdit(false);
    console.log(chapter);
    let body = { chapter_id: chapter.id, chapter_name: newName };
    socketContext.socket.emit("editChapter", body);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      editChapter();
    }
  };

  return (
    <div className="chapter-item">
      <div className={"delete-modal " + (deleteOpen ? "show" : "")}>
        <div className="delete-container">
          <span className="title ">
            Είστε σίγουροι ότι θέλετε να διαγράψατε το επιλεγμένο κεφάλαιο; Μαζι
            θα διαγραφούν όλα τα αντιστοίχα quiz.
          </span>
          <div className="controls">
            <button onClick={() => setDeleteOpen(false)} className="cta">
              Ακύρωση
            </button>
            <button onClick={() => deleteChapter()} className="cta cta-red">
              Διαγραφή
            </button>
          </div>
        </div>
      </div>
      <div className="chapter-item__info">
        <span className="chapter-item__info-name">
          <span className="label">
            Όνομα κεφαλαίου:{" "}
            {isEdit ? (
              <input
                className="input"
                placeholder="Πληκτρολογήστε νέο όνομα τάξης"
                value={newName}
                onKeyDown={handleKeyDown}
                onChange={(e) => setNewName(e.target.value)}
              />
            ) : (
              <span className="label-name">{chapter.chapter_name}</span>
            )}
          </span>
        </span>
        {!isEdit ? (
          <div className="chapter-item__info-action">
            <BiPencil
              className="close"
              size={"25px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
              onClick={() => setIsEdit(true)}
            />
            <BiTrash
              className="close"
              size={"25px"}
              color={"#FF6D6D"}
              style={iconStyle("transparent")}
              onClick={() => setDeleteOpen(true)}
            />
          </div>
        ) : (
          <div className={"chapter-item__info-action show"}>
            <BiX
              className="close"
              size={"25px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
              onClick={() => setIsEdit(false)}
            />
            <BiCheck
              className="close"
              size={"25px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
              onClick={() => editChapter()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChapterItem;
