import "./expenses-section.scss";
import { useContext, useEffect, useState, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { BiPlus } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import AddExpense from "./addExpense";

function ExpensesSection() {
  const socketContext = useContext(SocketContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newExpenseTypeName, setNewExpenseTypeName] = useState("");
  const [expensesTypes, setExpensesTypes] = useState([]);
  const [addExpanded, setAddExpanded] = useState(false);
  const [schoolExpenses, setSchoolExpenses] = useState([]);
  const [expenseDate, setExpenseDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [expenseAmount, setExpenseAmount] = useState();

  const [selectedExpenseType, setSelectedExpenseType] = useState();

  const dropdownRef = useRef(null);
  const previewRef = useRef(null);

  useEffect(() => {
    const cleanUpGetExpensesTypes = getExpensesTypes();
    const cleanUpGetSchoolExpenses = getSchoolExpenses();
    return () => {
      cleanUpGetExpensesTypes();
      cleanUpGetSchoolExpenses();
    };
  }, []);

  function formatGreekDate(dateString) {
    const date = new Date(dateString);

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = new Intl.DateTimeFormat("el-GR", options).format(
      date
    );

    return formattedDate;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        previewRef.current &&
        !previewRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, previewRef]);

  const addNewExpense = () => {};

  const addNewExpenseType = () => {
    let body = {
      type_name: newExpenseTypeName,
    };

    socketContext.socket.emit("addExpenseType", body);
    setNewExpenseTypeName("");
  };

  const getExpensesTypes = () => {
    let args = {};

    const getExpensesTypesListener = (data) => {
      setExpensesTypes(data);
      if (data && data.length) {
        setSelectedExpenseType(data[0].school_expenses_type_id);
      }
    };

    const refreshExpensesTypesListener = () => {
      socketContext.socket.emit("getExpensesTypes", args);
    };

    socketContext.socket.on("expensesTypes", getExpensesTypesListener);
    socketContext.socket.emit("getExpensesTypes", args);
    socketContext.socket.on(
      "refreshExpensesTypes",
      refreshExpensesTypesListener
    );

    return () => {
      socketContext.socket.off("getExpensesTypes", getExpensesTypesListener);
      socketContext.socket.off("expensesTypes", getExpensesTypesListener);
      socketContext.socket.off(
        "refreshExpensesTypes",
        refreshExpensesTypesListener
      );
    };
  };

  const getSchoolExpenses = () => {
    let args = {};

    const getSchoolExepnsesListener = (data) => {
      console.log(data);
      setSchoolExpenses(data);
    };

    const refreshSchoolExpensesListener = () => {
      socketContext.socket.emit("getSchoolExpenses", args);
    };

    socketContext.socket.on("schoolExpenses", getSchoolExepnsesListener);
    socketContext.socket.emit("getSchoolExpenses", args);
    socketContext.socket.on(
      "refreshSchoolExpenses",
      refreshSchoolExpensesListener
    );

    return () => {
      socketContext.socket.off("getSchoolExpenses", getSchoolExepnsesListener);
      socketContext.socket.off("schoolExpenses", getSchoolExepnsesListener);
      socketContext.socket.off(
        "refreshSchoolExpenses",
        refreshSchoolExpensesListener
      );
    };
  };

  const populateExpenses = () => {
    return schoolExpenses.map((expense, index) => {
      return (
        <div key={"expense-item " + index} className="expenses-timeline__item">
          <span className="expenses-timeline__item-type">
            {
              expensesTypes.find(
                (type) => type.school_expenses_type_id == expense.expense_type
              ).name
            }
          </span>
          <span className="expenses-timeline__item-date">
            {formatGreekDate(expense.date)}
          </span>
          <span className="expenses-timeline__item-ammount">
            <span>Ποσό: </span>
            <span>{expense.amount} €</span>
          </span>
        </div>
      );
    });
  };

  return (
    <div className={"section expenses-section"}>
      <span className="section__title">Εξοδολόγια</span>
      <AddExpense isExpanded={addExpanded} setIsExpanded={setAddExpanded} />
      <div className="expenses-section__content">
        <div className="expenses-timeline">{populateExpenses()}</div>
      </div>
    </div>
  );
}

export default ExpensesSection;
