import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import {
  BiCalendar,
  BiHome,
  BiX,
  BiBookOpen,
  BiTime,
  BiInfoCircle,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import "./parent-event.scss";
import { useSelector } from "react-redux";

function ParentEvent({ event }) {
  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);
  const [professors, setProfessors] = useState([]);

  useEffect(() => {
    const cleanUpGetProfessors = getProfessors();
    return () => {
      cleanUpGetProfessors();
    };
  }, []);

  const getProfessors = () => {
    let args = {
      class_id: event.class_id,
      department_id: event.department_id,
    };

    const getProfessorsListener = (data) => {
      console.log(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getParentEventProfessors", args);
    };

    socketContext.socket.on("parentEventProfessors", getProfessorsListener);
    socketContext.socket.emit("getParentEventProfessors", args);
    socketContext.socket.on(
      "refreshParentEventProfessors",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off(
        "getParentEventProfessors",
        getProfessorsListener
      );
      socketContext.socket.off("parentEventProfessors", getProfessorsListener);
      socketContext.socket.off(
        "refreshParentEventProfessors",
        refreshProfessorsListener
      );
    };
  };
  return (
    <div className="parent-event__card">
      <span></span>
    </div>
  );
}

export default ParentEvent;
