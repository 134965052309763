import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../../app/socket";
import { useSelector } from "react-redux";
import "./class-selector.scss";
import { BiChevronsDown, BiChevronsUp } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function ClassSelector({
  classes,
  selectedGrades,
  setSelectedGrades,
  selectedClasses,
  setSelectedClasses,
  setSelectedClassId,
  isSingle,
}) {
  const socketContext = useContext(SocketContext);
  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const [grades, setGrades] = useState([]);
  const [openGrades, setOpenGrades] = useState({});

  useEffect(() => {
    getGrades();
  }, []);

  const getGrades = () => {
    let args = {};

    const getGradesListener = (data) => {
      setGrades(data);
    };

    socketContext.socket.on("grades", getGradesListener);
    socketContext.socket.emit("getGrades", args);
    socketContext.socket.on("refreshGrades", () => {
      socketContext.socket.emit("getGrades", args);
    });

    // Cleanup listeners on component unmount
    return () => {
      socketContext.socket.off("grades", getGradesListener);
    };
  };

  const handleGradeClick = (gradeId) => {
    if (isSingle) {
      handleToggleClick(gradeId);
      return;
    }

    if (selectedGrades.includes(gradeId)) {
      setSelectedGrades((prev) => prev.filter((id) => id !== gradeId));
    } else {
      setSelectedGrades((prev) => [...prev.filter((id) => id !== -1), gradeId]);
      setSelectedClasses((prev) =>
        prev.filter((cls) => {
          const clsGrade = classes.find((c) => c.class_id === cls);
          return clsGrade && clsGrade.grade_id !== gradeId;
        })
      );
    }
  };

  const handleToggleClick = (gradeId) => {
    setOpenGrades((prev) => ({
      ...prev,
      [gradeId]: !prev[gradeId],
    }));
  };

  const handleClassClick = (classId) => {
    const classGradeId = classes.find(
      (cls) => cls.class_id === classId
    ).grade_id;

    if (isSingle) {
      setSelectedClasses((prev) => (prev.includes(classId) ? [] : [classId]));
      setSelectedGrades([]);
    } else {
      if (selectedClasses.includes(classId)) {
        setSelectedClasses((prev) => prev.filter((id) => id !== classId));
      } else {
        setSelectedClasses((prev) => [
          ...prev.filter((id) => id !== -1),
          classId,
        ]);
        setSelectedGrades((prev) => prev.filter((id) => id !== classGradeId));
      }
    }
  };

  const handleSelectAll = () => {
    setSelectedGrades([-1]);
    setSelectedClasses([-1]);
  };

  const renderClasses = (gradeId) => {
    return classes
      .filter((cls) => cls.grade_id === gradeId)
      .map((cls) => (
        <div
          key={cls.class_id}
          className={`class-selector__class ${
            selectedClasses.includes(cls.class_id) ? "selected" : ""
          }`}
          onClick={() => handleClassClick(cls.class_id)}
        >
          {cls.class_name}
        </div>
      ));
  };

  const isSelectAllSelected =
    selectedGrades.length === 1 &&
    selectedGrades[0] === -1 &&
    selectedClasses.length === 1 &&
    selectedClasses[0] === -1;

  useEffect(() => {
    if (
      selectedClasses &&
      selectedClasses.length &&
      isSingle &&
      setSelectedClassId
    ) {
      let selectedClassId = selectedClasses[0];
      setSelectedClassId(selectedClassId);
      if (selectedClassId != -1) {
        console.log(" selected class ");
        console.log(selectedClassId);
        let gradeId = classes.filter(
          (cls) => cls.class_id == selectedClassId
        )[0].grade_id;
        console.log(gradeId);
        setOpenGrades((prev) => {
          // Check if the gradeId exists in the previous state
          if (!(gradeId in prev)) {
            // If it doesn't exist, add it with an initial value (e.g., true)
            return {
              ...prev,
              [gradeId]: true,
            };
          }
          // If it already exists, return the previous state unchanged
          return prev;
        });
      }
    }
  }, [selectedClasses]);

  return (
    <div className="class-selector">
      <span className="class-selector__title">Επιλέξτε Μάθημα</span>
      <div className="class-selector__accordion">
        {!isSingle ? (
          <button
            className={`class-selector__select-all ${
              isSelectAllSelected ? "selected" : ""
            }`}
            onClick={handleSelectAll}
          >
            <span>Όλα</span>
          </button>
        ) : (
          ""
        )}
        {grades.map((grade) => (
          <div
            key={grade.grade_id}
            className={`class-selector__grade ${
              openGrades[grade.grade_id] ? "expanded " : ""
            } ${selectedGrades.includes(grade.grade_id) ? " selected" : ""}`}
          >
            <div
              className={`class-selector__grade-title ${
                selectedGrades.includes(grade.grade_id) ? "selected" : ""
              }`}
              onClick={() => handleGradeClick(grade.grade_id)}
            >
              <span className="grade-name">{grade.grade_name}</span>

              <div className="grade-toggle">
                {openGrades[grade.grade_id] ? (
                  <BiChevronsUp
                    className="search-close"
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleClick(grade.grade_id);
                    }}
                  />
                ) : (
                  <BiChevronsDown
                    className="search-close"
                    size={"35px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleClick(grade.grade_id);
                    }}
                  />
                )}
              </div>
              {/* <button
                className="class-selector__toggle"
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleClick(grade.grade_id);
                }}
              >
                {openGrades[grade.grade_id] ? "-" : "+"}
              </button> */}
            </div>
            <div className={`class-selector__classes`}>
              {renderClasses(grade.grade_id)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClassSelector;
