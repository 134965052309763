import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../../../app/socket";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";

const opaqueColor = "#6225E6";

const adjustOpacity = (color, opacity) => {
  let r = parseInt(color.slice(1, 3), 16),
    g = parseInt(color.slice(3, 5), 16),
    b = parseInt(color.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

function ExamSlide({ exam }) {
  const socketContext = useContext(SocketContext);
  const [departmentsAvg, setDepartmentsAvg] = useState([]);
  const [departmentsThemataAvg, setDepartmentsThemataAvg] = useState([]);
  const [examAvg, setExamAvg] = useState(0);
  const [examResults, setExamResults] = useState([]);
  const [totalStudentsInExam, setTotalStudentsInExam] = useState(0);
  const chartContainerRef = useRef(null);

  const gradeStatsRef = useRef(null);
  const userStatsRef = useRef(null);

  useEffect(() => {
    if (gradeStatsRef.current) {
      const gradeStatsHeight = gradeStatsRef.current.clientHeight;
      if (userStatsRef.current) {
        userStatsRef.current.style.maxHeight = `${gradeStatsHeight}px`;
      }
    }
  }, [
    departmentsThemataAvg,
    examAvg,
    examResults,
    totalStudentsInExam,
    departmentsAvg,
  ]);

  const hasThemata = departmentsThemataAvg && departmentsThemataAvg.length > 0;

  const barHeight = 10;
  const gapBetweenBars = 10;

  const calculatedHeight = () => {
    let height = 30;
    departmentsThemataAvg.map((item) => {
      if (item.detailed_avg_score) {
        height += item.detailed_avg_score.length * (barHeight + gapBetweenBars);
      }
    });
    return height;
  };

  useEffect(() => {
    if (chartContainerRef.current) {
      chartContainerRef.current.style.height = `${calculatedHeight()}px`;
    }
  }, [departmentsThemataAvg]);

  const barChartData =
    hasThemata && departmentsThemataAvg[0].detailed_avg_score
      ? {
          labels: departmentsThemataAvg.map((item) => item.department_name),
          datasets: departmentsThemataAvg[0].detailed_avg_score.map(
            (_, index) => {
              const backgroundColors = departmentsThemataAvg.map(
                (_, itemIndex) => {
                  const currentOpacity = index === 0 ? 1 : 1 - index * 0.2;
                  const effectiveOpacity = Math.max(currentOpacity, 0.2);
                  return adjustOpacity(opaqueColor, effectiveOpacity);
                }
              );
              return {
                label: `Θέμα ${index + 1}`,
                data: departmentsThemataAvg.map(
                  (item) => item.detailed_avg_score[index]
                ),
                backgroundColor: backgroundColors,
                borderRadius: {
                  topLeft: 0,
                  topRight: 5,
                  bottomLeft: 0,
                  bottomRight: 5,
                },
                borderSkipped: false,
              };
            }
          ),
        }
      : "";

  const barChartOptions = hasThemata
    ? {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: "y",
        scales: {
          x: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: "right",
          },
        },
      }
    : {};

  const departmentsAvgData = {
    labels: departmentsAvg.map((dept) => dept.department_name),
    datasets: [
      {
        label: "Μέσος όρος τμημάτων",
        data: departmentsAvg.map((dept) => dept.avg_score),
        backgroundColor: "#6124e681",
        borderColor: "#6225E6",
        borderWidth: 1,
        borderRadius: 10,
      },
    ],
  };

  const departmentsAvgOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100, // Since the highest possible value is 100
      },
    },
  };

  useEffect(() => {
    const cleanUpGetDepartmentsAvg = getDeparrmentsAvg();
    const cleanUpGetExamResults = getExamResults();
    const cleanUpGetExamAvg = getExamAvg();
    const cleanGetStudentsCountInExam = getStudentsCountInExam();
    return () => {
      cleanUpGetDepartmentsAvg();
      cleanUpGetExamAvg();
      cleanUpGetExamResults();
      cleanGetStudentsCountInExam();
    };
  }, [exam]);

  const getDeparrmentsAvg = () => {
    let args = { exam_id: exam.id };
    const getDepartmentsAvgListener = (data) => {
      const sortedDepartments = data.sort((a, b) =>
        a.department_name.localeCompare(b.department_name)
      );
      sortedDepartments.map((sortedDepartment) => {
        sortedDepartment.detailed_avg_score = JSON.parse(
          sortedDepartment.detailed_avg_score
        );
      });
      setDepartmentsThemataAvg(sortedDepartments);

      const departmentsAvg = sortedDepartments;
      departmentsAvg.forEach((department) => {
        try {
          const scores = department.detailed_avg_score;
          const sum = scores.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
          department.avg_score = Math.round(sum * 10) / 10;
        } catch (error) {}
      });
      setDepartmentsAvg(departmentsAvg);
    };

    socketContext.socket.on(
      "examDepartmentsAvg" + exam.id,
      getDepartmentsAvgListener
    );
    socketContext.socket.emit("getExamDepartmentsAvg", args);

    return () => {
      socketContext.socket.off(
        "getExamDepartmentsAvg",
        getDepartmentsAvgListener
      );
      socketContext.socket.off(
        "examDepartmentsAvg" + exam.id,
        getDepartmentsAvgListener
      );
    };
  };

  const getExamResults = () => {
    let args = { exam_id: exam.id };
    const getExamResultsListener = (data) => {
      setExamResults(data);
    };

    socketContext.socket.on("examResults" + exam.id, getExamResultsListener);
    socketContext.socket.emit("getExamResults", args);

    return () => {
      socketContext.socket.off("getExamResults", getExamResultsListener);
      socketContext.socket.off("examResults" + exam.id, getExamResultsListener);
    };
  };

  // const getExamAvg = () => {
  //   let args = { exam_id: exam.id };
  //   const getDepartmentsAvgListener = (data) => {
  //     console.log(data);
  //     // if (data && data[0] && data[0].avg_score) {
  //     //   setExamAvg(data[0].avg_score);
  //     // }
  //   };

  //   socketContext.socket.on(
  //     "examDepartmentsAvg" + exam.id,
  //     getDepartmentsAvgListener
  //   );
  //   socketContext.socket.emit("getExamDepartmentsAvg", args);

  //   return () => {
  //     socketContext.socket.off(
  //       "getExamDepartmentsAvg",
  //       getDepartmentsAvgListener
  //     );
  //     socketContext.socket.off(
  //       "examDepartmentsAvg" + exam.id,
  //       getDepartmentsAvgListener
  //     );
  //   };
  // };

  const getExamAvg = () => {
    let args = { exam_id: exam.id };
    const getExamsAvgMonthlyListener = (data) => {
      if (data && data[0]) {
        setExamAvg(data[0].average_score);
      }
    };

    socketContext.socket.on("examsAvgMonthly", getExamsAvgMonthlyListener);
    socketContext.socket.emit("getExamsAvgMonthly", args);

    return () => {
      socketContext.socket.off(
        "getExamsAvgMonthly",
        getExamsAvgMonthlyListener
      );
      socketContext.socket.off("examsAvgMonthly", getExamsAvgMonthlyListener);
    };
  };

  const getStudentsCountInExam = () => {
    let args = { exam_id: exam.id, class_id: exam.class_id };
    const getStudentsCountInExamListener = (data) => {
      if (data && data[0].total_count) {
        setTotalStudentsInExam(data[0].total_count);
      }
    };

    socketContext.socket.on(
      "studentsCountInExam" + exam.id,
      getStudentsCountInExamListener
    );
    socketContext.socket.emit("getStudentsCountInExam", args);

    return () => {
      socketContext.socket.off(
        "getStudentsCountInExam",
        getStudentsCountInExamListener
      );
      socketContext.socket.off(
        "studentsCountInExam" + exam.id,
        getStudentsCountInExamListener
      );
    };
  };

  const populateUsers = () => {
    return examResults.map((result, index) => {
      return (
        <Link
          to={"/profile?user-id=" + result.user_id}
          key={"student-score" + result.id}
          className={"item " + (result.score ? "" : "not_scored")}
        >
          <div className="user-info">
            <div className="user-info__img">
              <img
                alt={"student"}
                src={
                  result.profile_picture
                    ? result.profile_picture
                    : "resources/student.png"
                }
              />
            </div>
            <div className="user-info__name">
              <span>{result.first_name}</span>
              <span>{result.last_name}</span>
            </div>
          </div>
          {result.score ? (
            <div className="score">
              <span className="score">
                <CircularProgressbar
                  pathColor="{red}"
                  strokeWidth={6}
                  className="user-score"
                  value={result.score}
                  duration={1.4}
                  text={result.score}
                />
              </span>
            </div>
          ) : (
            ""
          )}
        </Link>
      );
    });
  };

  return (
    <div className={"exam-slide"}>
      <div className="info">
        <span className="title">
          <span>Διαγώνισμα: </span>
          {exam.title}
        </span>
        <span className="class">
          <span>Μάθημα: </span>
          {exam.class_name}
        </span>
        <span className="date">
          {/* <span>Ημερομηνία: </span> */}
          {formatDate(exam.activated_at)}
        </span>
      </div>
      <div className="stats-container">
        <div className="grade-stats" ref={gradeStatsRef}>
          <div className="boxes">
            <div className="total-avg">
              <span className="label">Συνολικός μέσος όρος:</span>
              <span className="score">
                <CircularProgressbar
                  pathColor="{red}"
                  strokeWidth={6}
                  className="user-score"
                  value={examAvg}
                  duration={1.4}
                  text={`${parseFloat(examAvg.toFixed(1))}%`}
                />
              </span>
            </div>
            <div className="total-avg">
              <span className="label">Συμμετοχή μαθητών</span>
              <span className="score">
                <CircularProgressbar
                  pathColor="{red}"
                  strokeWidth={6}
                  className="user-score"
                  value={(examResults.length * 100) / totalStudentsInExam}
                  duration={1.4}
                  text={`${parseFloat(
                    ((examResults.length * 100) / totalStudentsInExam).toFixed(
                      1
                    )
                  )}%`}
                />
              </span>
            </div>
          </div>
          <div className="graph-container">
            <span className="label">Συνολικός μέσος όρος τμημάτων</span>
            <Bar data={departmentsAvgData} options={departmentsAvgOptions} />
          </div>
          {hasThemata ? (
            <div className="graph-container">
              <span className="label">Μέσος όρος τμημάτων ανά θέμα</span>
              <div
                ref={chartContainerRef}
                style={{ width: "100%" }} // Width can be 100% to fill the container
              >
                {departmentsThemataAvg &&
                hasThemata &&
                departmentsThemataAvg[0].detailed_avg_score ? (
                  <Bar data={barChartData} options={barChartOptions} />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="user-stats" ref={userStatsRef}>
          <span className="title">Επίδοση μαθητών</span>
          <div className="user-stats__list">{populateUsers()}</div>
        </div>
      </div>
    </div>
  );
}

export default ExamSlide;
