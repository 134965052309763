import React from "react";
import { useState, useContext } from "react";
import "./mobile-side-filters.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../../app/socket";
import { useNavigate } from "react-router-dom";
import { setCurrentClassId } from "../classNamesSlice";
import {
  BiPlus,
  BiCheck,
  BiX,
  BiTime,
  BiLock,
  BiRightArrow,
  BiGridVertical,
  BiArchive,
  BiDesktop,
  BiFoodMenu,
  BiTrim,
  BiTrash,
  BiAbacus,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import ClassItem from "./classItem";

const MobileSideFilters = ({ bigWidth }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const [classes, setClasses] = useState([]);

  const socketContext = useContext(SocketContext);

  const profile = useSelector((state) => state.profile.value);

  useEffect(() => {
    const cleanUpGetClasses = getClasses();
    return () => {
      cleanUpGetClasses();
    };
  }, []);

  const getClasses = () => {
    let args = {};

    const getClassesListener = (data) => {
      setClasses(data);
    };

    const refreshClassesListener = () => {
      socketContext.socket.emit("getClasses", args);
    };

    socketContext.socket.on("classes", getClassesListener);
    socketContext.socket.emit("getClasses", args);
    socketContext.socket.on("refreshClasses", refreshClassesListener);
    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClasses", getClassesListener);
      socketContext.socket.off("classes", getClassesListener);
      socketContext.socket.off("refreshClasses", refreshClassesListener);
    };
  };

  const populateClasses = () => {
    const sortedClasses = sortClasses();
    return sortedClasses.map((classItem, index) => {
      if (classItem.class_id != -10) {
        return (
          <ClassItem
            key={"classItem" + index}
            classItem={classItem}
            index={index}
            profile={profile}
            classItemClick={classItemClick}
          ></ClassItem>
        );
      } else {
        return (
          <div className="filters__class-grade">
            <span>{classItem.grade_name}</span>
          </div>
        );
      }
    });
  };

  const sortClasses = () => {
    const sortedClasses = classes;

    const withHeaders = [];
    let previousGrade = null;

    sortedClasses.forEach((classItem, index) => {
      if (classItem.grade_name != previousGrade) {
        withHeaders.push({
          class_id: -10,
          type: "header",
          grade_name: classItem.grade_name,
        });
        previousGrade = classItem.grade_name;
      }
      withHeaders.push(classItem);
    });
    return withHeaders;
  };

  const classItemClick = (e, classIndex) => {
    if (e.target.tagName !== "path" && e.target.tagName !== "svg") {
      dispatch(setCurrentClassId(classIndex));
    }
  };

  return (
    <div className={"mobile-filters " + (bigWidth ? " big-width" : "")}>
      <div className="mobile-filters__container">
        <div className="filters__section">
          <span className="filters__section-label">Μαθήματα</span>
          <div className="filters__section-classes">
            <div className={"filters__class-item "}>
              <div
                className={
                  "filters__class-item-button " +
                  (sideFiltersClassId == -1 ? " active" : "")
                }
                onClick={(e) => classItemClick(e, -1)}
              >
                <span>Όλα</span>
              </div>
            </div>
            {populateClasses()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSideFilters;
