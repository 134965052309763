import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { motion } from "framer-motion";
import ProfessorEventCard from "../../../../components/EventCard/exam/professor/professorEventCard";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiClipboard,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

function ProfessorExamsCard({ event, setEditIsOpen, setEventToEdit }) {
  const socketContext = useContext(SocketContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [modalHeight, setModalHeight] = useState(85);
  const [modalWidth, setModalWidth] = useState(80);
  const [modalLeft, setModalLeft] = useState(0.1);
  const [modalTop, setModalTop] = useState(0.075);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isExpanded]);

  const openEdit = () => {
    setIsExpanded(false);
    setEditIsOpen(true);
    setEventToEdit(event);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat("el-GR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formatter.format(date);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Define options for displaying hours and minutes
    const options = {
      hour: "2-digit", // two-digit hour
      minute: "2-digit", // two-digit minute
      hour12: false, // use 24-hour format
    };

    // Create an Intl.DateTimeFormat object for the locale with time options
    const formatter = new Intl.DateTimeFormat("el-GR", options);

    // Format and return the time
    return formatter.format(date);
  };

  useEffect(() => {
    if (window.innerWidth < 800) {
      setModalHeight(92);
      setModalWidth(90);
      setModalTop(0.05);
      setModalLeft(0.03);
    } else {
      if (event.type == "exam" || event.type == "test") {
        setModalHeight(85);
        setModalWidth(80);
        setModalTop(0.075);
        setModalLeft(0.1);
      }
    }
  }, [event]);

  const openCard = () => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newTopPosition = window.innerHeight * modalTop - topPosition;
      let newLeftPosition = window.innerWidth * modalLeft - leftPosition;

      const newPositionStyle = {
        top: newTopPosition + "px",
        left: newLeftPosition + "px",
        width: modalWidth + "vw",
        height: modalHeight + "vh",
      };
      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  const closeCard = () => {
    setIsExpanded(false);
  };

  const deleteExam = () => {
    socketContext.socket.emit("deleteExam", event.exam_id);
    setShowDeleteModal(false);
    setIsExpanded(false);
  };

  return (
    <div
      ref={containerRef}
      className={
        "exams__card professor-exams__list-card " +
        (isExpanded ? " is-expanded " : " is-collapsed ") +
        event.type
      }
    >
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        layout
        initial={false} // You can adjust this as needed
        className={
          "exams__card-content " + (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style} // Apply dynamic style here
      >
        <div
          className={
            "professor-exams__list-card-delete " +
            (showDeleteModal ? "show" : "")
          }
        >
          <div className="wrapper">
            <span className="wrapper__label ">
              Είστε σίγουροι ότι θέλετε να διαγράψατε το διαγώνισμα;
            </span>
            <div className="wrapper__actions">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="cta cta-red"
              >
                Ακύρωση
              </button>
              <button onClick={() => deleteExam()} className="cta">
                Διαγραφή
              </button>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <ProfessorEventCard event={event} closeCard={closeCard} />
        ) : (
          <div className="exams__card-content-closed">
            <div className="title__wrapper">
              <span className="title">{event.title}</span>
            </div>
            <div className="closed__details">
              <div className="detail-item">
                <BiBookAlt
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
                <span>{event.class_name}</span>
              </div>
              <div className="detail-item">
                {event.type == "online-exam" ? (
                  <BiCalendarCheck
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  <BiCalendar
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}
                <span>{formatDate(event.start_at)}</span>
              </div>
              <div className="detail-item">
                {event.type == "online-exam" ? (
                  <BiCalendarX
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                ) : (
                  <BiAlarm
                    size={"30px"}
                    color={"#cccccc"}
                    style={iconStyle("transparent")}
                  />
                )}

                <span>
                  {event.type == "online-exam"
                    ? formatDate(event.finish_at)
                    : formatDateTime(event.start_at) +
                      " - " +
                      formatDateTime(event.finish_at)}
                </span>
              </div>
            </div>
          </div>
        )}
        {isExpanded ? (
          <div className="exams__card-actions">
            <button className="cta" onClick={() => openEdit()}>
              Επεξεργασία
            </button>
            <button
              onClick={() => setShowDeleteModal(true)}
              className="cta cta-red"
            >
              Διαγραφή
            </button>
          </div>
        ) : (
          ""
        )}
      </motion.div>
    </div>
  );
}

export default ProfessorExamsCard;
