function getFileExtension(filename) {
  return filename
    .slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
    .toLowerCase();
}

function resizeBase64Img(base64, newWidth, newHeight) {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    canvas.style.width = newWidth.toString() + "px";
    canvas.style.height = newHeight.toString() + "px";
    let context = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
}

export const fileUtils = { getFileExtension, resizeBase64Img };
