import { createSlice } from "@reduxjs/toolkit";

export const classNamesSlice = createSlice({
  name: "classes",
  initialState: {
    value: [{}],
    selectedClassId: -1,
    items: [{}],
    newClassCreatedId: -1,
    selectedClassName: "",
  },
  reducers: {
    setClasses: (state, action) => {
      state.value = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    },
    setCurrentClassId: (state, action) => {
      state.selectedClassId = action.payload;
    },
    setNewClassCreatedId: (state, action) => {
      console.log(action.payload);
      state.newClassCreatedId = action.payload;
    },
    setCurrentClassName: (state, action) => {
      console.log(action.payload);
      state.selectedClassName = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setClasses,
  setCurrentClassId,
  setCurrentClassName,
  setItem,
  setNewClassCreatedId,
} = classNamesSlice.actions;

export default classNamesSlice.reducer;
