import "./homepage.scss";
import Calendar from "../../components/Calendar/calendar";
import Announcments from "../../components/Announcements/announcements";
import { useHomepageContext } from "./homepageContext";
import { useSelector } from "react-redux";
import { getGeneralSettingValue } from "../../utils/generalUtils";

function HomepageWrapper() {
  const { modalOpen } = useHomepageContext();
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  return (
    <div className={" homepage  " + (modalOpen ? "modal-open" : "")}>
      {generalSettings &&
      !getGeneralSettingValue(generalSettings, "announcement_calendar") ? (
        <Announcments />
      ) : (
        ""
      )}
      <Calendar />
    </div>
  );
}

export default HomepageWrapper;
