import React from "react";
import "./loaderCard.scss";

function LoaderCard({ cardSum, width, height, align, mobileWidth }) {
  const populateCards = () => {
    const cardElements = [];
    for (let i = 0; i < cardSum; i++) {
      cardElements.push(
        <div
          key={"loaderCard" + i}
          className={
            "loader-card__item " +
            (width === 20 ? " width-20 " : "") +
            (width === 25 ? " width-25 " : "") +
            (width === 30 ? " width-30 " : "") +
            (height === "small" ? " height-small " : "") +
            (width === 50 ? " width-50 " : "") +
            (height === "xl" ? " height-xl " : "") +
            (height === "xxl" ? " height-xxl " : "") +
            (i > 0 ? " animation-delay__" + i : "") +
            (align === "right" ? "align-right" : "") +
            (mobileWidth === 100 ? " mobile-width-100" : "") +
            (mobileWidth === 50 ? " mobile-width-50" : "")
          }
        ></div>
      );
    }
    return cardElements;
  };

  return <div className="loader-card">{populateCards()}</div>;
}

export default LoaderCard;
