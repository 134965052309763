import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAbacus,
  BiChevronsDown,
  BiPlus,
  BiUpload,
  BiX,
} from "react-icons/bi";
import {
  getGeneralSettingValue,
  iconStyle,
} from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./curriculum-filters.scss";
import { useSelector } from "react-redux";

function CurriculumFilters({
  isExpanded,
  setIsExpanded,
  isGridView,
  setIsGridView,
  showExercises,
  setShowExercises,
  showLiveWork,
  setShowLiveWork,
}) {
  const socketContext = useContext(SocketContext);
  const filterRef = useRef(null);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isExpanded]);

  return (
    <div
      ref={filterRef}
      className={
        "curriculum-filters " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      <div
        className={"curriculum-filters__preview " + (isExpanded ? "hide" : "")}
        onClick={() => setIsExpanded(true)}
      >
        <BiChevronsDown
          size={"30px"}
          color={"#cccccc"}
          style={iconStyle("transparent")}
        />
      </div>
      {isExpanded ? (
        <div className="curriculum-filters__close">
          <BiX
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
            className="curriculum-filters__close"
            onClick={() => setIsExpanded(false)}
          />
        </div>
      ) : (
        ""
      )}
      {isExpanded && (
        <div className="curriculum-filters__content">
          <span className="content-title">Φίλτρα</span>
          <div className="content__options">
            <div
              className={
                "content__options-item " + (isGridView ? " active" : "")
              }
              onClick={() => setIsGridView(!isGridView)}
            >
              <span>Grid</span>
            </div>
            {getGeneralSettingValue(generalSettings, "has-live-work") ? (
              <div
                className={
                  "content__options-item " + (showLiveWork ? " active" : "")
                }
                onClick={() => setShowLiveWork(!showLiveWork)}
              >
                <span>LiveWork</span>
              </div>
            ) : (
              ""
            )}
            <div
              className={
                "content__options-item " + (showExercises ? " active" : "")
              }
              onClick={() => setShowExercises(!showExercises)}
            >
              <span>Ασκήσεις</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CurriculumFilters;
