import React from "react";
import "../skeleton.scss";
import SideFiltersSkeleton from "../sideFiltersSkeleton/sideFiltersSkeleton";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function QuizSkeleton() {
  return (
    <div className="users-skeleton skeleton">
      <SideFiltersSkeleton />
      <div className="users-wrapper wrapper">
        <LoaderCard cardSum={1} width={100} size="large" />
        <div className="text-center mt-5 mb-5">
          <LoaderCard cardSum={1} width={100} size="large" />
        </div>
        <LoaderCard cardSum={4} width={100} size="large" />
      </div>
    </div>
  );
}

export default QuizSkeleton;
