import React from "react";
import { motion, usePresence } from "framer-motion";
import { BiPlus, BiMinus } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

function UserItem({ user, type, handleClick }) {
  const [isPresent, safeToRemove] = usePresence();
  const transition = {
    type: "spring",
    stiffness: 500,
    damping: 70,
    mass: 5,
    duration: 2,
  };

  const animations = {
    layout: true,
    initial: "in",
    style: {
      position: isPresent ? "relative" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0, zIndex: -1 },
      tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.1 } },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  return (
    <motion.div
      {...animations}
      className="add-users-slide__list-item"
      onClick={handleClick}
    >
      <div className="action">
        {type === "add" ? (
          <BiPlus size={"30px"} color={"#fff"} style={iconStyle("#25e662")} />
        ) : (
          <BiMinus size={"30px"} color={"#fff"} style={iconStyle("#ff6f6f")} />
        )}
      </div>
      <div className="profile-img">
        <img
          src={
            user.profile_picture
              ? user.profile_picture
              : "resources/student.png"
          }
          alt="user"
        />
      </div>
      <div className="name">
        <span>{user.last_name}</span>
        <span>{user.first_name}</span>
      </div>
    </motion.div>
  );
}

export default UserItem;
