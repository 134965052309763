import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../app/socket";
import "./event-comment.scss";
import { useSelector } from "react-redux";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronRight,
  BiDesktop,
  BiPencil,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get the year, month, and day from the Date object
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Format and return the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
};

function EventComment({ selectedDate, event }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [lectureComment, setLectureComment] = useState();
  const [newLectureComment, setNewLectureComment] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const cleanUpGetEventComment = getLectureComment();
    return () => {
      cleanUpGetEventComment();
    };
  }, []);

  useEffect(() => {
    if (lectureComment) {
      console.log(" there is ");
      setNewLectureComment(lectureComment.comment_value);
    }
  }, [lectureComment]);

  const getLectureComment = () => {
    let args = {
      event_id: event.event_id,
      date: formatDate(selectedDate),
    };

    const getLectureCommentListener = (data) => {
      if (data && data[0]) {
        setLectureComment(data[0]);
      }
      // setLectureComment(data);
      if (!data || !data.length) {
        setIsEdit(true);
      }
    };

    const refreshLectureCommentListener = () => {
      socketContext.socket.emit("getLectureComment", args);
    };

    socketContext.socket.on(
      "lectureComment" + event.event_id,
      getLectureCommentListener
    );
    socketContext.socket.emit("getLectureComment", args);
    socketContext.socket.on(
      "refreshLectureComment" + event.event_id,
      refreshLectureCommentListener
    );

    return () => {
      socketContext.socket.off("getLectureComment", getLectureCommentListener);
      socketContext.socket.off(
        "lectureComment" + event.event_id,
        getLectureCommentListener
      );
      socketContext.socket.off(
        "refreshLectureComment" + event.event_id,
        refreshLectureCommentListener
      );
    };
  };

  const saveLectureComment = () => {
    if (!lectureComment) {
      let body = {
        event_id: event.event_id,
        date: formatDate(selectedDate),
        comment: newLectureComment,
      };

      socketContext.socket.emit("addLectureComment", body);
      setIsEdit(false);
    } else {
      let body = {
        lecture_comment_id: lectureComment.lecture_comment_id,
        event_id: event.event_id,
        comment: newLectureComment,
      };
      console.log("editing");
      console.log(body);

      socketContext.socket.emit("updateLectureComment", body);
      setIsEdit(false);
    }
  };

  return (
    <div className="event-comment">
      {!lectureComment ? (
        <div className="event-comment__new">
          <span className="event-comment__new-title">
            Εισάγεται διδακταία ύλη
          </span>
          {/* <textarea
            className="event-comment__new-textarea"
            placeholder="Διδακταία ύλη..."
            value={newLectureComment}
            onChange={(e) => setNewLectureComment(e.target.value)}
          ></textarea> */}
          <div className="event-comment__new-input">
            <input
              className="input"
              placeholder="Διδακταία ύλη..."
              value={newLectureComment}
              onChange={(e) => setNewLectureComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents the default action of Enter key in a form
                  saveLectureComment();
                }
              }}
            />
            <BiChevronRight
              onClick={() => saveLectureComment()}
              size={"25px"}
              color={"#6225e6"}
              style={iconStyle("transparent")}
            />
          </div>
          {/* <div className="event-comment__new-save">
            <button className="cta" onClick={() => saveLectureComment()}>
              Αποθήκευση
            </button>
          </div> */}
        </div>
      ) : isEdit ? (
        <div className="event-comment__new">
          <span className="event-comment__new-title">
            Επεξεργασία διδακταίας ύλης
          </span>
          <div className="event-comment__new-input">
            <input
              className="input"
              placeholder="Διδακταία ύλη..."
              value={newLectureComment}
              onChange={(e) => setNewLectureComment(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents the default action of Enter key in a form
                  saveLectureComment();
                }
              }}
            />
          </div>
          <div className="event-comment__new-save edit">
            <button
              className="cta cta-red"
              onClick={() => {
                setIsEdit(false);
                setNewLectureComment(lectureComment.comment_value);
              }}
            >
              Ακύρωση
            </button>
            <button className="cta" onClick={() => saveLectureComment()}>
              Αποθήκευση
            </button>
          </div>
        </div>
      ) : (
        <div className="event-comment__item">
          <span className="event-comment__item-title">Διδακταία ύλη</span>
          <span className="event-comment__item-comment">
            {lectureComment ? lectureComment.comment_value : ""}
          </span>
          <div
            className="event-comment__item-edit"
            onClick={() => setIsEdit(true)}
          >
            <BiPencil
              size={"25px"}
              color={"#ccc"}
              style={iconStyle("transparent")}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default EventComment;
