import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiPlus, BiUpload, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./add-expense.scss";
import { useSelector } from "react-redux";
import Compress from "compress.js";

function AddExpense({
  professors,
  students,
  parents,
  isExpanded,
  setIsExpanded,
}) {
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const [sendToAade, setSendToAade] = useState(false);
  const [aadeAfm, setAadeAfm] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newExpenseTypeName, setNewExpenseTypeName] = useState("");
  const [expensesTypes, setExpensesTypes] = useState([]);
  const [expenseDate, setExpenseDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [expenseAmount, setExpenseAmount] = useState();

  const [selectedExpenseType, setSelectedExpenseType] = useState();

  const dropdownRef = useRef(null);
  const previewRef = useRef(null);

  useEffect(() => {
    const cleanUpGetExpensesTypes = getExpensesTypes();
    return () => {
      cleanUpGetExpensesTypes();
    };
  }, []);

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.25 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "50vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        previewRef.current &&
        !previewRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, previewRef]);

  const addNewExpense = () => {};

  const addNewExpenseType = () => {
    if (newExpenseTypeName) {
      let body = {
        type_name: newExpenseTypeName,
      };

      socketContext.socket.emit("addExpenseType", body);
    }
  };

  const getExpensesTypes = () => {
    let args = {};

    const getExpensesTypesListener = (data) => {
      setExpensesTypes(data);
      if (data && data.length) {
        setSelectedExpenseType(data[0].school_expenses_type_id);
      }
    };

    const refreshExpensesTypesListener = () => {
      socketContext.socket.emit("getExpensesTypes", args);
    };

    socketContext.socket.on("expensesTypes", getExpensesTypesListener);
    socketContext.socket.emit("getExpensesTypes", args);
    socketContext.socket.on(
      "refreshExpensesTypes",
      refreshExpensesTypesListener
    );

    return () => {
      socketContext.socket.off("getExpensesTypes", getExpensesTypesListener);
      socketContext.socket.off("expensesTypes", getExpensesTypesListener);
      socketContext.socket.off(
        "refreshExpensesTypes",
        refreshExpensesTypesListener
      );
    };
  };

  const saveExpense = () => {
    let body = {
      amount: expenseAmount,
      type: selectedExpenseType,
      date: expenseDate,
    };

    socketContext.socket.emit("addSchoolExpense", body);

    setNewExpenseTypeName("");
    setIsExpanded(false);
    setExpenseAmount();
    setSelectedExpenseType();
  };

  const populateExpensesTypes = () => {
    return expensesTypes.map((type) => {
      return (
        <div
          className={
            "dropdown-content__item " +
            (selectedExpenseType &&
            selectedExpenseType == type.school_expenses_type_id
              ? " active"
              : "")
          }
          onClick={() => {
            setSelectedExpenseType(type.school_expenses_type_id);
            setDropdownOpen(false);
          }}
          key={"expensesType " + type.school_expenses_type_id}
        >
          <span>{type.name}</span>
        </div>
      );
    });
  };

  return (
    <div
      ref={containerRef}
      className={"add-expense " + (isExpanded ? "is-expanded" : "is-collapsed")}
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "add-expense__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="add-expense__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="add-expense__config-item">
              <span className="add-expense__config-item-label">
                Τύπος Εξοδολογίου
              </span>
              <div
                className={
                  "add-expense__config-select " + (dropdownOpen ? "open" : "")
                }
              >
                <div
                  className="dropdown-preview"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  ref={previewRef}
                >
                  {selectedExpenseType
                    ? expensesTypes.find(
                        (type) =>
                          type.school_expenses_type_id == selectedExpenseType
                      ).name
                    : " Επιλογή Τύπου"}
                </div>
                <div
                  className={"dropdown-content " + (dropdownOpen ? "open" : "")}
                  ref={dropdownRef}
                >
                  <div className="dropdown-content__item new">
                    <input
                      type="text"
                      value={newExpenseTypeName}
                      placeholder="Νέα Κατηγορία"
                      onChange={(e) => {
                        setNewExpenseTypeName(e.target.value);
                      }}
                    />
                    <div
                      className="new-button"
                      onClick={() => addNewExpenseType()}
                    >
                      <BiPlus
                        size={"30px"}
                        color={"#cccccc"}
                        style={iconStyle("transparent")}
                      />
                    </div>
                  </div>
                  {expensesTypes.length ? (
                    populateExpensesTypes()
                  ) : (
                    <div className="dropdown-content__item ">
                      <span>Προσθέστε τύπο εξοδολόγιου...</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="add-expense__config-item">
              <span className="add-expense__config-item-label">Ημερομηνία</span>
              <div className="add-expense__config-date">
                <input
                  className="time input"
                  type="date"
                  value={expenseDate}
                  onChange={(e) => setExpenseDate(e.target.value)}
                />
              </div>
            </div>

            <div className="add-expense__config-item">
              <span className="add-expense__config-item-label">Ποσό</span>
              <div className="add-expense__config-amount">
                <input
                  className="input"
                  placeholder="Ποσό"
                  value={expenseAmount}
                  onChange={(e) => setExpenseAmount(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="add-expense__config-item">
              <div
                className="configure__accept-answers checkbox-wrapper"
                onClick={() => setSendToAade(!sendToAade)}
              >
                <div
                  className={"checkbox " + (sendToAade ? "active" : "")}
                ></div>
                <span className="ms-2">Αποστολή στην ΑΑΔΕ</span>
              </div>
            </div>

            {sendToAade ? (
              <div className="add-expense__config-item">
                <span className="add-expense__config-item-label">Α.Φ.Μ.</span>
                <div className="add-expense__config-amount">
                  <input
                    className="input"
                    placeholder="Εισάγεται το ΑΦΜ"
                    value={aadeAfm}
                    onChange={(e) => setAadeAfm(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              ""
            )} */}

            <div className="add-expense__config-item save">
              <button className="cta" onClick={() => saveExpense()}>
                Αποθήκευση
              </button>
            </div>

            <div
              className="add-expense__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="add-expense__preview">
            <BiPlus
              size={"30px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
            <span>Προσθήκη Εξοδολόγιου</span>
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default AddExpense;
