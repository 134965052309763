import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { BiCheck, BiPencil, BiPlus, BiUpload, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import { SocketContext } from "../../../../app/socket";
import "./edit-chapters.scss";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ChapterItem from "./chapterItem";

function EditChapters({ chapters, setChapters, isExpanded, setIsExpanded }) {
  const socketContext = useContext(SocketContext);
  const [style, setStyle] = useState({});
  const containerRef = useRef(null);

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const [addingNew, setAddingNew] = useState(false);
  const [newChapterName, setNewChapterName] = useState("");

  const openCard = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isExpanded) {
      const rect = containerRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;
      let newPositionStyle = {
        top: window.innerHeight * 0.1 - topPosition + "px",
        left: window.innerWidth * 0.25 - leftPosition + "px",
        width: "50vw",
        height: "80vh",
      };
      if (window.innerWidth < 800) {
        newPositionStyle = {
          top: window.innerHeight * 0.1 - topPosition + "px",
          left: window.innerWidth * 0.05 - leftPosition + "px",
          width: "90vw",
          height: "80vh",
        };
      }

      setStyle(newPositionStyle);
    } else {
      setStyle({});
    }
    if (window.innerWidth < 700) {
      if (isExpanded) {
        document.getElementById("main-app").style.overflowY = "hidden";
      } else {
        document.getElementById("main-app").style.overflowY = "auto";
      }
    } else {
      if (isExpanded) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isExpanded]);

  const populateChapters = () => {};

  const addNewChapter = () => {
    const newOrder = chapters.length
      ? Math.max(...chapters.map((g) => g.order_value)) + 1
      : 1;

    if (newChapterName) {
      const body = {
        chapter_name: newChapterName,
        order: newOrder,
        class_id: sideFiltersClassId,
      };
      socketContext.socket.emit("addChapter", body);
      setNewChapterName("");
      setAddingNew(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addNewChapter();
    }
  };

  const updateChapterOrder = (chapterId, order) => {
    const body = {
      chapter_id: chapterId,
      order: order,
    };
    socketContext.socket.emit("updateChapterOrder", body);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(chapters);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);

    newItems.forEach((item, index) => {
      item.order = index + 1;
      updateChapterOrder(item.id, item.order);
    });

    setChapters(newItems);
  };

  return (
    <div
      ref={containerRef}
      className={
        "edit-chapters " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      {isExpanded && (
        <div
          onClick={() => setIsExpanded(false)}
          className={"modal-background show"}
        ></div>
      )}
      <motion.div
        initial={false}
        className={
          "edit-chapters__content " +
          (isExpanded ? "is-expanded" : "is-collapsed")
        }
        onClick={openCard}
        style={style}
      >
        {isExpanded ? (
          <div
            className="edit-chapters__config no-scrollbar"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="edit-chapters__config-title">
              <span>Κεφάλαια</span>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="chapters">
                {(provided) => (
                  <div
                    className="chapters__list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {chapters.map((chapter, index) => (
                      <Draggable
                        key={chapter.id}
                        draggableId={chapter.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              cursor: "pointer",
                            }}
                          >
                            <ChapterItem chapter={chapter} key={index} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="add-item" onClick={() => setAddingNew(true)}>
              <BiPlus
                size={"35px"}
                color={"#949494"}
                style={iconStyle("transparent")}
              />
              <span className="label">Προσθέστε νέο κεφάλαιο</span>
            </div>
            {addingNew ? (
              <div className="new-item">
                <div className="new-item__info">
                  <span className="label">Όνομα νέου κεφαλαίου</span>
                  <input
                    className="input"
                    placeholder="'Ονομασία κεφαλαίου"
                    value={newChapterName}
                    onChange={(e) => setNewChapterName(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="new-item__actions">
                  {newChapterName ? (
                    <BiCheck
                      onClick={() => addNewChapter()}
                      size={"30px"}
                      color={"#6225e6"}
                      style={iconStyle("transparent")}
                    />
                  ) : (
                    ""
                  )}
                  <BiX
                    onClick={() => {
                      setAddingNew(false);
                      setNewChapterName("");
                    }}
                    size={"30px"}
                    color={"#ff6f6f"}
                    style={iconStyle("transparent")}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className="edit-chapters__config-close"
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#cccccc"}
                style={iconStyle("#f2f2f2")}
              />
            </div>
          </div>
        ) : (
          <div className="edit-chapters__preview">
            <BiPencil
              size={"25px"}
              color={"#cccccc"}
              style={iconStyle("transparent")}
            />
          </div>
        )}
      </motion.div>
    </div>
  );
}

export default EditChapters;
