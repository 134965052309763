import React, { createContext, useState, useContext, useEffect } from "react";

// Step 1: Create the context
export const EventContext = createContext();

// Step 2: Create a provider component
export const EventProvider = ({
  type,
  children,
  title,
  classId,
  departments,
  professors,
  startDate,
  endDate,
  classroom,
  classroomName,
  repeatType,
  maxRank,
  detailedRank,
  id,
  duration,
  eventOnlineGrades,
  examId,
  anId,
  anTitle,
  anClsId,
  anGrdId,
  anForStudents,
  anForProfessors,
  anForParents,
}) => {
  const eventId = id ? id : "";
  const [announcementTitle, setAnnouncementTitle] = useState(
    anTitle ? anTitle : ""
  );
  const [announcementId, setAnnouncementId] = useState(anId ? anId : "");
  const [forProfessors, setForProfessors] = useState(
    anForProfessors ? anForProfessors : ""
  );
  const [forParents, setForParents] = useState(
    anForParents ? anForParents : ""
  );
  const [forStudents, setForStudents] = useState(
    anForStudents ? anForStudents : ""
  );
  const [eventType, setEventType] = useState(type ? type : "");
  const [eventTitle, setEventTitle] = useState(title ? title : "");
  const [eventClass, setEventClass] = useState(classId ? classId : -1);
  const [anClass, setAnClass] = useState(anClsId ? anClsId : [-1]);
  const [anGrade, setAnGrade] = useState(anGrdId ? anGrdId : [-1]);
  const [eventDepartments, setEventDepartments] = useState(
    departments ? JSON.parse(departments) : []
  );

  const [eventProfessors, setEventProfessors] = useState(
    professors ? JSON.parse(professors) : []
  );

  const [eventStartDate, setEventStartDate] = useState(
    startDate ? startDate : new Date()
  );
  const [eventEndDate, setEventEndDate] = useState(
    endDate ? endDate : new Date()
  );
  const [eventClassrooms, setEventClassrooms] = useState(
    classroom ? JSON.parse(classroom) : []
  );
  const [eventClassroomName, setEventClassRoomName] = useState(
    classroomName ? classroomName : ""
  );
  const [eventRepeatType, setEventRepeatType] = useState(
    repeatType ? repeatType : ""
  );
  const [eventDuration, setEventDuration] = useState(duration ? duration : "");
  const [eventMaxRank, setEventMaxRank] = useState(maxRank ? maxRank : 100);
  const [eventDetailedRank, setEventDetailedRank] = useState(
    detailedRank ? JSON.parse(detailedRank) : [0]
  );
  const [testForErrors, setTestForErrors] = useState(false);
  const [noErrors, setNoErrors] = useState(false);

  const [finalTestForErrors, setFinalTestForErrors] = useState(false);
  const [noFinalErrors, setNoFinalErrors] = useState("testing");
  const [errorMsg, setErrorMsg] = useState("");

  const [eventExamId, setEventExamId] = useState(examId ? examId : "");

  const [saveRichText, setSaveRichText] = useState(false);
  const [saveAnnouncementText, setSaveAnnouncementText] = useState(false);
  const [onlineGrades, setOnlineGrades] = useState([
    { id: Date.now(), description: "", saved: false, rank: 0 },
  ]);

  useEffect(() => {
    if (eventOnlineGrades) {
      try {
        const rank = JSON.parse(detailedRank);
        const temp = JSON.parse(decodeURIComponent(eventOnlineGrades));
        const tempGrades = [];
        temp.map((description, index) => {
          tempGrades.push({
            id: Date.now() + index,
            description: description,
            saved: false,
            rank: rank[index],
          });
        });
        console.log(tempGrades);
        setOnlineGrades(tempGrades);
      } catch (e) {}
    }
  }, []);

  return (
    <EventContext.Provider
      value={{
        eventType,
        setEventType,
        eventTitle,
        setEventTitle,
        eventClass,
        setEventClass,
        eventDepartments,
        setEventDepartments,
        eventProfessors,
        setEventProfessors,
        eventStartDate,
        setEventStartDate,
        eventEndDate,
        setEventEndDate,
        eventClassrooms,
        setEventClassrooms,
        eventClassroomName,
        setEventClassRoomName,
        eventRepeatType,
        setEventRepeatType,
        eventMaxRank,
        setEventMaxRank,
        eventDetailedRank,
        setEventDetailedRank,
        testForErrors,
        setTestForErrors,
        noErrors,
        setNoErrors,
        eventId,
        finalTestForErrors,
        setFinalTestForErrors,
        noFinalErrors,
        setNoFinalErrors,
        errorMsg,
        setErrorMsg,
        onlineGrades,
        setOnlineGrades,
        saveRichText,
        setSaveRichText,
        saveAnnouncementText,
        setSaveAnnouncementText,
        eventDuration,
        setEventDuration,
        eventExamId,
        setEventExamId,
        announcementTitle,
        setAnnouncementTitle,
        forParents,
        setForParents,
        forProfessors,
        setForProfessors,
        forStudents,
        setForStudents,
        announcementId,
        setAnnouncementId,
        anClass,
        setAnClass,
        anGrade,
        setAnGrade,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

// Step 3: Create a custom hook for easier consumption of the context
export const useEventContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEventContext must be used within an EventProvider");
  }
  return context;
};
