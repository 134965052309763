import { configureStore } from '@reduxjs/toolkit'
import questionsReducer from "../components/ClassesNav/questionsSlice";
import navbarReducer from "../components/Navbar/navbarSlice";
import studentAnswersReducer from "../pages/Quiz-student/quiz-student-slice"
import classesReducer from '../components/SideFilters/classNamesSlice';
import progressBarReducer from "../components/ProgressBar/progressBarSlice";
import chaptersReducer from '../components/SideFilters/chaptersSlice';
import topFilterReducer from '../components/SideFilters/topFilterSlice';

export default configureStore({
    reducer: {
        questions: questionsReducer,
        profile: navbarReducer,
        studentAnswers: studentAnswersReducer,
        // classNames: classNamesReducer,
        classes: classesReducer,
        chapters: chaptersReducer,
        topFilter: topFilterReducer,
        progressBar: progressBarReducer,
    },
})