import {cookieUtils} from "./cookie-parser";

function getToken () {
    return cookieUtils.getCookie('auth');
}

function getBearerToken () {
    return getToken() ? 'Bearer ' + JSON.parse(getToken()).token : null;
}

export const tokenUtils = {getToken, getBearerToken}