import React, { useEffect } from "react";
import { Outlet } from "react-router";

const WithoutNav = () => {
  useEffect(() => {
    const messageListener = window.addEventListener("message", (event) => {
      if (event.message === "SET_COOKIE") {
        const cookie = event.data;
        document.cookie = `auth=${cookie}; path=/`;
        window.location.href = "/";
      }
    });
    return messageListener;
  }, []);

  return <Outlet />;
};
export default WithoutNav;
