import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BiPlus,
  BiX,
  BiMinus,
  BiTrash,
  BiPencil,
  BiSearch,
  BiAbacus,
  BiCheck,
  // BiInfoCircle,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { SocketContext } from "../../../app/socket";
import { getGeneralSettingValue, iconStyle } from "../../../utils/generalUtils";

function DepartmentItem({
  department,
  selectedDepartment,
  setSelectedDepartment,
  classTuition,
}) {
  const socketContext = useContext(SocketContext);
  const generalSettings = useSelector((state) => state.profile.generalSettings);

  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [newDepName, setNewDepName] = useState(department.department_name);

  const handleDepartmentClick = (event) => {
    if (!editMode && !deleteMode) {
      setSelectedDepartment(department.department_id);
    }
  };

  const handleActionsClick = (event) => {
    event.stopPropagation();
  };

  const deleteDepartment = () => {
    const body = {
      department_id: department.department_id,
      add_tuition: getGeneralSettingValue(generalSettings, "tuition_for_class"),
      steroid_tuition: getGeneralSettingValue(
        generalSettings,
        "tuition_on_steroids"
      ),
      class_tuition: classTuition,
    };
    setSelectedDepartment(-1);

    socketContext.socket.emit("deleteDepartment", body);
  };

  const editDepartment = () => {
    const body = {
      department_id: department.department_id,
      department_name: newDepName,
    };

    socketContext.socket.emit("editDepartmentName", body);
    setEditMode(false);
  };

  return (
    <div
      className={
        "users__departments-list-item " +
        (selectedDepartment === department.department_id ? "active" : "")
      }
      onClick={handleDepartmentClick}
    >
      {editMode ? (
        <input
          className="input"
          value={newDepName}
          onChange={(e) => setNewDepName(e.target.value)}
        />
      ) : (
        <span className="label">{department.department_name}</span>
      )}
      <div className="department__actions" onClick={handleActionsClick}>
        {!editMode && !deleteMode ? (
          <div className="department__actions-list">
            <BiPencil
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f8f8f8")}
              onClick={() => setEditMode(true)}
            />
            <BiMinus
              size={"35px"}
              color={"#fff"}
              onClick={() => setDeleteMode(true)}
              style={iconStyle("#ff6f6f")}
            />
          </div>
        ) : (
          ""
        )}
        {deleteMode ? (
          <div className="department__delete">
            <BiCheck
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f8f8f8")}
              onClick={() => deleteDepartment()}
            />
            <BiX
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f8f8f8")}
              onClick={() => setDeleteMode(false)}
            />
          </div>
        ) : (
          ""
        )}
        {editMode ? (
          <div className="department__edit">
            <BiX
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f8f8f8")}
              onClick={() => setEditMode(false)}
            />
            <BiCheck
              size={"35px"}
              color={"#cccccc"}
              style={iconStyle("#f8f8f8")}
              onClick={() => editDepartment()}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default DepartmentItem;
