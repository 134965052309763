import React, { useState, useEffect } from "react";

function DepartmentItem({
  department,
  selectDepartments,
  setSelectedDepartments,
}) {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (!selectDepartments.length) {
      setSelected(false);
    } else {
      if (selectDepartments.find((dep) => dep == department.department_id)) {
        setSelected(true);
      }
    }
  }, [selectDepartments]);

  const selectDepartment = () => {
    setSelected(!selected);
    const departmentId = department.department_id;
    if (!selectDepartments.length) {
      setSelectedDepartments([departmentId]);
    } else {
      setSelectedDepartments((prevDepartments) => {
        if (prevDepartments.includes(departmentId)) {
          return prevDepartments.filter((id) => id !== departmentId);
        } else {
          return [...prevDepartments, departmentId];
        }
      });
    }
  };

  return (
    <div
      key={"itemDep " + department.department_id}
      className={"item " + (selected ? "selected" : "")}
      onClick={() => selectDepartment()}
    >
      <span className="title">{department.department_name}</span>
    </div>
  );
}

export default DepartmentItem;
