import React, { useState, useEffect, useContext } from "react";
import { BiPlus, BiArrowToBottom } from "react-icons/bi";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { SocketContext } from "../../app/socket";
import { iconStyle } from "../../utils/generalUtils";

function StudentAnswerItem({ answer }) {
  const [userData, setUserData] = useState([]);
  const socketContext = useContext(SocketContext);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    const getUsersListener = (data) => {
      if (data.length > 0) {
        setUserData(data[0]);
      } else {
        setUserData([]);
      }
    };

    // Add the event listener when the component mounts
    socketContext.socket.on(
      "allUsersWithParams" + answer.user_id,
      getUsersListener
    );

    // Emit the event to get user data
    const args = { user_id: answer.user_id };
    socketContext.socket.emit("getAllUsersWithParams", args);

    // Add the refresh event listener to update user data
    const refreshAllUsersWithParamsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshAllUsersWithParamsListener
    );

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off(
        "allUsersWithParams" + answer.user_id,
        getUsersListener
      );
      socketContext.socket.off("getAllUsersWithParams", getUsersListener);
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshAllUsersWithParamsListener
      );
    };
  };

  const downloadFiles = async () => {
    var zip = new JSZip();

    const images = JSON.parse(answer.files);

    const files = images[0];

    for (let i = 0; i < files.length; i++) {
      const url = files[i];
      const response = await fetch(url);

      // check if the response is ok before proceeding
      if (!response.ok) {
        throw new Error(`Error while fetching ${url}: ${response.statusText}`);
      }
      const fileData = await response.arrayBuffer();

      // use the file name from the URL, or fallback to file0, file1, etc. if there is none
      const fileName = url.split("/").pop() || `file${i}`;
      zip.file(fileName, fileData);
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "example.zip");
    });
  };

  const downloadSingleFile = async (file) => {
    var zip = new JSZip();

    const url = file;
    const response = await fetch(url);

    // check if the response is ok before proceeding
    if (!response.ok) {
      throw new Error(`Error while fetching ${url}: ${response.statusText}`);
    }
    const fileData = await response.arrayBuffer();

    // use the file name from the URL, or fallback to file0, file1, etc. if there is none
    const fileName = url.split("/").pop() || "";
    zip.file(fileName, fileData);

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "example.zip");
    });
  };

  const getFormatedDate = (sqlTimestamp) => {
    const date = new Date(sqlTimestamp);

    const formattedDate = `${date.getUTCDate().toString().padStart(2, "0")}/${(
      date.getUTCMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getUTCFullYear()} - ${date
      .getUTCHours()
      .toString()
      .padStart(2, "0")}:${date.getUTCMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div className="item">
      <div className="header">
        <div className="user-info">
          <div className="profile-img">
            {userData && (
              <img
                alt="profile student professor"
                className="profile-image__img"
                src={
                  userData.profile_picture
                    ? userData.profile_picture
                    : "resources/student.png"
                }
              />
            )}
          </div>
          <div className="details">
            <span className="last-name">{userData.last_name}</span>
            <span className="first-name">{userData.first_name}</span>
          </div>
        </div>
        <div className="time">
          <span>{getFormatedDate(answer.upload_time)}</span>
        </div>
      </div>
      <div className="files-list">
        <div className="files">
          {JSON.parse(answer.files).map((images, index) => {
            return images.map((image, imageIndex) => {
              if (imageIndex < 5) {
                const lastIndex = image.lastIndexOf("/");
                const imageName = image.substring(lastIndex + 1);
                return (
                  <div
                    key={imageIndex}
                    className="assets__item"
                    title={imageName}
                    onClick={() => downloadSingleFile(image)}
                  >
                    <div className="assets__item-overlay">
                      <BiArrowToBottom
                        size={"45px"}
                        color={"#6225E6"}
                        style={iconStyle("transparent")}
                      />
                    </div>
                    {image.substring(image.lastIndexOf(".") + 1) === "pdf" && (
                      <img
                        alt="pdf document edupal"
                        className="card__info-submitted-img"
                        src="../../../resources/icons/pdf-icon.png"
                      />
                    )}
                    {image.substring(image.lastIndexOf(".") + 1) !== "pdf" && (
                      <img
                        alt="zoom edupal"
                        className="card__info-submitted-img"
                        src="../../../resources/icons/image-icon.png"
                      />
                    )}
                  </div>
                );
              } else if (imageIndex === 5) {
                return (
                  <BiPlus
                    size={"60px"}
                    color={"#cccccc"}
                    style={iconStyle("#f2f2f2")}
                  />
                );
              }
            });
          })}
        </div>
        <div className="files-download">
          <button className="cta-link" onClick={() => downloadFiles()}>
            Λήψη όλων σε zip.
          </button>
        </div>
      </div>
    </div>
  );
}

export default StudentAnswerItem;
