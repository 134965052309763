import "./profile-skeleton.scss";
import React from "react";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function ProfileSkeleton() {
  return (
    <div className="profile-skeleton">
      <div className="left">
        <LoaderCard cardSum={1} width={100} size="large" height="xxl" />
        <LoaderCard cardSum={1} width={100} size="large" />
      </div>
      <div className="right">
        <LoaderCard cardSum={1} width={20} size="large" height="small" />
        <LoaderCard cardSum={4} width={20} size="large" height="small" />
        <LoaderCard cardSum={1} width={20} size="large" height="small" />
        <LoaderCard cardSum={4} width={20} size="large" />
        <LoaderCard cardSum={1} width={20} size="large" height="small" />
        <LoaderCard cardSum={3} width={30} size="large" height="small" />
        <LoaderCard cardSum={1} width={100} size="large" height="xl" />
      </div>
    </div>
  );
}

export default ProfileSkeleton;
