import React, { useContext, useEffect, useState } from "react";
import { motion, usePresence } from "framer-motion";
import { CircularProgressbar } from "react-circular-progressbar";
import { SocketContext } from "../../app/socket";
import { BiMinus, BiX } from "react-icons/bi";
import { iconStyle } from "../../utils/generalUtils";

function MarkedUser({
  student,
  score,
  examId,
  comment,
  maxRank,
  studentDetailedScore,
  examDetailedRank,
}) {
  const socketContext = useContext(SocketContext);
  const [isPresent, safeToRemove] = usePresence();
  const [isExpanded, setIsExpanded] = useState(false);

  const [detailedScore, setDetailedScore] = useState([]);
  const [detailedRank, setDetailedRank] = useState([]);

  useEffect(() => {
    try {
      setDetailedScore(JSON.parse(studentDetailedScore));
      setDetailedRank(JSON.parse(examDetailedRank));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const transition = {
    type: "spring",
    stiffness: 500,
    damping: 70,
    mass: 5,
    duration: 2,
  };

  const animations = {
    layout: true,
    initial: "in",
    style: {
      position: isPresent ? "relative" : "absolute",
    },
    animate: isPresent ? "in" : "out",
    whileTap: "tapped",
    variants: {
      in: { scale: 1, opacity: 1 },
      out: { scale: 0, opacity: 0, zIndex: -1 },
    },
    whileHover: { scale: 1.05 },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  };

  const removeScore = () => {
    const body = {
      exam_id: examId,
      user_id: student.user_id,
    };
    socketContext.socket.emit("removeExamScore", body);
  };

  const handleContainerClick = (e) => {
    // Prevent toggle when clicking on the BiMinus icon
    if (e.target.closest(".marked-users__item-delete")) return;
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      {isExpanded ? (
        <div
          onClick={() => {
            setIsExpanded(false);
          }}
          className={"modal-background show "}
        ></div>
      ) : (
        ""
      )}
      <motion.div
        {...animations}
        className="marked-users__item"
        onClick={handleContainerClick}
      >
        <div className="marked-users__item-info">
          <div className="marked-users__item-info-img">
            <img
              alt=""
              src={
                student.profile_picture
                  ? student.profile_picture
                  : "resources/student.png"
              }
            />
          </div>
          <div className="marked-users__item-info-details">
            <div className="name">
              <span>{student.first_name}</span>
              <span>{student.last_name}</span>
            </div>
            <div className="department">
              <span>{student.department_name}</span>
            </div>
            {comment ? (
              <div className="comment">
                <span>{comment}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={
            "marked-users__item-delete " + (!score ? "not_scored" : "")
          }
        >
          <BiMinus
            size={"30px"}
            color={"#fff"}
            style={iconStyle("#FF6D6D")}
            onClick={removeScore}
          />
        </div>
        {score ? (
          <div className="marked-users__item-score">
            <CircularProgressbar
              pathColor="{red}"
              strokeWidth={9}
              className={"marked-users__item-input-score-circle"}
              value={(score * 100) / maxRank}
              duration={1.4}
              text=""
            />
            <span>{score}</span>
          </div>
        ) : (
          <div className="marked-users__item-not_scored">
            <span>Αβαθμολόγητο</span>
          </div>
        )}
      </motion.div>
      {isExpanded && (
        <div className="expanded-container">
          <div className="expanded-container__close">
            <BiX
              size={"35px"}
              color={"#ccc"}
              style={iconStyle("#f8f8f8")}
              onClick={() => setIsExpanded(false)}
            />
          </div>
          <div className="expanded-container__profile">
            <div className="profile-img">
              <img
                alt=""
                src={
                  student.profile_picture
                    ? student.profile_picture
                    : "resources/student.png"
                }
              />
            </div>
            <div className="name">
              <span>{student.last_name}</span>
              <span>{student.first_name}</span>
            </div>
          </div>
          <div className="expanded-container__score">
            <div className="total-score">
              <div className="total-score__circle">
                <CircularProgressbar
                  pathColor="{red}"
                  strokeWidth={7}
                  value={(score * 100) / maxRank}
                  duration={1.4}
                  text=""
                />
                <span className="score-label">
                  {score} / {maxRank}
                </span>
              </div>
              <div className="total-score__comments">
                <span className="comment-label">Σχόλια Καθηγητή:</span>
                <span className="comment-title">{comment}</span>
              </div>
            </div>
            <div className="detailed-score">
              <div className="detailed-score__list">
                {detailedScore.map((score, index) => {
                  return (
                    <div
                      key={"studentscore" + index}
                      className="detailed-score__list-item"
                    >
                      <span className="sub-label">Θέμα {index + 1}</span>
                      <CircularProgressbar
                        pathColor="{red}"
                        strokeWidth={7}
                        value={
                          (detailedScore[index] * 100) / detailedRank[index]
                        }
                        duration={1.4}
                        text={
                          detailedScore[index] + " / " + detailedRank[index]
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MarkedUser;
