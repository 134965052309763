import React, { createContext, useState, useContext } from "react";

// Step 1: Create the context
export const EventContext = createContext();

// Step 2: Create a provider component
export const HomepageProvider = ({
  type,
  children,
  title,
  classId,
  departments,
  professors,
  startDate,
  endDate,
  classroom,
  classroomName,
  repeatType,
  maxRank,
  detailedRank,
  id,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const eventId = id ? id : "";
  const [eventType, setEventType] = useState(type ? type : "");
  const [eventTitle, setEventTitle] = useState(title ? title : "");
  const [eventClass, setEventClass] = useState(classId ? classId : -1);
  const [eventDepartments, setEventDepartments] = useState(
    departments ? JSON.parse(departments) : []
  );

  const [eventProfessors, setEventProfessors] = useState(
    professors ? JSON.parse(professors) : []
  );

  const [eventStartDate, setEventStartDate] = useState(
    startDate ? startDate : new Date()
  );
  const [eventEndDate, setEventEndDate] = useState(
    endDate ? endDate : new Date()
  );
  const [eventClassrooms, setEventClassrooms] = useState(
    classroom ? JSON.parse(classroom) : []
  );
  const [eventClassroomName, setEventClassRoomName] = useState(
    classroomName ? classroomName : ""
  );
  const [eventRepeatType, setEventRepeatType] = useState(
    repeatType ? repeatType : ""
  );
  const [eventMaxRank, setEventMaxRank] = useState(maxRank ? maxRank : 100);
  const [eventDetailedRank, setEventDetailedRank] = useState(
    detailedRank ? JSON.parse(detailedRank) : [0]
  );
  const [testForErrors, setTestForErrors] = useState(false);
  const [noErrors, setNoErrors] = useState(false);

  return (
    <EventContext.Provider
      value={{
        eventType,
        setEventType,
        eventTitle,
        setEventTitle,
        eventClass,
        setEventClass,
        eventDepartments,
        setEventDepartments,
        eventProfessors,
        setEventProfessors,
        eventStartDate,
        setEventStartDate,
        eventEndDate,
        setEventEndDate,
        eventClassrooms,
        setEventClassrooms,
        eventClassroomName,
        setEventClassRoomName,
        eventRepeatType,
        setEventRepeatType,
        eventMaxRank,
        setEventMaxRank,
        eventDetailedRank,
        setEventDetailedRank,
        testForErrors,
        setTestForErrors,
        noErrors,
        setNoErrors,
        eventId,
        modalOpen,
        setModalOpen,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

// Step 3: Create a custom hook for easier consumption of the context
export const useHomepageContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error(
      "useHomepageContext must be used within an HomepageProvider"
    );
  }
  return context;
};
