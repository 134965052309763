import React, { Suspense, lazy, useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login/login";
import Homepage from "./pages/Homepage/homepage";
import WithNav from "./pages/WithNav/withNav";
import WithoutNav from "./pages/WithoutNav/withoutNav";
import NotFound from "./pages/NotFound/not-found";
import ProgressBar from "./components/ProgressBar/progressBar";
import "./scss/main.scss";
import { getSkeleton } from "./utils/skeletonUtils";
import QuizStudent from "./pages/Quiz-student/quiz-student";
import Curriculum from "./pages/Curriculum/curriculum";
import Dashboard from "./pages/Dashboard/dashboard";
import Users from "./pages/Users/users";
import Exams from "./pages/Exams/exams";
import FullSizeImg from "./components/FullSizeImg/fullSizeImg";
import { cookieUtils } from "./utils/cookie-parser";

const CalendarBig = lazy(() => import("./components/CalendarBig/calendarBig"));
const QuizAdmin = lazy(() => import("./pages/Quiz-admin/quiz-admin"));
const QuizResults = lazy(() => import("./pages/QuizResults/quiz-results"));
const Settings = lazy(() => import("./pages/Settings/settings"));
const Profile = lazy(() => import("./pages/Profile/profile"));
const ChatUI = lazy(() => import("./pages/Chat/chat"));
const NotificationPage = lazy(() =>
  import("./pages/NotificationPage/notificationPage")
);
const Exam = lazy(() => import("./pages/Exam/exam"));
const Results = lazy(() => import("./pages/Results/results"));
const ExamsSubmitted = lazy(() =>
  import("./pages/ExamsSubmitted/examsSubmited")
);
const NewExam = lazy(() => import("./components/NewExam/newExam"));
const CheckIn = lazy(() => import("./pages/CheckIn/checkIn"));

function App() {
  const [fullImgSrc, setFullImgSrc] = useState("");
  const [showFullImg, setShowFullImg] = useState(false);
  let imageClicked = false;

  const imageClickListener = () => {
    let examTitles = document.getElementsByClassName("image__open__full");
    document.addEventListener("click", function (e) {
      for (let i = 0; i < examTitles.length; i++) {
        if (examTitles[i].contains(e.target)) {
          if (e.target.tagName === "IMG") {
            imageClicked = true;
            showImageFull(e.target.src);
          }
        }
      }
    });
  };

  const showImageFull = (image) => {
    setFullImgSrc(image);
    setShowFullImg(true);
  };

  imageClickListener();

  return (
    <div className="App" id="main-app">
      <ProgressBar />
      {showFullImg ? (
        <FullSizeImg
          imgSrc={fullImgSrc}
          closeOverlay={() => {
            setShowFullImg(false);
            imageClicked = false;
          }}
        />
      ) : (
        ""
      )}
      <Router>
        <MainRouter />
      </Router>
    </div>
  );
}

function MainRouter() {
  const location = useLocation();

  useEffect(() => {
    const updateTitleAndFavicon = () => {
      const url = window.location.href;
      const favicon = document.getElementById("favicon");
      document.title = "edupal.";
      favicon.href = "/favicon.png";
      if (url && url.includes("lingopowers")) {
        document.title = "Lingopowers";
        favicon.href = "/lingopowers_favicon.png"; // Ensure this file exists in the public folder
      }
    };

    updateTitleAndFavicon();
  }, [location]);

  return (
    <Suspense fallback={getSkeleton()}>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<WithNav />}>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/calendar" element={<CalendarBig />} />
          <Route exact path="/quiz-admin" element={<QuizAdmin />} />
          <Route exact path="/quiz-results" element={<QuizResults />} />
          <Route exact path="/quiz" element={<QuizStudent />} />
          <Route exact path="/curriculum" element={<Curriculum />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/chat" element={<ChatUI />} />
          <Route exact path="/notifications" element={<NotificationPage />} />
          <Route exact path="/exams" element={<Exams />} />
          <Route exact path="/exam" element={<Exam />} />
          <Route exact path="/exam-results" element={<Results />} />
          <Route exact path="/exams-submitted" element={<ExamsSubmitted />} />
          <Route exact path="/new-exam" element={<NewExam />} />
          <Route exact path="/check-in" element={<CheckIn />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
