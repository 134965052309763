import { ProgressBar } from "react-bootstrap";
import WithNav from "../pages/WithNav/withNav";
import QuizSkeleton from "./skeletons/quizSkeleton/quiz-skeleton";
import UsersSkeleton from "./skeletons/usersSkeleton/users-skeleton";
import ExamsSkeleton from "./skeletons/examsSkeleton/exams-skeleton";
import ProfileSkeleton from "./skeletons/profileSkeleton/profile-skeleton";
import HomepageSkeleton from "./skeletons/homepageSkeleton/homepageSkeleton";
import CurriculumSkeleton from "./skeletons/curriculumSkeleton/curriculum-skeleton";
import StatisticsSkeleton from "./skeletons/statisticsSkeleton/statistics-skeleton";

export const getSkeleton = () => {
  const url = window.location.pathname;

  if (url === "/login") {
    return null;
  }

  if (url === "/users") {
    return (
      <>
        <WithNav />
        <UsersSkeleton />
      </>
    );
  } else if (url === "/exams") {
    return (
      <>
        <WithNav />
        <ExamsSkeleton />
      </>
    );
  } else if (url === "/curriculum") {
    return (
      <>
        <WithNav />
        <CurriculumSkeleton />
      </>
    );
  } else if (url === "/quiz") {
    return (
      <>
        <WithNav />
        <QuizSkeleton />
      </>
    );
  } else if (url === "/dashboard") {
    return (
      <>
        <WithNav />
        <StatisticsSkeleton />
      </>
    );
  } else if (url === "/profile") {
    return (
      <>
        <WithNav />
        <ProfileSkeleton />
      </>
    );
  } else if (url !== "/") {
    return (
      <>
        <WithNav />
        <ProgressBar force />
      </>
    );
  } else if (url === "/") {
    return (
      <>
        <WithNav />
        <HomepageSkeleton />
      </>
    );
  } else {
    return <ProgressBar force />;
  }
};
