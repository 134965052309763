import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiChevronRight,
  BiDesktop,
  BiDockBottom,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import ImageSlider from "../../../../components/ImageSlider/imageSlider";
import ProfessorComment from "./professorComment";
import DropDownMenu from "../../../../components/DropDownMenu/dropDownMenu";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

function CurriculumAnswerItem({ curriculumAnswer }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const [studentData, setStudentData] = useState();
  const [imageIsOpen, setImageIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState();

  const [studentFiles, setStudentFiles] = useState([]);

  const [professorComments, setProfessorComments] = useState([]);
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    console.log(curriculumAnswer);
    try {
      const temp = [];
      const tempFiles = JSON.parse(curriculumAnswer.files);
      tempFiles.map((file) => {
        file.map((fileItem) => {
          temp.push(fileItem);
        });
      });
      setStudentFiles(temp);
    } catch (e) {
      console.log(e);
    }
    const cleanUpGetStudentData = getStudentData();
    const cleanUpGetCurriculumProfessorComments =
      getCurriculumProfessorComments();
    return () => {
      cleanUpGetStudentData();
      if (profile.user_type != "student") {
        cleanUpGetCurriculumProfessorComments();
      }
    };
  }, []);

  const getCurriculumProfessorComments = () => {
    const args = { curriculumAnswerId: curriculumAnswer.curriculum_answers_id };

    const getCurriculumProfessorCommentsListener = (data) => {
      setProfessorComments(data);
    };

    const refreshCurriculumProfessorCommentsListener = () => {
      socketContext.socket.emit("getCurriculumProfessorComments", args);
    };

    socketContext.socket.on(
      "curriculumProfessorComments" + curriculumAnswer.curriculum_answers_id,
      getCurriculumProfessorCommentsListener
    );
    socketContext.socket.emit("getCurriculumProfessorComments", args);
    socketContext.socket.on(
      "refreshCurriculumProfessorComments" +
        curriculumAnswer.curriculum_answers_id,
      refreshCurriculumProfessorCommentsListener
    );

    return () => {
      socketContext.socket.off(
        "getCurriculumProfessorComments",
        getCurriculumProfessorCommentsListener
      );
      socketContext.socket.off(
        "curriculumProfessorComments" + curriculumAnswer.curriculum_answers_id,
        getCurriculumProfessorCommentsListener
      );
      socketContext.socket.off(
        "refreshCurriculumProfessorComments" +
          curriculumAnswer.curriculum_answers_id,
        refreshCurriculumProfessorCommentsListener
      );
    };
  };

  const getStudentData = () => {
    const args = { user_id: curriculumAnswer.user_id };

    const getUsersListener = (data) => {
      if (data.length > 0) {
        setStudentData(data[0]);
      } else {
        setStudentData([]);
      }
    };

    socketContext.socket.on(
      "userData" + curriculumAnswer.user_id,
      getUsersListener
    );

    socketContext.socket.emit("getUserData", args);

    const refreshUserData = () => {
      socketContext.socket.emit("getUserData", args);
    };
    socketContext.socket.on("refreshUserData", refreshUserData);

    return () => {
      socketContext.socket.off(
        "userData" + curriculumAnswer.user_id,
        getUsersListener
      );
      socketContext.socket.off("getUserData", getUsersListener);
      socketContext.socket.off("refreshUserData", refreshUserData);
    };
  };

  const openFile = (image) => {
    setImageIsOpen(true);
    setActiveImage(image);
  };

  const populateStudentFiles = () => {
    return studentFiles.map((image, index) => {
      const lastIndex = image.lastIndexOf("/");
      const imageName = image.substring(lastIndex + 1);
      const fileExtension = image.substring(image.lastIndexOf(".") + 1);
      return (
        <div
          key={"asset__item" + index}
          className={
            "content__files-item " + (fileExtension !== "pdf" ? "img" : "pdf")
          }
          onClick={() => openFile(image)}
          title={imageName}
        >
          {image.substring(image.lastIndexOf(".") + 1) === "pdf" && (
            <img
              alt="pdf document edupal"
              className="card__info-submitted-img"
              src="../../../resources/icons/pdf-icon.png"
            />
          )}
          {image.substring(image.lastIndexOf(".") + 1) !== "pdf" && (
            <img className="card__info-submitted-img img" src={image} />
          )}
        </div>
      );
    });
  };

  const submitComment = () => {
    const tempComment = [
      {
        id: -1,
        comment_text: commentText,
      },
    ];
    setProfessorComments(tempComment);
    if (commentText) {
      const eventBody = {
        comment_text: commentText,
        curriculumAnswerId: curriculumAnswer.curriculum_answers_id,
      };
      socketContext.socket.emit("uploadCurriculumProfessorComment", eventBody);
      setCommentText("");
    }
  };

  const populateComments = () => {
    return professorComments.map((comment, index) => {
      return (
        <ProfessorComment
          key={"profComment " + index}
          comment={comment}
          curriculumAnswer={curriculumAnswer}
        />
      );
    });
  };

  const deleteAnswer = () => {
    let body = {
      curriculum_answer_id: curriculumAnswer.curriculum_answers_id,
      curriculum_id: curriculumAnswer.curriculum_id,
    };

    socketContext.socket.emit("deleteCurriculumAnswer", body);
  };

  return (
    <div className="curriculum-answers__list-item">
      {imageIsOpen ? (
        <div
          onClick={() => setImageIsOpen(false)}
          className={"modal-background show z-index"}
        ></div>
      ) : (
        ""
      )}
      {imageIsOpen ? (
        <ImageSlider assets={studentFiles} activeAsset={activeImage} />
      ) : (
        ""
      )}
      {studentData ? (
        <div to={"/profile?user-id=" + studentData.user_id} className="header">
          <Link
            to={"/profile?user-id=" + studentData.user_id}
            className="header__image"
          >
            <img
              alt="profile student professor"
              src={
                studentData.profile_picture
                  ? studentData.profile_picture
                  : "resources/student.png"
              }
            />
          </Link>
          <Link
            to={"/profile?user-id=" + studentData.user_id}
            className="header__info"
          >
            <span className="header__info-name">
              <span>{studentData.first_name}</span>
              <span>{studentData.last_name}</span>
            </span>
            <span className="header__info-date">
              <span>{formatDate(curriculumAnswer.upload_time)}</span>
            </span>
          </Link>
          <div className="header__actions">
            <DropDownMenu onAction={deleteAnswer} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="content">
        {curriculumAnswer.answer_text ? (
          <div className="content__text">
            <span>{curriculumAnswer.answer_text}</span>
          </div>
        ) : (
          ""
        )}
        {studentFiles ? (
          <div className="content__files">{populateStudentFiles()}</div>
        ) : (
          ""
        )}
      </div>
      {profile.user_type != "student" &&
      profile.user_type != "parent" &&
      !professorComments.length ? (
        <div className="professor-comments__add">
          <div className="comment__input">
            <input
              placeholder="Αφήστε ένα σχόλιο..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault(); // Prevents the default action of Enter key in a form
                  submitComment();
                }
              }}
            />
            <BiChevronRight
              onClick={() => submitComment()}
              size={"35px"}
              color={"#6225E6"}
              style={iconStyle("transparent")}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {professorComments.length ? (
        <div className="professor-comments__content">{populateComments()}</div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CurriculumAnswerItem;
