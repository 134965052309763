import React from "react";
import { useState } from "react";
import {
  BiArrowBack,
  BiCheck,
  BiLockAlt,
  BiMailSend,
  BiUser,
  BiX,
} from "react-icons/bi";
import "./login.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "../../scss/main.scss";
import { useEffect } from "react";
import { iconStyle } from "../../utils/generalUtils";
import { calls } from "../../config/db_config";
import { tokenToCookie } from "../../utils/token-to-cookie";
import { logUtils } from "../../utils/logUtils";
import GoogleAuth from "./googleAuth";
import TermsAndConditions from "./termsAndConditions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setHasCameraAccess } from "../../components/Navbar/navbarSlice";

const Login = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showProtection, setShowProtection] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [logoLink, setLogoLink] = useState("");

  useEffect(() => {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
    getEnvironment();
  }, []);

  useEffect(() => {
    const handleIncomingMessage = (eventData) => {
      let data;
      try {
        data = JSON.parse(eventData);
        if (data.message) {
          switch (data.message) {
            case "GOOGLE_LOGIN":
              if (data.data) {
                googleLogin(data.data);
              }
              break;
            default:
              break;
          }
        }
      } catch (e) {
        // Handle JSON parsing error
      }
    };

    const setupMessageListener = () => {
      const isAndroid = /Android/i.test(navigator.userAgent);
      if (isAndroid) {
        document.addEventListener("message", (event) => {
          handleIncomingMessage(event.data);
        });
      } else {
        window.addEventListener("message", (event) => {
          handleIncomingMessage(event.data);
        });
      }
    };

    // Call setupMessageListener to setup the appropriate listener
    setupMessageListener();

    // Cleanup function to remove event listener
    return () => {
      if (/Android/i.test(navigator.userAgent)) {
        document.removeEventListener("message", handleIncomingMessage);
      } else {
        window.removeEventListener("message", handleIncomingMessage);
      }
    };
  }, []);

  const getEnvironment = () => {
    fetch(calls.getLogoLink, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        // Authorization: tokenUtils.getBearerToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setLogoLink(data.data);
        }
      });
  };

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPwd, setLoginPwd] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pwd, setPwd] = useState("");
  const [repeatPwd, setRepeatPwd] = useState("");

  useEffect(() => {
    setErrorMsg("");
  }, [isRegister]);

  const returnHomeNative = () => {
    if (isRunningInWebView && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "RETURN_HOME",
        })
      );
    }
  };

  const isRunningInWebView = () => {
    return window.ReactNativeWebView !== undefined;
  };

  const dispatch = useDispatch();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const paramAllowCameraAccess = searchParams.get("hasCameraAccess");
  if (paramAllowCameraAccess) {
    dispatch(setHasCameraAccess(parseInt(paramAllowCameraAccess)));
  }

  const login = (event) => {
    if (event) {
      event.preventDefault();
    }
    setShowSuccess(false);
    if (!loginEmail) {
      setErrorMsg("Παρακαλώ εισάγεται email");
    } else if (!loginPwd) {
      setErrorMsg("Παρακαλώ εισάγεται κωδικό");
    } else {
      setIsLoading(true);
      const data = {
        email: loginEmail,
        password: loginPwd,
      };
      fetch(calls.signIn, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status != null && data.status === 200) {
            console.log(" user ");
            console.log(data.user);
            tokenToCookie(data.user);
            logUtils.loggedIn().then((r) => {
              if (isRunningInWebView && window.ReactNativeWebView) {
                const schools = data.schools
                  ? data.schools.filter(
                      (item) => item.school_url !== process.env.domain
                    )
                  : [];
                const schoolsToSend = [];
                schools.map((school) => {
                  schoolsToSend.push({
                    schoolName: school.school_name,
                    schoolLogo: school.school_logo,
                    schoolDomain: school.school_url,
                  });
                });
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: "USER_SCHOOL_INFO",
                    data: schoolsToSend,
                  })
                );
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: "LOGGED_IN",
                    userData: {
                      email: loginEmail,
                    },
                  })
                );
              }
              window.location.href = "/";
            });
          } else {
            if (data.message) {
              setIsLoading(false);
              if (data.message == "wrong-pwd") {
                setErrorMsg("Λάθος κωδικός");
              } else if (data.message == "user-not-found") {
                setErrorMsg("Ο χρήστης με αυτό το email δε βρέθηκε");
              } else if (data.message == "not-activated") {
                setErrorMsg(
                  "Ο λογαριασμός σας δεν έχει ενεργοποιήθει ακομά. Θα ειδοποιηθείτε μέσω email όταν κάποιος υπέυθυνος ενεργοποιήσει τον λογαριασμό σας."
                );
              }
            } else {
              setIsLoading(false);
              setErrorMsg("Υπήρχε κάποιο πρόβλημα στην σύνδεση σας");
            }
          }
        });
    }
  };

  const register = (event) => {
    console.log(" calling register ");
    if (event) {
      event.preventDefault();
    }

    if (!firstName) {
      setErrorMsg("Παρακαλώ εισάγεται όνομα");
    } else if (!lastName) {
      setErrorMsg("Παρακαλώ εισάγεται επώνυμο");
    } else if (!email) {
      setErrorMsg("Παρακαλώ εισάγεται email");
    } else if (!pwd) {
      setErrorMsg("Παρακαλώ εισάγεται κωδικό");
    } else if (!repeatPwd) {
      setErrorMsg("Παρακαλώ εισάγεται ξανά τον κωδικό");
    } else if (pwd != repeatPwd) {
      setErrorMsg("Οι κωδικοί σας δε ταιριάζουν");
    } else if (!acceptTerms) {
      setErrorMsg(
        "Η εγγραφή δε μπορεί να γίνει χωρίς την αποδοχή όρων χρήσης και πολιτικής προστασίας"
      );
    } else {
      // everything in the form is correct
      setIsLoading(true);
      // TODO: consider a datepicker or initialize a default value
      const d = new Date();
      d.setTime(d.getTime() - 27 * 365 * 24 * 60 * 60 * 1000);
      const birthday = new Date().toISOString().slice(0, 19).replace("T", " ");

      // TODO: get user_type from secret_code
      const data = {
        email: email,
        password: pwd,
        first_name: firstName,
        last_name: lastName,
      };

      fetch(calls.signUp, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message) {
            if (data.message == "register-success") {
              setShowSuccess(true);
              setIsRegister(false);
              setErrorMsg("");
              setEmail("");
              setFirstName("");
              setPwd("");
              setRepeatPwd("");
              setLastName("");
              setIsLoading(false);
            } else {
              setIsLoading(false);
              setErrorMsg(data.message);
            }
          }
        });
    }
  };

  const googleLogin = (credentialResponse) => {
    try {
      console.log(JSON.parse(credentialResponse));
      const googleData = JSON.parse(credentialResponse);
      const data = {
        is_google: true,
        first_name: googleData.given_name,
        last_name: googleData.family_name,
        profile_picture: googleData.picture,
        email: googleData.email,
        google_id: googleData.sub,
      };

      fetch(calls.googleLogin, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            data.status != null &&
            data.status === 200 &&
            data.message != "register-success"
          ) {
            tokenToCookie(data.user);
            if (isRunningInWebView && window.ReactNativeWebView) {
              const schools = data.schools
                ? data.schools.filter(
                    (item) => item.school_url !== process.env.domain
                  )
                : [];
              const schoolsToSend = [];
              schools.map((school) => {
                schoolsToSend.push({
                  schoolName: school.school_name,
                  schoolLogo: school.school_logo,
                  schoolDomain: school.school_url,
                });
              });
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "USER_SCHOOL_INFO",
                  data: schoolsToSend,
                })
              );
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "LOGGED_IN",
                  userData: {
                    email: loginEmail,
                  },
                })
              );
            }
            window.location.href = "/";
          } else if (data.message) {
            if (data.message == "register-success") {
              setShowSuccess(true);
              setIsRegister(false);
              setErrorMsg("");
              setEmail("");
              setFirstName("");
              setPwd("");
              setRepeatPwd("");
              setLastName("");
              setIsLoading(false);
            } else if (data.message == "user-exists") {
              setErrorMsg("Υπάρχει ήδη χρήστης με αυτό το email");
            } else {
              if (data.message == "not-activated") {
                setIsLoading(false);
                setErrorMsg(
                  "Ο λογαριασμός σας δεν έχει ενεργοποιήθει ακομά. Θα ειδοποιηθείτε μέσω email όταν κάποιος υπέυθυνος ενεργοποιήσει τον λογαριασμό σας."
                );
              } else {
                setIsLoading(false);
                setErrorMsg(data.message);
              }
            }
          } else {
            setIsLoading(false);
            setErrorMsg("Υπήρχε κάποιο πρόβλημα στη σύνδεση.");
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleGooglePress = () => {
    if (isRunningInWebView && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "GOOGLE_LOGIN",
        })
      );
    }
  };

  return (
    <div
      className={
        "start-page " +
        (isRunningInWebView && window.ReactNativeWebView ? "native" : "")
      }
    >
      {isRunningInWebView && window.ReactNativeWebView && (
        <div
          className="start-page__return-home"
          onClick={() => returnHomeNative()}
        >
          <BiArrowBack
            size={"22px"}
            color={"#000"}
            style={iconStyle("transparent")}
          />
          {/* <span>Επιστροφή στην αρχική</span> */}
        </div>
      )}
      {showTerms || showProtection ? (
        <div className="terms-modal">
          <div
            onClick={() => {
              setShowTerms(false);
              setShowProtection(false);
            }}
            className="modal-background show"
          ></div>
          <div className="wrapper">
            <div
              className="wrapper__close"
              onCLick={() => {
                console.log(" clicked ");
                setShowProtection(false);
                setShowTerms(false);
              }}
            >
              <BiX
                size={"30px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />
            </div>
            <TermsAndConditions termsOpen={showTerms} />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="start-page__form">
        <div className="start-page__form-header">
          <img src={logoLink} alt="school-logo" />
        </div>
        <div className="toggle">
          <span
            className={"toggle__option " + (isRegister ? "" : "active")}
            onClick={() => setIsRegister(false)}
          >
            Είσοδος
          </span>
          <span
            className={"toggle__option " + (isRegister ? "active" : "")}
            onClick={() => setIsRegister(true)}
          >
            Εγγραφή
          </span>
        </div>
        <div
          className={
            "slide-container " +
            (isRegister ? "for-register" : "for-login") +
            (showSuccess ? " success " : "") +
            (errorMsg ? " error " : "")
          }
        >
          <div className={"login-container " + (isRegister ? "close" : "show")}>
            <form onSubmit={(e) => login(e)}>
              <div className="login-input">
                <BiUser
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
                <input type="submit" className="d-none " />
                <input
                  type="text"
                  placeholder="Email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </div>
              <div className="login-input">
                <BiLockAlt
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
                <input
                  type="password"
                  placeholder="Κωδικός"
                  value={loginPwd}
                  onChange={(e) => setLoginPwd(e.target.value)}
                />
              </div>
            </form>
            <div className="errors">
              <span>{errorMsg}</span>
            </div>
            <div className="actions">
              <div
                className="google__button"
                onClick={() => handleGooglePress()}
              >
                <span className="label">Σύνδεση με Google</span>
                <img
                  className="google-img"
                  src={"logo-google.svg"}
                  alt="edupal-logo"
                />
                {isRunningInWebView && window.ReactNativeWebView ? (
                  ""
                ) : (
                  <div className="google-provider">
                    <GoogleAuth googleLoginFunction={googleLogin} />
                  </div>
                )}
              </div>
              <button className="cta cta-fill" onClick={() => login()}>
                Σύνδεση
              </button>
            </div>
            {showSuccess ? (
              <div className="success">
                <BiCheck
                  size={"30px"}
                  color={"#25e662"}
                  style={iconStyle("transparent")}
                />
                <span>
                  Επιτυχής εγγραφή! Παρακαλώ αναμείνετε μέχρι να ενεργοποιηθεί ο
                  λογαριασμός σας.
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
          <form
            onSubmit={(e) => register(e)}
            className={"register-container " + (isRegister ? "show" : "close")}
          >
            <div className="dual-input">
              <div className="login-input">
                <BiUser
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
                <input
                  type="text"
                  placeholder="Όνομα"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="login-input">
                <BiUser
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
                <input
                  type="text"
                  placeholder="Επίθετο"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="login-input">
              <BiMailSend
                size={"30px"}
                color={"#6225e6"}
                style={iconStyle("transparent")}
              />
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="dual-input">
              <div className="login-input">
                <BiLockAlt
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
                <input
                  type="password"
                  placeholder="Κωδικός"
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                />
              </div>
              <div className="login-input">
                <BiLockAlt
                  size={"30px"}
                  color={"#6225e6"}
                  style={iconStyle("transparent")}
                />
                <input
                  type="password"
                  placeholder="Επιβεβαίωση κωδικού"
                  value={repeatPwd}
                  onChange={(e) => setRepeatPwd(e.target.value)}
                />
                <input
                  className="d-none"
                  type="submit"
                  value={repeatPwd}
                  onChange={(e) => setRepeatPwd(e.target.value)}
                  placeholder="Επιβεβαίωση κωδικού"
                />
              </div>
            </div>
            <div className="errors">
              <span>{errorMsg}</span>
            </div>
            <div className="register-container__terms">
              <div className="target__select">
                <div className="item">
                  <div
                    className={
                      "target__select-radio " + (acceptTerms ? "active" : "")
                    }
                    onClick={() => setAcceptTerms(!acceptTerms)}
                  ></div>
                  <span className="label">
                    Αποδέχομαι τους
                    <span onClick={() => setShowTerms(true)}>
                      όρους χρήσης{" "}
                    </span>
                    και
                    <span onClick={() => setShowProtection(true)}>
                      πολιτικής προστασίας
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="actions">
              <div
                className="google__button"
                onClick={() => handleGooglePress()}
              >
                <span className="label">Εγγραφή με Google</span>
                <img
                  className="google-img"
                  src={"logo-google.svg"}
                  alt="edupal-logo"
                />
                {isRunningInWebView && window.ReactNativeWebView ? (
                  ""
                ) : (
                  <div className="google-provider">
                    <GoogleAuth googleLoginFunction={googleLogin} />
                  </div>
                )}
              </div>
              <button className="cta cta-fill">Εγγραφή</button>
            </div>
          </form>
        </div>
      </div>
      <div className="start-page__graphic">
        <div className={"edupal-logo " + (isLoading ? "loading" : "")}>
          {window.location.hostname.includes("lingopowers") ? (
            <img
              className="white-label small"
              src={"lingopowers.png"}
              alt="lingopowers-logo"
            />
          ) : (
            <img src={"logo_white.png"} alt="edupal-logo" />
          )}
        </div>
        <div
          className={
            "circle third " +
            (window.location.hostname.includes("lingopowers")
              ? " purple "
              : "") +
            +(isLoading ? "loading" : "")
          }
        ></div>
        <div
          className={
            "circle second " +
            (isLoading ? "loading" : "") +
            (window.location.hostname.includes("lingopowers") ? " blue" : "")
          }
        ></div>
        <div
          className={
            "circle fourth " +
            (window.location.hostname.includes("lingopowers")
              ? " orange"
              : "") +
            (isLoading ? "loading" : "")
          }
        ></div>
        <div
          className={
            "circle first " +
            (isLoading ? "loading" : "") +
            (window.location.hostname.includes("lingopowers") ? " orange" : "")
          }
        ></div>
      </div>
    </div>
  );
};

export default Login;
