import React from "react";
import { useTimer } from "react-timer-hook";

function CountDownTimer({ expiryTimestamp, onTimeExpired }) {
  const { seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      if (typeof onTimeExpired === "function") onTimeExpired();
    },
  });

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  return (
    <div
      className={
        "timer " +
        (isRunning ? "" : " expired ") +
        (minutes < 3 ? " warning " : "")
      }
    >
      <div className="timer-container">
        <span>
          {hours > 0 && (
            <>
              <span>{hours}</span>:
            </>
          )}
          <span>{hours > 0 ? formatTime(minutes) : minutes}</span>:
          <span>{minutes > 0 ? formatTime(seconds) : seconds}</span>
        </span>
      </div>
    </div>
  );
}

export default React.memo(CountDownTimer);
