import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import "./curriculum-answers.scss";
import {
  BiAlarm,
  BiBookAlt,
  BiCalendar,
  BiCalendarCheck,
  BiCalendarX,
  BiDesktop,
  BiDockBottom,
} from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";
import CurriculumAnswerItem from "./curriculumAnswerItem";

function CurriculumAnswers({ curriculumAnswers }) {
  const profile = useSelector((state) => state.profile.value);
  const socketContext = useContext(SocketContext);

  const populateCurriculumAnswers = () => {
    return curriculumAnswers.map((answer, index) => {
      return <CurriculumAnswerItem curriculumAnswer={answer} />;
    });
  };

  return (
    <div className="curriculum-answers">
      <div className="curriculum-answers__list">
        {populateCurriculumAnswers()}
      </div>
    </div>
  );
}

export default CurriculumAnswers;
