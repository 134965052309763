import React, { useEffect, useState, useContext, useRef } from "react";
import { SocketContext } from "../../../../app/socket";
import {
  BiX,
  BiArrowFromRight,
  BiArrowFromLeft,
  BiCarousel,
  BiSearch,
  BiCategoryAlt,
  BiCheckCircle,
  BiCast,
  BiTime,
  BiLogInCircle,
  BiCheck,
  BiLockAlt,
  BiCaretRightCircle,
  BiAbacus,
} from "react-icons/bi";
import { motion } from "framer-motion";
import "./studentExams.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import { iconStyle } from "../../../../utils/generalUtils";
import { EventProvider } from "../../../../components/ConfigureEvent/eventContext";
import StudentExamsCard from "./studentExamsCard";
import { useSelector } from "react-redux";
import MobileSideFilters from "../../../../components/SideFilters/MobileSideFilters/mobileSideFilters";
import LoaderCard from "../../../../components/LoaderCard/loaderCard";

function StudentExams({
  allExams,
  searchText,
  setSearchText,
  topFilter,
  setTopFilter,
  gotExams,
}) {
  const socketContext = useContext(SocketContext);

  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const mobileFiltersClick = () => {
    if (!mobileFiltersOpen) {
      setMobileFiltersOpen(true);
    }
  };

  const sideFiltersExamType = useSelector((state) => state.topFilter.value);

  const populateExams = () => {
    return allExams.map((exam, index) => {
      return (
        <StudentExamsCard key={"professorExamsCard" + index} event={exam} />
      );
    });
  };

  const searchClick = () => {
    if (!searchOpen) {
      setSearchOpen(true);
    }
  };

  return (
    <div className="student-exams">
      <div className="student-header">
        <div
          className={
            "student-header__search " + (mobileFiltersOpen ? " closed" : "")
          }
          onClick={() => setMobileFiltersOpen(false)}
        >
          <input
            placeholder="Αναζήτηση"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <BiSearch
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
        </div>
        <div
          className={
            "student-header__filters " + (mobileFiltersOpen ? "open" : "closed")
          }
          onClick={() => mobileFiltersClick()}
        >
          <div className="student-header__filters-preview">
            {mobileFiltersOpen ? (
              <span onClick={() => setMobileFiltersOpen(false)}>
                Φίλτρα
                <BiX
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </span>
            ) : (
              <BiAbacus
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
          </div>
          <MobileSideFilters bigWidth={true} />
        </div>
      </div>
      <div className="student-exams__list">
        {gotExams ? (
          populateExams()
        ) : window.innerWidth > 800 ? (
          <LoaderCard cardSum={16} width={25} />
        ) : (
          <LoaderCard cardSum={10} width={100} />
        )}
      </div>
    </div>
  );
}

export default StudentExams;
