import {createSlice} from '@reduxjs/toolkit'

export const progressBarSlice = createSlice({
    name: 'progressBar',
    initialState: {
        show: false,
    },
    reducers: {
        toggleProgressBar: (state) => {
            state.value = !state.value;
        },
    },
})

export const {toggleProgressBar} = progressBarSlice.actions

export default progressBarSlice.reducer