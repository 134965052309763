import "./exams.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "../../app/socket";
import SideFilters from "../../components/SideFilters/side-filters";
import { useNavigate } from "react-router-dom";
import { HomepageProvider } from "../Homepage/homepageContext";
import ProfessorExams from "./sections/professorExams/professorExams";
import StudentExams from "./sections/studentExams/studentExams";
import ParentExams from "./sections/parentExams/parentExams";
import Pagination from "../../components/Pagination/pagination";

function Exams() {
  const socketContext = useContext(SocketContext);

  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );

  const [activePage, setActivePage] = useState(1);
  const [totalExams, setTotalExams] = useState(0);
  const pageLimit = 20;

  const [allExams, setAllExams] = useState([]);
  const [gotExams, setGotExams] = useState(false);

  const activePageRef = useRef(0);

  const [searchText, setSearchText] = useState("");
  const [topFilter, setTopFilter] = useState("all");

  const searchRef = useRef(0);
  const topFilterRef = useRef(0);

  const sideFiltersExamType = useSelector((state) => state.topFilter.value);
  const typeRef = useRef(0);

  useEffect(() => {
    setGotExams(false);
    const cleanUpGetAllExams = getAllExams();
    return () => {
      cleanUpGetAllExams();
    };
  }, [sideFiltersClassId]);

  useEffect(() => {
    if (activePageRef.current > 0) {
      setGotExams(false);
      const cleanUpGetAllExams = getAllExams();
      return () => {
        cleanUpGetAllExams();
      };
    } else {
      activePageRef.current++;
    }
  }, [activePage]);

  useEffect(() => {
    if (searchRef.current > 0) {
      setGotExams(false);
      const cleanUpGetAllExams = getAllExams();
      return () => {
        cleanUpGetAllExams();
      };
    } else {
      searchRef.current++;
    }
  }, [searchText]);

  useEffect(() => {
    if (topFilterRef.current > 0) {
      setGotExams(false);
      const cleanUpGetAllExams = getAllExams();
      return () => {
        cleanUpGetAllExams();
      };
    } else {
      topFilterRef.current++;
    }
  }, [topFilter]);

  useEffect(() => {
    if (typeRef.current > 0) {
      setGotExams(false);
      const cleanUpGetAllExams = getAllExams();
      return () => {
        cleanUpGetAllExams();
      };
    } else {
      typeRef.current++;
    }
  }, [sideFiltersExamType]);

  const profile = useSelector((state) => state.profile.value);

  const getAllExams = () => {
    let args = {
      class_id: sideFiltersClassId,
      type: sideFiltersExamType,
      search: searchText,
      top_filter: topFilter,
      limit: pageLimit,
      pagination_index: activePage,
    };

    const getAllExamsListener = (data) => {
      setGotExams(true);
      if (data && data.total) {
        setTotalExams(data.total);
      }
      if (data && data.exams) {
        const events = data.exams.map((event) => {
          const classroomNames = event.classroom_names
            ? event.classroom_names.split("<><>")
            : [];
          return {
            ...event,
            classroom_names: classroomNames,
          };
        });
        setAllExams(events);
      }
    };

    const refreshAllExamsListener = () => {
      socketContext.socket.emit("getAllExams", args);
    };

    socketContext.socket.on("allExams", getAllExamsListener);
    socketContext.socket.emit("getAllExams", args);
    socketContext.socket.on("refreshEvents", refreshAllExamsListener);

    return () => {
      socketContext.socket.off("getAllExams", getAllExamsListener);
      socketContext.socket.off("allExams", getAllExamsListener);
      socketContext.socket.off("refreshEvents", refreshAllExamsListener);
    };
  };

  return (
    <div className="exams">
      <SideFilters hasTopFilters={true} type="exam" />
      <HomepageProvider>
        {profile.user_type == "admin" || profile.user_type == "professor" ? (
          <ProfessorExams
            allExams={allExams}
            setAllExams={setAllExams}
            searchText={searchText}
            setSearchText={setSearchText}
            gotExams={gotExams}
          />
        ) : (
          ""
        )}
        {profile.user_type == "student" ? (
          <StudentExams
            allExams={allExams}
            searchText={searchText}
            setSearchText={setSearchText}
            topFilter={topFilter}
            setTopFilter={setTopFilter}
            gotExams={gotExams}
          />
        ) : (
          ""
        )}
        {profile.user_type == "parent" ? (
          <ParentExams
            allExams={allExams}
            searchText={searchText}
            setSearchText={setSearchText}
            topFilter={topFilter}
            setTopFilter={setTopFilter}
            gotExams={gotExams}
          />
        ) : (
          ""
        )}
      </HomepageProvider>
      <div className="exams__pagination">
        {totalExams > pageLimit ? (
          <Pagination
            className="pagination-bar"
            currentPage={activePage}
            totalCount={totalExams}
            pageSize={pageLimit}
            onPageChange={(page) => setActivePage(page)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Exams;
