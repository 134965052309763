import React from "react";
import "./users-skeleton.scss";
import "../skeleton.scss";
import SideFiltersSkeleton from "../sideFiltersSkeleton/sideFiltersSkeleton";
import LoaderCard from "../../../components/LoaderCard/loaderCard";

function UsersSkeleton() {
  return (
    <div className="users-skeleton skeleton">
      <SideFiltersSkeleton />
      <div className="users-wrapper wrapper">
        <LoaderCard cardSum={1} width={30} size="small" height="small" />
        <LoaderCard cardSum={10} width={20} size="large" />
        <LoaderCard cardSum={1} width={30} size="small" height="small" />
        <LoaderCard cardSum={10} width={20} size="large" />
      </div>
    </div>
  );
}

export default UsersSkeleton;
