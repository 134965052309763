import "./exam-section.scss";
import { BiArrowBack } from "react-icons/bi";
import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../../../app/socket";
import Slider from "react-slick";
import ExamSlide from "./exam-slide";
import { useSelector } from "react-redux";
import LoaderCard from "../../../../components/LoaderCard/loaderCard";
import { CircularProgressbar } from "react-circular-progressbar";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { iconStyle } from "../../../../utils/generalUtils";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

function ExamSection() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const socketContext = useContext(SocketContext);
  const carouselRef = useRef(null);
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [gotExams, setGotExams] = useState(false);
  const [searchExam, setSearchExam] = useState("");
  const [generalAvg, setGeneralAvg] = useState(0);
  const [gotGeneralAvg, setGotGeneralAvg] = useState(false);
  const [contribution, setContribution] = useState(0);
  const [gotContribution, setGotContribution] = useState(false);
  const [examsStdDev, setExamsStdDev] = useState(0);
  const [gotDev, setGotDev] = useState(false);
  const [examsAvgMonthly, setExamsAvgMonthly] = useState(0);
  const [gotExamsAvgMonthly, setGotExamsAvgMonthly] = useState(false);
  const [exams, setExams] = useState([]);
  const examsRef = useRef(0);
  const debounceRef = useRef(null);

  const greekMonths = [
    "Ιαν",
    "Φεβ",
    "Μαρ",
    "Απρ",
    "Μαϊ",
    "Ιουν",
    "Ιουλ",
    "Αυγ",
    "Σεπ",
    "Οκτ",
    "Νοε",
    "Δεκ",
  ];

  const getMonthsWithYear = (currentYear, currentMonth) => {
    let months = [];
    let year = currentMonth >= 9 ? currentYear : currentYear - 1;

    for (let i = 0; i < 12; i++) {
      let monthIndex = (i + 8) % 12; // Start from September
      let displayYear = monthIndex < 8 ? year + 1 : year;
      months.push(
        `${greekMonths[monthIndex]} '${displayYear.toString().substr(2)}`
      );
    }

    return months;
  };

  // Get the current month and year
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const monthsLabels = getMonthsWithYear(currentYear, currentMonth);

  const avgMonthlyData = examsAvgMonthly
    ? {
        labels: monthsLabels,
        datasets: [
          {
            label: "Μέσος όρος",
            data: monthsLabels.map((label) => {
              const [month, year] = label.split(" ");
              const monthIndex = greekMonths.indexOf(month) + 1;
              const exam = examsAvgMonthly.find(
                (ex) =>
                  ex.year == "20" + year[1] + year[2] && ex.month == monthIndex
              );
              return exam ? exam.average_score : 0;
            }),
            fill: false,
            borderColor: "#6124e681",
            backgroundColor: "#6225e6",
            tension: 0.1,
          },
        ],
      }
    : "";

  const avgMonthlyOptions = examsAvgMonthly
    ? {
        elements: {
          point: {
            hoverRadius: 5, // radius of point on hover
            hitRadius: 20, // radius of hover sensitive area around the point
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 100,
          },
        },
      }
    : "";

  const carouselSettings = {
    centerMode: true,
    infinite: false,
    dots: false,
    draggable: true,
    arrows: false,
    slidesToShow: 1,
    centerPadding: "0",
    swipeToSlide: false,
    focusOnSelect: false,
    afterChange: (current) => setCurrentSlide(current),
  };

  useEffect(() => {
    setGotExams(false);
    setGotGeneralAvg(false);
    setGotExamsAvgMonthly(false);
    setGotContribution(false);
    setGotDev(false);
    const cleanUpGetExams = getExams();
    const cleanUpGetGeneralAvg = getGeneralAvg();
    const cleanUpGetContribution = getContribution();
    const cleanUpGetExamsAvgMonthly = getExamsAvgMonthly();
    const cleanUpGetExamsStdDev = getExamsStdDev();
    return () => {
      cleanUpGetContribution();
      cleanUpGetGeneralAvg();
      cleanUpGetExams();
      cleanUpGetExamsAvgMonthly();
      cleanUpGetExamsStdDev();
    };
  }, [sideFiltersClassId]);

  useEffect(() => {
    setGotExams(false);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      const cleanUpGetExams = getExams();
      return () => {
        cleanUpGetExams();
      };
    }, 1000);

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [searchExam]);

  useEffect(() => {
    if (examsRef.current > 0) {
      carouselRef.current.slickGoTo(0);
    } else {
      examsRef.current += 1;
    }
  }, [exams]);

  const getExams = () => {
    let args = { class_id: sideFiltersClassId, search: searchExam };
    const getJustExamsListener = (data) => {
      const temp = [];
      data.map((exam, index) => {
        if (index <= 30) {
          temp.push(exam);
        }
      });
      setExams(temp);
      setGotExams(true);
    };

    socketContext.socket.on("justExams", getJustExamsListener);
    socketContext.socket.emit("getJustExams", args);

    return () => {
      socketContext.socket.off("getJustExams", getJustExamsListener);
      socketContext.socket.off("justExams", getJustExamsListener);
    };
  };

  const populateExamSlides = () => {
    return exams.map((exam, index) => {
      return <ExamSlide key={"examSlide " + index} exam={exam} />;
    });
  };

  const getGeneralAvg = () => {
    let args = { class_id: sideFiltersClassId };
    const getExamsAvgListener = (data) => {
      setGotGeneralAvg(true);
      if (data && data[0] && data[0].average_score) {
        setGeneralAvg(data[0].average_score.toFixed(1));
      }
    };

    socketContext.socket.on("examsAvg", getExamsAvgListener);
    socketContext.socket.emit("getExamsAvg", args);

    return () => {
      socketContext.socket.off("getExamsAvg", getExamsAvgListener);
      socketContext.socket.off("examsAvg", getExamsAvgListener);
    };
  };

  const getContribution = () => {
    let args = { class_id: sideFiltersClassId };
    const getExamsContributionListener = (data) => {
      setGotContribution(true);
      if (data && data[0].contribution_rate) {
        setContribution(data[0].contribution_rate.toFixed(1));
      }
    };

    socketContext.socket.on("examsContribution", getExamsContributionListener);
    socketContext.socket.emit("getExamsContribution", args);

    return () => {
      socketContext.socket.off(
        "getExamsContribution",
        getExamsContributionListener
      );
      socketContext.socket.off(
        "examsContribution",
        getExamsContributionListener
      );
    };
  };

  const getExamsStdDev = () => {
    let args = { class_id: sideFiltersClassId };
    const getExamsStdDevListener = (data) => {
      setGotDev(true);
      if (data && data[0].standard_deviation) {
        setExamsStdDev(data[0].standard_deviation.toFixed(1));
      }
    };

    socketContext.socket.on("examsStdDev", getExamsStdDevListener);
    socketContext.socket.emit("getExamsStdDev", args);

    return () => {
      socketContext.socket.off("getExamsStdDev", getExamsStdDevListener);
      socketContext.socket.off("examsStdDev", getExamsStdDevListener);
    };
  };

  const getExamsAvgMonthly = () => {
    let args = { class_id: sideFiltersClassId };
    const getExamsAvgMonthlyListener = (data) => {
      setGotExamsAvgMonthly(true);
      setExamsAvgMonthly(data);
    };

    socketContext.socket.on("examsAvgMonthly", getExamsAvgMonthlyListener);
    socketContext.socket.emit("getExamsAvgMonthly", args);

    return () => {
      socketContext.socket.off(
        "getExamsAvgMonthly",
        getExamsAvgMonthlyListener
      );
      socketContext.socket.off("examsAvgMonthly", getExamsAvgMonthlyListener);
    };
  };

  return (
    <div className={"section exam-section"}>
      <span className="section__title">Διαγωνίσματα</span>
      <div className="general">
        <div className="general__stats">
          {gotGeneralAvg ? (
            <div className="item">
              <CircularProgressbar
                pathColor="{red}"
                strokeWidth={6}
                className="user-score"
                value={generalAvg}
                duration={1.4}
                text={generalAvg + "%"}
              />
              <span className="label">Γενικός μέσος όρος</span>
            </div>
          ) : (
            <div className="item loading">
              {" "}
              <LoaderCard cardSum={1} width={100} size="large" />
            </div>
          )}
          {gotContribution ? (
            <div className="item">
              <CircularProgressbar
                pathColor="{red}"
                strokeWidth={6}
                className="user-score"
                value={contribution}
                duration={1.4}
                text={contribution + "%"}
              />
              <span className="label">Ποσοστό συμμετοχής μαθητών</span>
            </div>
          ) : (
            <div className="item loading">
              {" "}
              <LoaderCard cardSum={1} width={100} size="large" />
            </div>
          )}
          {gotDev ? (
            <div className="item">
              <CircularProgressbar
                pathColor="{red}"
                strokeWidth={6}
                className="user-score"
                value={examsStdDev}
                duration={1.4}
                text={examsStdDev + "%"}
              />
              <span className="label">Tυπική απόκλιση</span>
            </div>
          ) : (
            <div className="item loading">
              {" "}
              <LoaderCard cardSum={1} width={100} size="large" />
            </div>
          )}
        </div>
        <div className="general__monthly">
          <span className="label">Μέσος όρος διαγωνισμάτων ανά μήνα</span>
          {examsAvgMonthly && gotExamsAvgMonthly ? (
            <Line data={avgMonthlyData} options={avgMonthlyOptions} />
          ) : (
            <LoaderCard cardSum={1} width={100} size="large" />
          )}
        </div>
      </div>
      <div className="old-exams">
        <input
          className="input search-exam"
          placeholder="Αναζήτηση"
          value={searchExam}
          onChange={(e) => setSearchExam(e.target.value)}
        />
        {exams.length ? (
          <div className="old-exams__controls">
            <div
              className={"arrow left " + (currentSlide === 0 ? "disabled" : "")}
              onClick={(event) => {
                carouselRef.current.slickPrev();
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <BiArrowBack
                size={"25px"}
                color={"#c7c7c7"}
                style={iconStyle("transparent")}
              />
            </div>
            <div
              className={
                "arrow right " +
                (currentSlide === exams.length - 1 ? "disabled" : "")
              }
              onClick={(event) => {
                carouselRef.current.slickNext();
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <BiArrowBack
                size={"25px"}
                color={"#c7c7c7"}
                style={iconStyle("transparent")}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <Slider className="slider" ref={carouselRef} {...carouselSettings}>
          {gotExams ? (
            populateExamSlides()
          ) : (
            <div className="loader-container">
              <div className="title-loader">
                <LoaderCard cardSum={1} width={100} size="large" />
              </div>
              <div className="graph-loader-container">
                <div className="stats-loader">
                  <div className="stat">
                    <LoaderCard cardSum={1} width={100} size="large" />
                  </div>
                  <div className="stat">
                    <LoaderCard cardSum={1} width={100} size="large" />
                  </div>
                </div>
                <div className="users-loader">
                  <div className="user">
                    <LoaderCard cardSum={1} width={100} size="large" />
                  </div>
                  <div className="user">
                    <LoaderCard cardSum={1} width={100} size="large" />
                  </div>
                  <div className="user">
                    <LoaderCard cardSum={1} width={100} size="large" />
                  </div>
                  <div className="user">
                    <LoaderCard cardSum={1} width={100} size="large" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </div>
  );
}

export default ExamSection;
