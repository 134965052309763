import { createSlice } from '@reduxjs/toolkit'

export const quizStudentAnswer = createSlice({
    name: 'studentAnswers',
    initialState: {
        value: [],
    },
    reducers: {
        setStudentAnswers: (state, action) => {
            state.value = action.payload
        },
        emptyPreviousQuestions: (state, action) => {
            state.value = [];
        },
        addQuestionItem: (state, action) => {
            state.value.push(action.payload);
        },
        selectAnswer: (state, action) => {
            let answerIndex = action.payload.answerIndex;
            let questionIndex = action.payload.questionIndex;

            let correctAnswers = state.value[questionIndex].correctAnswers;
            if(correctAnswers > 1) {
                
            } else {
                state.value[questionIndex].answers.forEach((answer,i) => {
                    state.value[questionIndex].answers[i].selected = false;
                });
            }
            state.value[questionIndex].submited = false;
            state.value[questionIndex].answers[answerIndex].selected = !state.value[questionIndex].answers[answerIndex].selected;
        },
        submitQuestion: (state, action) => {
            state.value[action.payload].submited = !state.value[action.payload].submited;
        },
        studentHasSelected: (state, action) => {
            
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStudentAnswers, emptyPreviousQuestions, addQuestionItem, selectAnswer, submitQuestion, studentHasSelected} = quizStudentAnswer.actions


export default quizStudentAnswer.reducer