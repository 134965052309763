import { createSlice } from '@reduxjs/toolkit'
import {calls} from "../../config/db_config";
import {tokenUtils} from "../../utils/token-utils";

export const questionsSlice = createSlice({
    name: 'questions',
    initialState: {
        value: [],
    },
    reducers: {
        setQuestions: (state, action) => {
            state.value = action.payload
        },
        addQuestions: (state, action) => {
            state.value.push(action.payload)
        },
        removeQuestion: (state, action) => {
            console.log(action.payload)
            const index = action.payload;
            const exerciseId = state.value[index].exercises_id;
            const body = {'exercises_id': exerciseId};
            console.log(exerciseId)
            state.value.splice(index, 1);

            fetch(calls.removeQuestion, {
                method: 'delete',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokenUtils.getBearerToken()
                },
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                if (data.status === 200) {
                }
                console.log(data)
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { setQuestions, addQuestions, removeQuestion } = questionsSlice.actions

export default questionsSlice.reducer