import React, { useState, useContext, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import {
  BiAbacus,
  BiChevronsDown,
  BiPlus,
  BiUpload,
  BiX,
} from "react-icons/bi";
import { iconStyle } from "../../../utils/generalUtils";
import { SocketContext } from "../../../app/socket";
import "./filter-users.scss";

function FilterUsers({
  isExpanded,
  setIsExpanded,
  selectedOrder,
  setSelectedOrder,
}) {
  const socketContext = useContext(SocketContext);
  const filterRef = useRef(null);

  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isExpanded]);

  return (
    <div
      ref={filterRef}
      className={
        "filter-users " + (isExpanded ? "is-expanded" : "is-collapsed")
      }
    >
      <div
        className={"filter-users__preview " + (isExpanded ? "hide" : "")}
        onClick={() => setIsExpanded(true)}
      >
        <BiChevronsDown
          size={"30px"}
          color={"#cccccc"}
          style={iconStyle("transparent")}
        />
      </div>
      {isExpanded ? (
        <div className="filter-users__close">
          <BiX
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
            className="filter-users__close"
            onClick={() => setIsExpanded(false)}
          />
        </div>
      ) : (
        ""
      )}
      {isExpanded && (
        <div className="filter-users__content">
          <span className="content-title">Ταξινόμηση</span>
          <div className="content__options">
            <div
              className={
                "content__options-item " +
                (selectedOrder == "alphabetical" ? " active" : "")
              }
              onClick={() => setSelectedOrder("alphabetical")}
            >
              <span>Αλφαβητικά</span>
            </div>
            <div
              className={
                "content__options-item " +
                (selectedOrder == "most-recent" ? " active" : "")
              }
              onClick={() => setSelectedOrder("most-recent")}
            >
              <span>Πιο Πρόσφατοι</span>
            </div>
            <div
              className={
                "content__options-item " +
                (selectedOrder == "least-recent" ? " active" : "")
              }
              onClick={() => setSelectedOrder("least-recent")}
            >
              <span>Λιγότερο Πρόσφατοι</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterUsers;
