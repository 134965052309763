import "./users.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { calls } from "../../config/db_config";
import { tokenUtils } from "../../utils/token-utils";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  BiPlus,
  BiX,
  BiMinus,
  BiTrash,
  BiPencil,
  BiSearch,
  BiAbacus,
  // BiInfoCircle,
} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SideFilters from "../../components/SideFilters/side-filters";
import "react-tabs/style/react-tabs.css";
import { SocketContext } from "../../app/socket";
import LoaderCard from "../../components/LoaderCard/loaderCard";
import { getGeneralSettingValue, iconStyle } from "../../utils/generalUtils";
import MobileSideFilters from "../../components/SideFilters/MobileSideFilters/mobileSideFilters";
import Departments from "./Departments/departments";
import EditDepUsers from "./editDepUsers";
import Chapters from "./sections/chapters";
import { setCurrentClassId } from "../../components/SideFilters/classNamesSlice";
import InactiveUserItem from "./inactiveUserItem";
import CreateUser from "./CreateUser/createUser";
import FilterUsers from "./FilterUsers/filterUsers";

function Users() {
  const sideFiltersClassId = useSelector(
    (state) => state.classes.selectedClassId
  );
  const sideFiltersClassName = useSelector(
    (state) => state.classes.selectedClassName
  );
  const socketContext = useContext(SocketContext);
  const [search, setSearch] = useState("");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const mobileFiltersClick = () => {
    if (!mobileFiltersOpen) {
      setMobileFiltersOpen(true);
    }
  };

  const profile = useSelector((state) => state.profile.value);
  const generalSettings = useSelector((state) => state.profile.generalSettings);
  const [createUserExpanded, setCreateUserExpanded] = useState(false);
  const [filterUsersExpanded, setFilterUsersExpanded] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("alphabetical");

  const selectedOrderRef = useRef(0);

  const [selectedDepartment, setSelectedDepartment] = useState(-1);

  const [professors, setProfessors] = useState([]);
  const [students, setStudents] = useState([]);
  const [parents, setParents] = useState([]);
  const [gotProfessors, setGotProfessors] = useState(false);
  const [gotStudents, setGotStudents] = useState(false);
  const [gotParents, setGotParents] = useState(false);

  const [classTuition, setClassTuition] = useState();
  const [newClassTuitionAmount, setNewClassTuitionAmount] = useState(
    classTuition ? classTuition.amount : ""
  );
  const [newClassTuitionInstalments, setNewClassTuitionInstalments] = useState(
    classTuition ? classTuition.instalments : ""
  );
  const [classTuitionIsEdit, setClassTuitionIsEdit] = useState(false);
  const [showClassTuition, setShowClassTuition] = useState(false);

  const [newClassName, setNewClassName] = useState(sideFiltersClassName);
  const [showEditClass, setShowEditClass] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const departmentRef = useRef(0);
  const searchRef = useRef(0);

  useEffect(() => {
    setNewClassName(sideFiltersClassName);
  }, [sideFiltersClassName]);

  useEffect(() => {
    return getInactiveUsers();
  }, []);

  useEffect(() => {
    setGotStudents(false);
    setGotProfessors(false);
    const cleanUpGetProfessors = getProfessors(true);
    const cleanUpGetStudents = getStudents(true);
    const cleanUpGetParents = getParents(true);
    setSelectedDepartment(-1);
    const cleanUpGetClassTuition = getClassTuition();
    return () => {
      cleanUpGetProfessors();
      cleanUpGetStudents();
      cleanUpGetParents();
      cleanUpGetClassTuition();
    };
  }, [sideFiltersClassId]);

  useEffect(() => {
    if (selectedOrderRef.current > 0) {
      const cleanUpGetProfessors = getProfessors();
      const cleanUpGetStudents = getStudents();
      const cleanUpGetParents = getParents();
      return () => {
        cleanUpGetProfessors();
        cleanUpGetStudents();
        cleanUpGetParents();
      };
    } else {
      selectedOrderRef.current++;
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (departmentRef.current > 0) {
      setGotStudents(false);
      setGotProfessors(false);
      const cleanUpGetProfessors = getProfessors();
      const cleanUpGetStudents = getStudents();
      const cleanUpGetParents = getParents();
      return () => {
        cleanUpGetProfessors();
        cleanUpGetStudents();
        cleanUpGetParents();
      };
    } else {
      departmentRef.current++;
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (searchRef.current > 0) {
      setGotStudents(false);
      setGotProfessors(false);
      setGotParents(false);
      const cleanUpGetProfessors = getProfessors();
      const cleanUpGetStudents = getStudents();
      const cleanUpGetParents = getParents();
      return () => {
        cleanUpGetProfessors();
        cleanUpGetStudents();
        cleanUpGetParents();
      };
    } else {
      searchRef.current++;
    }
  }, [search]);

  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [showAllInactiveUsers, setShowAllInactiveUsers] = useState(false);

  const getInactiveUsers = () => {
    let args = {};

    const getNotActivatedUsersListener = (data) => {
      setInactiveUsers(data);
    };

    const refreshNotActivatedUsersListener = () => {
      socketContext.socket.emit("getNotActivatedUsers", args);
    };

    socketContext.socket.on("notActivatedUsers", getNotActivatedUsersListener);
    socketContext.socket.emit("getNotActivatedUsers", args);
    socketContext.socket.on(
      "refreshNotActivatedUsers",
      refreshNotActivatedUsersListener
    );

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off(
        "getNotActivatedUsers",
        getNotActivatedUsersListener
      );
      socketContext.socket.off(
        "notActivatedUsers",
        getNotActivatedUsersListener
      );
      socketContext.socket.off(
        "refreshNotActivatedUsers",
        refreshNotActivatedUsersListener
      );
    };
  };

  const getClassTuition = () => {
    let args = { class_id: sideFiltersClassId };

    const getClassTuitionListener = (data) => {
      if (data && data.length && data[0]) {
        setClassTuition(data[0]);
        setNewClassTuitionAmount(data[0].amount);
        setNewClassTuitionInstalments(data[0].instalments);
      } else {
        setClassTuition();
        setNewClassTuitionAmount();
        setNewClassTuitionInstalments();
      }
    };

    const refreshClassTuitionListener = () => {
      socketContext.socket.emit("getClassTuition", args);
    };

    socketContext.socket.on(
      "classTuition" + sideFiltersClassId,
      getClassTuitionListener
    );
    socketContext.socket.emit("getClassTuition", args);
    socketContext.socket.on(
      "refreshClassTuition" + sideFiltersClassId,
      refreshClassTuitionListener
    );

    // Clean up the event listeners when the component unmounts
    return () => {
      socketContext.socket.off("getClassTuition", getClassTuitionListener);
      socketContext.socket.off(
        "classTuition" + sideFiltersClassId,
        getClassTuitionListener
      );
      socketContext.socket.off(
        "refreshClassTuition" + sideFiltersClassId,
        refreshClassTuitionListener
      );
    };
  };

  const inactiveUserActivate = (user) => {
    // console.log("activating user");
  };

  const populateInactiveUsers = () => {
    return inactiveUsers.map((user) => {
      return (
        <InactiveUserItem
          key={"inactive-user" + user.user_id}
          user={user}
          inactiveUserActivate={inactiveUserActivate}
        />
      );
    });
  };

  const getProfessors = (newClassId) => {
    const uniqueId = "users-professors" + selectedDepartment;
    let args = {
      classId: sideFiltersClassId,
      uniqe_id: uniqueId,
      type: "professor",
      also_admins: true,
      department_id: newClassId ? -1 : selectedDepartment,
      name: search,
      selected_order: selectedOrder,
      get_all: true,
    };

    const getProfessorsListener = (data) => {
      setGotProfessors(true);
      setProfessors(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams" + selectedDepartment,
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams" + selectedDepartment,
        refreshProfessorsListener
      );
    };
  };

  const getStudents = (newClassId) => {
    const uniqueId = "users-students" + selectedDepartment;
    let args = {
      classId: sideFiltersClassId,
      uniqe_id: uniqueId,
      type: "student",
      department_id: newClassId ? -1 : selectedDepartment,
      name: search,
      selected_order: selectedOrder,
      get_all: true,
    };

    const getProfessorsListener = (data) => {
      setGotStudents(true);
      setStudents(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams" + selectedDepartment,
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams" + selectedDepartment,
        refreshProfessorsListener
      );
    };
  };

  const getParents = (newClassId) => {
    const uniqueId = "users-parents";
    let args = {
      classId: sideFiltersClassId,
      uniqe_id: uniqueId,
      type: "parent",
      department_id: newClassId ? -1 : selectedDepartment,
      name: search,
      selected_order: selectedOrder,
      get_all: true,
    };

    const getProfessorsListener = (data) => {
      setGotParents(true);
      setParents(data);
    };

    const refreshProfessorsListener = () => {
      socketContext.socket.emit("getAllUsersWithParams", args);
    };

    socketContext.socket.on(
      "allUsersWithParams" + uniqueId,
      getProfessorsListener
    );
    socketContext.socket.emit("getAllUsersWithParams", args);
    socketContext.socket.on(
      "refreshAllUsersWithParams",
      refreshProfessorsListener
    );

    return () => {
      socketContext.socket.off("getAllUsersWithParams", getProfessorsListener);
      socketContext.socket.off(
        "allUsersWithParams" + uniqueId,
        getProfessorsListener
      );
      socketContext.socket.off(
        "refreshAllUsersWithParams",
        refreshProfessorsListener
      );
    };
  };

  const populateUsers = (type, getArchived) => {
    return (
      type == "professor" ? professors : type == "student" ? students : parents
    ).map((user, index) => {
      if (
        (getArchived && user.is_archived) ||
        (!getArchived && !user.is_archived)
      ) {
        return (
          <Link
            to={"/profile?user-id=" + user.user_id}
            key={"professor-user" + index}
            className="users__list-wrapper-item"
          >
            <div className="profile-img">
              <img
                src={
                  user.profile_picture
                    ? user.profile_picture
                    : "resources/student.png"
                  // : user.user_type == "student"
                  // ? "resources/student.png"
                  // : user.user_type == "parent"
                  // ? "resources/family.png"
                  // : "resources/professor.png"
                }
              />
            </div>
            <div className="name">
              <span>{user.last_name}</span>
              <span>{user.first_name}</span>
            </div>
          </Link>
        );
      }
    });
  };

  // const dispatch = useDispatch();

  const deleteClass = () => {
    setShowDelete(false);
    const eventBody = {
      class_id: sideFiltersClassId,
      add_tuition: getGeneralSettingValue(generalSettings, "tuition_for_class"),
      class_tuition: classTuition,
      steroid_tuition: getGeneralSettingValue(
        generalSettings,
        "tuition_on_steroids"
      ),
    };

    socketContext.socket.emit("deleteClass", eventBody);
    window.location.reload();
    // dispatch(setCurrentClassId(-1));
  };

  const saveClassTuition = () => {
    const body = {
      class_id: sideFiltersClassId,
      amount: newClassTuitionAmount,
      instalments: newClassTuitionInstalments,
      is_new: classTuition ? false : true,
      is_edit: classTuitionIsEdit,
      old_amount: classTuition ? classTuition.amount : "",
    };

    console.log(body);

    socketContext.socket.emit("updateClassTuition", body);
    setClassTuitionIsEdit(false);
    setShowClassTuition(false);
  };

  const saveNewClassName = () => {
    const body = {
      class_id: sideFiltersClassId,
      class_name: newClassName,
    };

    socketContext.socket.emit("updateClassName", body);
    setShowEditClass(false);
  };

  return (
    <div className="users-page">
      <SideFilters type="panel" addClassShow="true" />
      <div
        className={"users__header " + (createUserExpanded ? " big-index" : "")}
      >
        <div
          className={
            "users__header-search " +
            (mobileFiltersOpen ? " closed" : "") +
            (filterUsersExpanded ? " desktop-min" : "") +
            (sideFiltersClassId != -1 ? " full-width" : "")
          }
          onClick={() => setMobileFiltersOpen(false)}
        >
          <input
            placeholder="Αναζήτηση"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <BiSearch
            size={"30px"}
            color={"#cccccc"}
            style={iconStyle("transparent")}
          />
        </div>
        {sideFiltersClassId == -1 ? (
          <FilterUsers
            isExpanded={filterUsersExpanded}
            setIsExpanded={setFilterUsersExpanded}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />
        ) : (
          " "
        )}
        {sideFiltersClassId == -1 ? (
          <CreateUser
            professors={professors}
            students={students}
            parents={parents}
            isExpanded={createUserExpanded}
            setIsExpanded={setCreateUserExpanded}
          />
        ) : (
          " "
        )}
        <div
          className={
            "users__header-filters " + (mobileFiltersOpen ? "open" : "closed")
          }
          onClick={() => mobileFiltersClick()}
        >
          <div className="professor-exams__mobile-filters-preview">
            {mobileFiltersOpen ? (
              <span onClick={() => setMobileFiltersOpen(false)}>
                Φίλτρα
                <BiX
                  size={"25px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </span>
            ) : (
              <BiAbacus
                size={"25px"}
                color={"#cccccc"}
                style={iconStyle("transparent")}
              />
            )}
          </div>
          <MobileSideFilters />
        </div>
      </div>
      {sideFiltersClassId === -1 && inactiveUsers.length > 0 ? (
        <div className="inactive-users">
          <div className="title">Χρήστες προς ενεργοποίηση</div>
          <div className={"list " + (showAllInactiveUsers ? "open" : "")}>
            <AnimatePresence>{populateInactiveUsers()}</AnimatePresence>
          </div>
          {inactiveUsers.length > 4 && (
            <div>
              <button
                className={
                  "list__show-all " +
                  (inactiveUsers.length > 3 ? "" : "hide ") +
                  (showAllInactiveUsers ? " hide" : "")
                }
                onClick={() => setShowAllInactiveUsers(true)}
              >
                Προβολή όλων{" "}
                <BiPlus
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </button>
              <button
                className={
                  "list__show-all " +
                  (inactiveUsers.length > 3 ? "" : "hide ") +
                  (showAllInactiveUsers ? "" : " hide")
                }
                onClick={() => setShowAllInactiveUsers(false)}
              >
                Προβολή λιγότερων{" "}
                <BiMinus
                  size={"30px"}
                  color={"#cccccc"}
                  style={iconStyle("transparent")}
                />
              </button>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {sideFiltersClassId != -1 ? (
        <Chapters classId={sideFiltersClassId} search={search} />
      ) : (
        ""
      )}
      {sideFiltersClassId != -1 ? (
        <Departments
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          search={search}
          classTuition={classTuition}
        />
      ) : (
        ""
      )}
      <div className="users__list">
        <div className="users__list-label">
          <span className="users__title">
            Καθηγητές{" "}
            <span>({professors.filter((pr) => !pr.is_archived).length})</span>
          </span>
        </div>
        <div className="users__list-wrapper">
          {selectedDepartment != -1 && gotProfessors ? (
            <EditDepUsers
              currentUsers={professors}
              setCurrentUsers={setProfessors}
              userType="professor"
              selectedDepartment={selectedDepartment}
            />
          ) : (
            ""
          )}
          {!gotProfessors ? (
            <LoaderCard cardSum={5} width={20} />
          ) : (
            populateUsers("professor")
          )}
        </div>
      </div>
      <div className="users__list">
        <div className="users__list-label">
          <span className="users__title">
            Μαθητές{" "}
            <span>({students.filter((st) => !st.is_archived).length})</span>
          </span>
        </div>
        <div className="users__list-wrapper">
          {selectedDepartment != -1 && gotStudents ? (
            <EditDepUsers
              currentUsers={students}
              setCurrentUsers={setStudents}
              userType="student"
              selectedDepartment={selectedDepartment}
              classTuition={classTuition}
            />
          ) : (
            ""
          )}
          {!gotStudents ? (
            <LoaderCard cardSum={5} width={20} />
          ) : (
            populateUsers("student")
          )}
        </div>
      </div>
      {sideFiltersClassId == -1 ? (
        <div className="users__list">
          <div className="users__list-label">
            <span className="users__title">
              Γονείς{" "}
              <span>({parents.filter((pr) => !pr.is_archived).length})</span>
            </span>
          </div>
          <div className="users__list-wrapper">
            {!gotParents ? (
              <LoaderCard cardSum={5} width={20} />
            ) : (
              populateUsers("parent")
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {sideFiltersClassId == -1 ? (
        <div className="users__list">
          <div className="users__list-label">
            <span className="users__title">
              Αρχειοθετημένοι{" "}
              <span>({parents.filter((pr) => !pr.is_archived).length})</span>
            </span>
          </div>
          <div className="users__list-wrapper">
            {!gotParents ? (
              <LoaderCard cardSum={5} width={20} />
            ) : (
              populateUsers("parent")
            )}
          </div>
        </div>
      ) : (
        ""
      )} */}
      {showClassTuition || showEditClass ? (
        <div
          onClick={() => {
            setShowClassTuition(false);
            setShowEditClass(false);
          }}
          className="modal-background show"
        ></div>
      ) : (
        ""
      )}
      {showClassTuition ? (
        <div className="users__tuition">
          <span className="users__tuition-label">Δίδακτρα μαθήματος</span>
          {classTuition && !classTuitionIsEdit ? (
            <div className="users__tuition-info">
              <div className="amount tuition-info-item">
                <span className="tuition-title">Ποσό διδάκτρων:</span>
                <span className="tuition-label">{classTuition.amount} €</span>
              </div>
              <div className="instalments tuition-info-item">
                <span className="tuition-title">Αριθμός δόσεων</span>
                <span className="tuition-label">
                  {classTuition.instalments}
                </span>
              </div>
            </div>
          ) : (
            <div className="users__tuition-config">
              <div className="users__tuition-amount tuition-item">
                <span>Ορίστε τα συνολικά δίδακτρα για το μάθημα</span>
                <input
                  className="input"
                  placeholder="π.χ. 1000"
                  value={newClassTuitionAmount}
                  onChange={(e) => setNewClassTuitionAmount(e.target.value)}
                />
              </div>
              <div className="users__tuition-instalments tuition-item">
                <span>Ορίστε τις δόσεις διδάκτρων για το μάθημα</span>
                <input
                  className="input"
                  placeholder="π.χ. 8"
                  value={newClassTuitionInstalments}
                  onChange={(e) =>
                    setNewClassTuitionInstalments(e.target.value)
                  }
                />
              </div>
              <div className="users__tuition-instalments"></div>
            </div>
          )}
          {!classTuition || classTuitionIsEdit ? (
            <div className="users__tuition-actions">
              <button
                className="cta cta-red"
                onClick={() => setClassTuitionIsEdit(false)}
              >
                Ακύρωση
              </button>
              <button className="cta" onClick={() => saveClassTuition()}>
                Αποθήκευση
              </button>
            </div>
          ) : (
            <div className="users__tuition-edit">
              <button
                className="cta"
                onClick={() => setClassTuitionIsEdit(true)}
              >
                Επεξεργασία
              </button>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
      {showEditClass ? (
        <div className="users__edit-class">
          <span className="users__edit-class-label">
            Επεξεργασία ονόματος - {sideFiltersClassName}
          </span>
          <input
            className="input"
            placeholder="Ορίστε νέο όνομα μαθήματος"
            value={newClassName}
            onChange={(e) => setNewClassName(e.target.value)}
          />
          <div className="users__edit-class-actions">
            <button className="cta" onClick={() => saveNewClassName()}>
              Αποθήκευση
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {sideFiltersClassId != -1 && profile.user_type == "admin" ? (
        <div className="users__actions">
          {!getGeneralSettingValue(generalSettings, "only_grades") ? (
            <div className="users__actions-delete">
              <button
                className="cta cta-red"
                onClick={() => setShowDelete(true)}
              >
                Διαγραφή Μαθήματος
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="users__actions-general">
            {getGeneralSettingValue(generalSettings, "tuition_for_class") ? (
              <button
                className="cta cta-shadow"
                onClick={() => setShowClassTuition(true)}
              >
                Δίδακτρα Μαθήματος
              </button>
            ) : (
              ""
            )}
            {!getGeneralSettingValue(generalSettings, "only_grades") ? (
              <button className="cta" onClick={() => setShowEditClass(true)}>
                Επεξεργασία
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={"delete-modal " + (showDelete ? "show" : "")}>
        <div className="delete-container">
          <span className="title">
            Είστε σίγουροι ότι θέλετε να διαγράψετε το επιλεγμένο μάθημα; Μαζι
            του θα διαγραφούν και όλα τα στατιστικά, διαγωνίσματα κτλ
          </span>
          <div className="controls">
            <button className="cta" onClick={() => setShowDelete(false)}>
              Ακύρωση
            </button>
            <button className="cta cta-red" onClick={() => deleteClass()}>
              Διαγραφή
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
