import React, { useState, useEffect, useContext } from "react";
import "./online-date-section.scss";
import { SocketContext } from "../../../../app/socket";
import { useEventContext } from "../../eventContext";
import { BiCalendar, BiCalendarCheck, BiCalendarX, BiX } from "react-icons/bi";
import { iconStyle } from "../../../../utils/generalUtils";

const monthNames = [
  "Ιανουάριος",
  "Φεβρουάριος",
  "Μάρτιος",
  "Απρίλιος",
  "Μάιος",
  "Ιούνιος",
  "Ιούλιος",
  "Αύγουστος",
  "Σεπτέμβριος",
  "Οκτώβριος",
  "Νοέμβριος",
  "Δεκέμβριος",
];

function updateTimeInDatetime(datetimeStr, timeStr) {
  // Create a new Date object using the provided datetime string
  const datetime = new Date(datetimeStr);

  // Extract hours and minutes from the time string
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Set the hours and minutes to the datetime object
  datetime.setHours(hours);
  datetime.setMinutes(minutes);
  datetime.setSeconds(0); // Optionally reset seconds to 0

  // Return the updated datetime as a string in the original format
  const year = datetime.getFullYear();
  const month = (datetime.getMonth() + 1).toString().padStart(2, "0");
  const day = datetime.getDate().toString().padStart(2, "0");
  const formattedHours = datetime.getHours().toString().padStart(2, "0");
  const formattedMinutes = datetime.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes}:00`;
}

function extractDate(datetimeStr) {
  // Create a new Date object using the provided datetime string
  const date = new Date(datetimeStr);

  // Define options for displaying day of the week, day of the month, and month in Greek
  const options = {
    weekday: "long", // long format for the day of the week
    day: "numeric", // numeric day of the month
    month: "long", // long format for the month
    localeMatcher: "best fit",
  };

  // Create an Intl.DateTimeFormat object for Greek locale
  const formatter = new Intl.DateTimeFormat("el-GR", options);

  // Format and return the date
  return formatter.format(date);
}

const formatTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

const getMonthDays = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const dayNamesShort = ["Δευ", "Τρι", "Τετ", "Πεμ", "Παρ", "Σαβ", "Κυρ"];

function OnlineDateSection({ eventType, calendarDate }) {
  const socketContext = useContext(SocketContext);

  const [timeStart, setTimeStart] = useState("12:00");
  const [timeEnd, setTimeEnd] = useState("12:00");

  const { eventStartDate, setEventStartDate } = useEventContext();
  const { eventDuration, setEventDuration } = useEventContext();
  const { eventEndDate, setEventEndDate } = useEventContext();

  const [defDuration, setDefDuration] = useState(0);

  const selectDefDuration = (val) => {
    setDefDuration(val);
    setEventDuration(val);
  };

  useEffect(() => {
    if (eventDuration != 30 && eventDuration != 60 && eventDuration != 120) {
      setDefDuration();
    }
  }, [eventDuration]);

  const [currentDate, setCurrentDate] = useState(
    eventStartDate ? new Date(eventStartDate) : new Date()
  );

  const [selectedDate, setSelectedDate] = useState(
    calendarDate
      ? calendarDate
      : eventStartDate
      ? new Date(eventStartDate)
      : new Date()
  );

  const defaultStartTime = new Date();
  const defaultEndTime = new Date(
    defaultStartTime.getTime() + 2 * 60 * 60 * 1000
  );

  const [monthEvents, setMonthEvents] = useState([]);

  useEffect(() => {
    return getMonthEvents();
  }, [selectedDate]);

  // useEffect(() => {
  //   setEventStartDate(null);
  //   setEventEndDate(null);
  // }, []);

  useEffect(() => {
    if (!selectedDate) {
      setEventEndDate(null);
      setEventStartDate(null);
    }
  }, [selectedDate]);

  const getMonthEvents = () => {
    if (selectedDate) {
      let args = {
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      };

      const getMonthEventsListener = (data) => {
        setMonthEvents(data);
      };

      const refreshMonthEventsListener = () => {
        socketContext.socket.emit("getMonthEvents", args);
      };

      socketContext.socket.on("monthEvents", getMonthEventsListener);
      socketContext.socket.emit("getMonthEvents", args);
      socketContext.socket.on("refreshEvents", refreshMonthEventsListener);

      return () => {
        socketContext.socket.off("getMonthEvents", getMonthEventsListener);
        socketContext.socket.off("monthEvents", getMonthEventsListener);
        socketContext.socket.off("refreshEvents", refreshMonthEventsListener);
      };
    }
  };

  const getFirstDayOfMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    // Adjust to make Monday the first day of the week
    return firstDay == 0 ? 6 : firstDay - 1;
  };

  const renderDayNames = () => {
    return dayNamesShort.map((dayName, index) => (
      // Adjust index by 1 to start the week on Monday (1)
      <div key={index} className="day-name">
        {dayName}
      </div>
    ));
  };

  const dayClick = (clickedDay) => {
    setSelectedDate(clickedDay);

    let date = clickedDay instanceof Date ? clickedDay : new Date(clickedDay);
    const [hours, minutes] = timeStart.split(":").map(Number);
    date.setHours(hours, minutes, 0);

    // Adjust for the timezone offset to get the correct local time
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    date = new Date(date.getTime() - timezoneOffsetInMinutes * 60000);

    const formattedDate = date.toISOString().replace("T", " ").substring(0, 19);

    if (eventEndDate) {
      setEventStartDate(formattedDate);
      setEventEndDate(null);
    } else {
      if (eventStartDate) {
        if (eventStartDate > formattedDate) {
          let temp = eventStartDate;
          setEventEndDate(temp);
          setEventStartDate(formattedDate);
        } else {
          let newFormattedDate = updateTimeInDatetime(formattedDate, timeEnd);
          setEventEndDate(newFormattedDate);
        }
      } else {
        setEventStartDate(formattedDate);
      }
    }
  };

  const renderDays = () => {
    const days = [];
    const monthDays = getMonthDays(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );
    const firstDay = getFirstDayOfMonth(
      currentDate.getFullYear(),
      currentDate.getMonth()
    );

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="day empty"></div>);
    }

    for (let day = 1; day <= monthDays; day++) {
      days.push(
        <div
          key={day}
          className={
            "day " +
            (eventStartDate && new Date(eventStartDate)?.getDate() == day
              ? "selected "
              : "") +
            (eventEndDate && new Date(eventEndDate)?.getDate() == day
              ? "selected "
              : "") +
            (eventStartDate &&
            eventEndDate &&
            new Date(eventStartDate)?.getDate() < day &&
            new Date(eventEndDate)?.getDate() > day
              ? "range-day"
              : "") +
            (day == new Date().getDate() &&
            selectedDate.getMonth() == new Date().getMonth()
              ? " current "
              : " ") +
            (monthEvents &&
            day >= 1 &&
            day &&
            monthEvents[day - 1] &&
            monthEvents[day - 1].Events != "No events"
              ? monthEvents[day - 1].Events == "lecture"
                ? "has-lecture"
                : "" + monthEvents[day - 1].Events == "exam"
                ? "has-exam"
                : "" + monthEvents[day - 1].Events == "lecture&test"
                ? "has-lecture-and-test"
                : "" + monthEvents[day - 1].Events == "lecture&exam&test"
                ? "has-lecture-and-exam-and-test"
                : "" + monthEvents[day - 1].Events == "exam&test"
                ? "has-exam-and-test"
                : "" + monthEvents[day - 1].Events == "test"
                ? "has-test"
                : "" + monthEvents[day - 1].Events == "lecture&exam"
                ? "has-lecture-and-exam"
                : ""
              : "")
          }
          onClick={
            () =>
              dayClick(
                new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
              )
            // setSelectedDate(
            //   new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
            // )
          }
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const changeMonth = (increment) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + increment, 1)
    );
  };

  const getTotalDuration = () => {
    // Calculate the difference in milliseconds
    const endDate = new Date(eventEndDate);
    const startDate = new Date(eventStartDate);
    const diff = endDate - startDate;

    // Calculate days, hours, minutes, and seconds
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    // Create the result string in Greek
    let result = "";
    if (days > 0) result += days + (days === 1 ? " ημέρα, " : " ημέρες, ");
    if (hours > 0) result += hours + (hours === 1 ? " ώρα, " : " ώρες, ");
    if (minutes > 0)
      result += minutes + (minutes === 1 ? " λεπτό, " : " λεπτά, ");
    if (seconds > 0)
      result += seconds + (seconds === 1 ? " δευτερόλεπτο" : " δευτερόλεπτα");

    // Remove the last comma and space if they exist
    result = result.replace(/, $/, "");

    return result;
  };

  return (
    <div className={"online-date-section no-scrollbar"}>
      <div className="config">
        <div className="wrapper">
          <div className="config__date">
            <span className="config__date-label">Διαλέξτε εύρος ημερών</span>
            <div className="date-picker">
              <div className="header">
                <button onClick={() => changeMonth(-1)}>&lt;</button>
                <span>{`${
                  monthNames[currentDate.getMonth()]
                } ${currentDate.getFullYear()}`}</span>
                <button onClick={() => changeMonth(1)}>&gt;</button>
              </div>
              <div className="day-names">{renderDayNames()}</div>
              <div className="days">{renderDays()}</div>
            </div>
          </div>
          <div className="date__range">
            <div className="date__range-item"></div>
          </div>
          {eventType != "announcement" ? (
            <div className="config__duration">
              <span className="config__duration-label">
                Διάρκεια{" "}
                {eventType == "parent-event" ? "συνεδρίας" : "διαγωνίσματος"} σε
                λεπτά
              </span>
              <div className="config__duration-options">
                <input
                  type="number"
                  className="input"
                  placeholder="60"
                  value={eventDuration}
                  onChange={(e) => setEventDuration(e.target.value)}
                />
                <div
                  className={"option " + (defDuration == 30 ? "active" : "")}
                  onClick={() => selectDefDuration(30)}
                >
                  30
                </div>
                <div
                  className={"option " + (defDuration == 60 ? "active" : "")}
                  onClick={() => selectDefDuration(60)}
                >
                  60
                </div>
                <div
                  className={"option " + (defDuration == 120 ? "active" : "")}
                  onClick={() => selectDefDuration(120)}
                >
                  120
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="range">
        <div className="range__line">
          <div className="range__line-item">
            <div className="range__line-label">
              <BiCalendarCheck
                size={"40px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />{" "}
              Από
            </div>
            <div className="range__line-label">
              {eventStartDate
                ? extractDate(eventStartDate)
                : "Ημερομηνία εκκίνησης"}
            </div>
            <input
              type="time"
              className="input"
              value={timeStart}
              onChange={(e) => setTimeStart(e.target.value)}
            />
          </div>
          <div className="line start"></div>
          <span className="range__line-duration">
            {eventStartDate && eventEndDate
              ? getTotalDuration()
              : "Συνολική Διάρκεια"}
          </span>
          <div className="line end"></div>
          <div className="range__line-item">
            <div className="range__line-label">
              <BiCalendarX
                size={"40px"}
                color={"#ccc"}
                style={iconStyle("transparent")}
              />{" "}
              Εώς
            </div>
            <div className="range__line-label">
              {eventEndDate ? extractDate(eventEndDate) : "Ημερομηνία λήξης"}
            </div>
            <input
              type="time"
              className="input"
              value={timeEnd}
              onChange={(e) => setTimeEnd(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnlineDateSection;
