import "./homepage.scss";
import React from "react";
import { HomepageProvider } from "./homepageContext";
import HomepageWrapper from "./homepage-wrapper";

function Homepage() {
  return (
    <HomepageProvider>
      <HomepageWrapper />
    </HomepageProvider>
  );
}

export default Homepage;
